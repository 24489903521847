import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Table } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import QRCode from 'qrcode';
import jsPDF from 'jspdf';

import ProductDetailsModal from './ProductDetailsModal'; // Import the ProductDetailsModal component

const ProductList = ({ status, adminStatus, socityId }) => {
  const [products, setProductsData] = useState([]);
  const [loadingQRCode, setLoadingQRCode] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null); // State to hold selected product
  const [showProductModal, setShowProductModal] = useState(false); // State to control modal visibility
  const redirect = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem("admin-token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}product/get-socity/${socityId}`,
          {
            method: "GET",
            headers: {
              Authorization: `jwt ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          if (response.statusText === "Unauthorized") {
            localStorage.removeItem("token");
            localStorage.removeItem("societyDetails");
            redirect("/login");
            return;
          } else {
            throw new Error("Network response was not ok");
          }
        }

        const jsonData = await response.json();

        if (jsonData.status) {
          const transformedData = jsonData.data.map((item, index) => ({
            sl: index + 1, // Start sequence from 1
            code: item.product_code,
            name: item.product_name,
            status: item.status,
            id: item.id,
          }));

          setProductsData(transformedData);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    if (status) {
      fetchData();
    }
  }, [status, deleteStatus]);

  const handleGenerateQRCode = async (data) => {
    setLoadingQRCode(data.code); // Set loading state to current product code
    const currentDomain = window.location.origin;
    const url = `${currentDomain}/product/${data.code}`;

    try {
      const qrCodeDataUrl = await QRCode.toDataURL(url, {
        width: 300,
        margin: 1,
      });

      const pdf = new jsPDF();
      pdf.addImage(qrCodeDataUrl, 'PNG', 10, 10);
      pdf.save(`QR-${data.code}.pdf`);
    } catch (error) {
      console.error('Error generating QR code or PDF:', error);
    } finally {
      setLoadingQRCode(null); // Reset loading state
    }
  };

  const [shouldOpenModal, setShouldOpenModal] = useState(false); // New state

  // Effect to open the modal after selectedProduct is set
  // useEffect(() => {
  //   if (selectedProduct && shouldOpenModal) {
  //     setShowProductModal(true);
  //     setShouldOpenModal(false); // Reset the flag
  //   }
  // }, [selectedProduct, shouldOpenModal]);

  const table_header = [
    {
      prop: "sl",
      title: "Sl No",
    },
    {
      prop: "code",
      title: "Code",
      isFilterable: true,
      cell: (row) => (
        <a
          href="#!"
          onClick={(e) => {
            e.preventDefault();
            setSelectedProduct(row); 
            // setShouldOpenModal(true); // Set flag to open modal after product is set
            setShowProductModal(true);
          }}
        >
          {row.code}
        </a>
      ),
    },
    {
      prop: "name",
      title: "Name",
      isFilterable: true,
    },
    {
      prop: "edit",
      cell: (row) =>
        row.status === 0 && adminStatus !== true ? ( // Display edit option only when adminStatus is not true
          <div className="product-edit">
            <a href={`/edit-product/${row.code}`}>
              <img src="./assets/images/product_edit.png" alt="" />
            </a>
          </div>
        ) : null,
    },
    {
      prop: "qr",
      cell: (row) =>
        row.status === 1 ? (
          <a
            className="td-item"
            href="#!"
            onClick={(e) => handleGenerateQRCode(row)}
          >
            {loadingQRCode === row.code ? (
              <ThreeDots
                visible={true}
                height="20"
                width="40"
                color="#fff"
                radius="1"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: 'block' }}
              />
            ) : (
              "QR Code"
            )}
          </a>
        ) : null,
    },
  ];

  return (
    <>
      <h3>Products</h3>
      <DatatableWrapper
        body={products}
        headers={table_header}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10,
            options: [5, 10, 15, 20],
          },
        }}
      >
         <div style={{ overflowX: "auto", backgroundColor: "#f5fbf8", padding: "1rem", borderRadius: "0.25rem" }}>
        <Table style={{ backgroundColor: "#f5fbf8" }}>
          <TableHeader />
          <TableBody />
        </Table>
        </div>
        <Row className="mb-4 p-2">
          <Col
            xs={12}
            sm={12}
            lg={12}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Pagination />
          </Col>
        </Row>
      </DatatableWrapper>
      <ProductDetailsModal
        show={showProductModal}
        handleClose={() => setShowProductModal(false)}
        prods={selectedProduct} // Pass the selected product to the modal
      />
      
    </>
  );
};

export default ProductList;
