import React from "react";
const PrivacyPolicy =()=>{
   
    return (<>
    
    <section className="inner_banner d-flex align-items-center justify-content-center">
    <div className="container">
      <h1>PRIVACY POLICY</h1>
    </div>
  </section>
  <section className="privacy_policy content-page bg_white">
    <div className="container-new">
      <div className="content">
        <h3>Introduction</h3>
        <p>Welcome to the Kerala Handloom Mark website, operated by the Directorate of Handloom and Textiles under the
          Ministry of Industries and Commerce, Government of Kerala, and implemented by the Indian Institute of Handloom
          Technology (IIHT) Thottada. This Privacy Policy outlines the types of personal information we collect, how we
          use and protect that information, and your rights concerning your personal data.</p>
        <p>By using this website, you consent to the terms outlined in this Privacy Policy.</p>
        <h4>Information We Collect</h4>
        <p>1. Personal Information</p>
        <p>We may collect the following personal information when you interact with our website:</p>
        <ul>
          <li>Name</li>
          <li>Contact information, including email address and phone number</li>
          <li>Address details</li>
          <li>Identity verification documents</li>
          <li>Details related to your handloom society or artisan activities</li>
          <li>Any other information you voluntarily provide during registration or communication with us</li>
        </ul>
        <h4>Usage Data</h4>
        <p>We may also collect information on how the website is accessed and used. This data may include:</p>
        <ul>
          <li>IP address</li>
          <li>Browser type and version</li>
          <li>Pages of our website that you visit</li>
          <li>Time and date of your visit</li>
          <li>Time spent on those pages</li>
          <li>Other diagnostic data</li>
        </ul>
        <h4>How We Use Your Information</h4>
        <p>We use the information we collect for the following purposes:</p>
        <ul>
          <li><strong>Registration and Certification:</strong> To process applications for the Kerala Handloom Mark
            certification and maintain records.</li>
          <li><strong>Communication:</strong> To communicate with you regarding your application, provide updates, and
            respond to your inquiries.</li>
          <li><strong>Compliance:</strong> To ensure compliance with applicable laws, regulations, and government
            policies.</li>
          <li><strong>Website Improvement:</strong> To analyze website usage and improve our services and user
            experience.</li>
        </ul>
        <h4>Data Security</h4>
        <p>We are committed to ensuring that your information is secure. We have implemented appropriate technical and
          organizational measures to protect your personal data from unauthorized access, alteration, disclosure, or
          destruction.</p>
        <p>However, please be aware that no method of internet transmission or electronic storage is 100% secure. While
          we strive to protect your personal information, we cannot guarantee its absolute security.
        </p>
        <h4>Data Sharing and Disclosure</h4>
        <p>We do not sell, trade, or otherwise transfer your personal information to outside parties, except:</p>
        <ul>
          <li><strong>Government Authorities:</strong>As required by the Directorate of Handloom and Textiles or other
            government bodies for regulatory and legal compliance.</li>
          <li><strong>Service Providers:</strong> Trusted third parties who assist us in operating our website,
            conducting our business, or servicing you, provided that they agree to keep your information confidential.
          </li>
          <li><strong>Legal Requirements:</strong> When required to do so by law or in response to valid requests by
            public authorities.</li>
        </ul>
        <h4>Your Rights</h4>
        <p>You have the following rights concerning your personal information:</p>
        <ul>
          <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
          <li><strong>Correction:</strong> You can request correction of any inaccuracies in your personal information.
          </li>
          <li><strong>Deletion:</strong> You can request deletion of your personal information, subject to certain
            exceptions provided by law.</li>
          <li><strong>Objection:</strong> You can object to the processing of your personal information in certain
            circumstances.</li>
        </ul>
        <p>To exercise any of these rights, please contact us using the contact details provided below.</p>
        <h4>Cookies</h4>
        <p>Our website may use cookies to enhance your user experience. Cookies are small files stored on your device
          that allow us to recognize your browser and capture certain information. You can choose to disable cookies
          through your browser settings, but this may limit your ability to use some features of our website.</p>
        <h4>Changes to This Privacy Policy</h4>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last
          Updated" date at the bottom of this policy will be revised accordingly. We encourage you to review this
          Privacy Policy periodically to stay informed about how we are protecting your information.</p>
        <h4>Contact Us</h4>
        <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
        <p><strong>Directorate of Handloom and Textiles</strong>
          Ministry of Industries and Commerce,
          Government of Kerala,
          Thiruvananthapuram, Kerala, India.
        </p>
        <p><strong>Indian Institute of Handloom Technology (IIHT) Thottada</strong>
          Kannur, Kerala, India.
        </p>
      </div>
    </div>
  </section>
    </>);
}
export default PrivacyPolicy;
