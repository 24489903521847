import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AdminForgotPassword = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otpErrors, setOtpErrors] = useState({});
  const [isOtpVerified, setIsOtpVerified] = useState(false); // State to manage OTP verification
  const redirect = useNavigate();

  const apiUrl = process.env.REACT_APP_BASE_URL;

  // Phone number validation (e.g., must be 10 digits)
  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  };

  // Handle form submission for phone number
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    // Validate phone number
    if (!validatePhoneNumber(phoneNumber)) {
      validationErrors.phoneNumber = 'Please enter a valid 10-digit phone number.';
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return; // Stop submission if there are validation errors
    }

    try {
      setLoading(true);

      // Simulate a backend API call to send password reset link or OTP
      await sendPasswordResetRequest(phoneNumber);

    } catch (err) {
      setErrors({ form: 'An error occurred. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const sendPasswordResetRequest = async (phoneNumber) => {
    fetch(`${apiUrl}send_admin_otp`, {
      method: "POST",
      body: JSON.stringify({ phoneNumber }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((jsonResponse) => {
        if (jsonResponse.status) {
          setErrors({});
          setShowOtpModal(true); // Show OTP modal on success
        } else {
          setErrors({ form: jsonResponse.message });
        }
      });
  };

  // Handle OTP submission
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    // Validate OTP
    if (otp.length !== 5) {
      validationErrors.otp = 'Please enter a valid 5-digit OTP.';
    }

    setOtpErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return; // Stop submission if there are validation errors
    }

    try {
      setLoading(true);

      // Submit OTP to backend API
      await verifyOtp(otp);

    } catch (err) {
      setOtpErrors({ form: 'An error occurred. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const verifyOtp = async (otp) => {
    fetch(`${apiUrl}check_otp`, {
      method: "POST",
      body: JSON.stringify({ otp , phoneNumber}),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((jsonResponse) => {
        if (jsonResponse.status) {
          setIsOtpVerified(true); // OTP verified successfully
          setShowOtpModal(false); // Hide OTP modal
          setOtpErrors({});
        } else {
          setOtpErrors({ form: jsonResponse.message });
        }
      });
  };

  // Handle new password submission
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    // Validate Password
    if (!newPassword) {
      validationErrors.newPassword = 'New password is required.';
    } else if (newPassword !== confirmPassword) {
      validationErrors.confirmPassword = 'Passwords do not match.';
    }

    setOtpErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return; // Stop submission if there are validation errors
    }

    try {
      setLoading(true);

      // Submit new password to backend API
      await resetPassword(newPassword);

    } catch (err) {
      setOtpErrors({ form: 'An error occurred. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (newPassword) => {
    fetch(`${apiUrl}auth/change-admin-password`, {
      method: "POST",
      body: JSON.stringify({ phoneNumber, newPassword}),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((jsonResponse) => {
        if (jsonResponse.status) {
          setMessage('Password has been successfully reset.');
          setOtpErrors({});
          redirect('/admin/login'); // Redirect to login page after password reset
        } else {
          setOtpErrors({ form: jsonResponse.message });
        }
      });
  };

  return (
    <div className="container-fluid p-0 vh-100 d-flex flex-column" style={{ backgroundColor: "#f8f9fa" }}>
      {/* Header */}
      <header className="py-3" style={{ backgroundColor: "#28a745", color: "#fff" }}>
        <div className="container text-center">
          <h1>Kerala Kaithari</h1>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-fill d-flex justify-content-center align-items-center " style={{height: '80vh'}}>
        <div className="row justify-content-center w-100">
          <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <div className="card shadow-lg border-0">
              <div className="card-body p-4">
                <h2 className="text-center mb-4" style={{ color: "#28a745" }}>Forgot Password</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <label htmlFor="phone" className="form-label">Phone Number</label>
                    <input
                      type="text"
                      className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                      id="phone"
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      disabled={loading || isOtpVerified}
                    />
                    {errors.phoneNumber && (
                      <div className="invalid-feedback">
                        {errors.phoneNumber}
                      </div>
                    )}
                  </div>

                  {errors.form && (
                    <div className="alert alert-danger">
                      {errors.form}
                    </div>
                  )}

                  {message && (
                    <div className="alert alert-success">
                      {message}
                    </div>
                  )}

                  {!isOtpVerified && (
                    <button type="submit" className="btn btn-success btn-block mb-3" disabled={loading}>
                      {loading ? 'Sending...' : 'Reset Password'}
                    </button>
                  )}
                </form>

                {isOtpVerified && (
                  <form onSubmit={handlePasswordSubmit}>
                    <div className="form-group mb-3">
                      <label htmlFor="newPassword" className="form-label">New Password</label>
                      <input
                        type="password"
                        className={`form-control ${otpErrors.newPassword ? 'is-invalid' : ''}`}
                        id="newPassword"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        disabled={loading}
                        readOnly={isOtpVerified}
                      />
                      {otpErrors.newPassword && (
                        <div className="invalid-feedback">
                          {otpErrors.newPassword}
                        </div>
                      )}
                    </div>

                    <div className="form-group mb-3">
                      <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                      <input
                        type="password"
                        className={`form-control ${otpErrors.confirmPassword ? 'is-invalid' : ''}`}
                        id="confirmPassword"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        disabled={loading}
                      />
                      {otpErrors.confirmPassword && (
                        <div className="invalid-feedback">
                          {otpErrors.confirmPassword}
                        </div>
                      )}
                    </div>

                    {otpErrors.form && (
                      <div className="alert alert-danger">
                        {otpErrors.form}
                      </div>
                    )}

                    <button type="submit" className="btn btn-success btn-block" disabled={loading}>
                      {loading ? 'Resetting...' : 'Submit New Password'}
                    </button>
                  </form>
                )}

                <div className="text-center mt-3">
                  <a href="/admin/login" className="text-success">Back to Login</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* OTP Modal */}
      <Modal show={showOtpModal} onHide={() => setShowOtpModal(false)}>
        <Modal.Header >
          <Modal.Title>Enter OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleOtpSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="otp" className="form-label">OTP</label>
              <input
                type="text"
                className={`form-control ${otpErrors.otp ? 'is-invalid' : ''}`}
                id="otp"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                disabled={loading}
              />
              {otpErrors.otp && (
                <div className="invalid-feedback">
                  {otpErrors.otp}
                </div>
              )}
            </div>

            {otpErrors.form && (
              <div className="alert alert-danger">
                {otpErrors.form}
              </div>
            )}

            <Button type="submit" className="btn btn-success btn-block" disabled={loading}>
              {loading ? 'Verifying...' : 'Submit'}
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      {/* Footer */}
      <footer className="py-3 mt-auto" style={{ backgroundColor: "#28a745", color: "#fff" }}>
        <div className="container text-center">
          <p className="mb-0">© 2024 Kerala Kaithari. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default AdminForgotPassword;
