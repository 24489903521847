import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const AcceptProductModal = ({ show, handleClose, onSubmit }) => {
  const [name1, setName1] = useState("");
  const [designation1, setDesignation1] = useState("");
  const [name2, setName2] = useState("");
  const [designation2, setDesignation2] = useState("");
  const [errors, setErrors] = useState({ name1: "", designation1: "", name2: "", designation2: "" });

  const handleFormSubmit = () => {
    const validationErrors = {};

    // Validate Name 1
    if (!name1.trim()) {
      validationErrors.name1 = "Name 1 is required";
    }

    // Validate Designation 1
    if (!designation1.trim()) {
      validationErrors.designation1 = "Designation 1 is required";
    }

    // Validate Name 2
    if (!name2.trim()) {
      validationErrors.name2 = "Name 2 is required";
    }

    // Validate Designation 2
    if (!designation2.trim()) {
      validationErrors.designation2 = "Designation 2 is required";
    }

    setErrors(validationErrors);

    // If no validation errors, submit the form
    if (Object.keys(validationErrors).length === 0) {
      onSubmit({ name1, designation1, name2, designation2 }); // Pass the data to the parent component
      handleClose(); // Close the modal after submission
      setName1('');
      setDesignation1('');
      setName2('');
      setDesignation2('');
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header >
        <Modal.Title>Approve Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Name 1</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Name 1"
            value={name1}
            isInvalid={!!errors.name1} // Show error state
            onChange={(e) => {
              setName1(e.target.value);
              setErrors((prevErrors) => ({ ...prevErrors, name1: "" })); // Clear error
            }}
          />
          <Form.Control.Feedback type="invalid">{errors.name1}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Designation 1</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Designation 1"
            value={designation1}
            isInvalid={!!errors.designation1} // Show error state
            onChange={(e) => {
              setDesignation1(e.target.value);
              setErrors((prevErrors) => ({ ...prevErrors, designation1: "" })); // Clear error
            }}
          />
          <Form.Control.Feedback type="invalid">{errors.designation1}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Name 2</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Name 2"
            value={name2}
            isInvalid={!!errors.name2} // Show error state
            onChange={(e) => {
              setName2(e.target.value);
              setErrors((prevErrors) => ({ ...prevErrors, name2: "" })); // Clear error
            }}
          />
          <Form.Control.Feedback type="invalid">{errors.name2}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Designation 2</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Designation 2"
            value={designation2}
            isInvalid={!!errors.designation2} // Show error state
            onChange={(e) => {
              setDesignation2(e.target.value);
              setErrors((prevErrors) => ({ ...prevErrors, designation2: "" })); // Clear error
            }}
          />
          <Form.Control.Feedback type="invalid">{errors.designation2}</Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleFormSubmit}>
          Approve
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptProductModal;
