import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import ExportButtons from "./ExportButtons";

const ProductReport = () => {
  const authToken = localStorage.getItem("admin-token");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [products, setProductsData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]); // Store filtered data

  const [fromDate, setFromDate] = useState(""); // Store the 'from' date
  const [toDate, setToDate] = useState(""); // Store the 'to' date
  const [societyName, setSocietyName] = useState(""); // Store the selected society name
  const [productName, setProductName] = useState(""); // Store the selected product name

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}product/get-list/1`, {
        method: "GET",
        headers: {
          Authorization: `jwt ${authToken}`,
          "Content-Type": "application/json",
        },
      });

      const jsonData = await response.json();

      if (jsonData.status) {
        const transformedData = jsonData.data.map((item, index) => ({
          sl: index + 1, // Start sequence from 1
          code: item.register_number,
          product_name: item.product_name,
          society_name: item.name,
          product_code: item.product_code,
          warp: item.warp,
          weft: item.weft,
          status: item.status,
          email: item.email,
          weaver: item.weaver,
          date: item.date?.split("T")[0], // Extract only the date part
          epi: item.epi,
          length: item.length,
          width: item.width,
          ppi: item.ppi,
          structure: item.structure,
          weaver_position: item.weaver_position,
          id: item.id,
        }));

        setProductsData(transformedData);
        setFilteredProducts(transformedData); // Initialize filteredProducts with all data
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Filter products based on selected filters
  useEffect(() => {
    let filteredData = products;

    if (societyName) {
      filteredData = filteredData.filter((product) =>
        product.society_name.toLowerCase().includes(societyName.toLowerCase())
      );
    }

    if (productName) {
      filteredData = filteredData.filter((product) =>
        product.product_name.toLowerCase().includes(productName.toLowerCase())
      );
    }

    if (fromDate && toDate) {
      filteredData = filteredData.filter((product) => {
        const productDate = new Date(product.date);
        return productDate >= new Date(fromDate) && productDate <= new Date(toDate);
      });
    }

    setFilteredProducts(filteredData);
  }, [fromDate, toDate, societyName, productName, products]);

  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);
  const handleSocietyNameChange = (e) => setSocietyName(e.target.value);
  const handleProductNameChange = (e) => setProductName(e.target.value);

  const table_header = [
    {
      prop: "sl",
      title: "Sl No",
    },
    {
      prop: "product_name",
      title: "Product Name",
      isFilterable: true,
    },
    {
      prop: "product_code",
      title: "Product Code",
      isFilterable: true,
    },
    {
      prop: "weaver",
      title: "Weaver",
      isFilterable: true,
    },
    {
      prop: "society_name",
      title: "Society Name",
      isFilterable: true,
    },
    {
      prop: "code",
      title: "Society Reg No",
      isFilterable: true,
    },
    {
      prop: "warp",
      title: "WARP",
      isFilterable: true,
    },
    {
      prop: "weft",
      title: "WEFT",
      isFilterable: true,
    },
    {
      prop: "epi",
      title: "EPI",
      isFilterable: true,
    },
    {
      prop: "length",
      title: "Length",
      isFilterable: true,
    },
    {
      prop: "width",
      title: "Width",
      isFilterable: true,
    },
    {
      prop: "ppi",
      title: "PPI",
      isFilterable: true,
    },
    {
      prop: "structure",
      title: "Structure",
      isFilterable: true,
    },
    {
      prop: "weaver_position",
      title: "Position",
      isFilterable: true,
    },
    {
      prop: "date",
      title: "Date",
      isFilterable: true,
    },
  ];

  return (
    <>
      <h3>Product Report</h3>

      <Row className="mb-4 p-2">
        <Col xs={12} sm={6} lg={3}>
          <label>Filter by Society Name:</label>
          <input
            type="text"
            className="form-control"
            value={societyName}
            onChange={handleSocietyNameChange}
            placeholder="Enter Society Name"
          />
        </Col>

        <Col xs={12} sm={6} lg={3}>
          <label>Filter by Product Name:</label>
          <input
            type="text"
            className="form-control"
            value={productName}
            onChange={handleProductNameChange}
            placeholder="Enter Product Name"
          />
        </Col>

        <Col xs={12} sm={6} lg={3} >
          <label>From Date:</label>
          <input
            type="date"
            className="form-control"
            value={fromDate}
            onChange={handleFromDateChange}
          />
        </Col>

        <Col xs={12} sm={6} lg={3} >
          <label>To Date:</label>
          <input
            type="date"
            className="form-control"
            value={toDate}
            onChange={handleToDateChange}
          />
        </Col>
      </Row>

      <DatatableWrapper
        body={filteredProducts}
        headers={table_header}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10,
            options: [5, 10, 15, 20],
          },
        }}
      >
        <Row className="mb-4 p-2">
          <Col
            xs={12}
            sm={12}
            lg={6}
            className="d-flex flex-col justify-content-lg-start align-items-start justify-content-sm-start mb-2 mb-sm-0"
          >
            <PaginationOptions />
          </Col>
          <Col xs={12} sm={12} lg={6} className="mt-3 float-right">
            <ExportButtons data={filteredProducts} headers={table_header} />
          </Col>
        </Row>

        <div style={{ overflowX: "auto", backgroundColor: "#f5fbf8", padding: "1rem", borderRadius: "0.25rem" }}>
          <Table style={{ backgroundColor: "#f5fbf8" }}>
            <TableHeader />
            <TableBody />
          </Table>
        </div>

        <Row className="mb-4 p-2">
          <Col
            xs={12}
            sm={12}
            lg={12}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Pagination />
          </Col>
        </Row>
      </DatatableWrapper>
    </>
  );
};

export default ProductReport;
