import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const Login = ({setAccessToken}) => {
  const [formDetails, setFormDetails] = useState({
    phone: '',
    password: ''
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const redirect = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;

  // const handleClose = () => setErrors({});

  const validate = () => {
    const newErrors = {};
    const { phone, password } = formDetails;

    // Validate phone number
    if (!phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d+$/.test(phone)) {
      newErrors.phone = 'Phone number must be a valid number';
    }

    // Validate password
    if (!password) {
      newErrors.password = 'Password is required';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validate()) return;

    checkUser();
  };

  const checkUser = () => {
    setLoading(true);
    fetch(`${apiUrl}auth/society-signin`, {
      method: "POST",
      body: JSON.stringify(formDetails),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((jsonResponse) => {
        if (jsonResponse.status) {
          setErrors({});
          setLoading(false);
          localStorage.setItem('token', jsonResponse.data.token);
          localStorage.setItem('societyDetails', JSON.stringify(jsonResponse.data.society));
          setAccessToken(jsonResponse.data.token)
          redirect('/dashboard');
        } else {
          setErrors({...jsonResponse.message});
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    const checkAuth = async () => {
      const authToken = localStorage.getItem("token");
      if (authToken) {
        redirect('/dashboard');
      }
    };

    checkAuth();
  }, [redirect]);

  return (
    <section className="pt-2 pb-2 pt-md-5 pb-md-4">
      <form method="post" onSubmit={handleSubmit}>
        <div className="form_main_560">
          <div className="text-center pb-2 pb-md-4">
            <h2>Login</h2>
          </div>

          {/* {errors.phone && (
            <div className="alert alert-danger" role="alert">
              {errors.phone}
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>


          )}
          {errors.password && !errors.phone && (
            <div className="alert alert-danger" role="alert">
              {errors.password}
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )} */}
          <ul className="form_ul">
            <li>
              <label htmlFor="phone">Phone Number</label>
              <div className="input-group mb-2 mb-md-4">
                <div className="input-group-prepend">
                  <div className="input-group-text">+91</div>
                </div>

                <input
                  type="text"
                  className={`form-control input_style ${
                    errors.phone ? "is-invalid" : ""
                  }`}
                  placeholder="Enter Phone Number"
                  autoComplete="off"
                  id="phone"
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      phone: e.target.value,
                    })
                  }
                  value={formDetails.phone}
                />
                 {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
              </div>
            </li>

            <li>
              <div className="form-group mb-2 mb-md-4">
                <label htmlFor="password">Password</label>
                <div className="position-relative">
                  <input
                    type="password"
                    
                    className={`form-control input_style ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    id="password"
                    autoComplete="off"
                    placeholder="Password"
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        password: e.target.value,
                      })
                    }
                    value={formDetails.password}
                  />
                    {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                </div>
              </div>
            </li>
            <li className="text-right mb-2 mb-md-4">
              <a href="/forgot-password">Forgot Password?</a>
            </li>
            <li className="mb-2 mb-md-4">
              <div className="form-group">
                {loading ? (
                  <button type="button" className="btn btn-primary w-100 text-center">
                    <ThreeDots
                      visible={true}
                      height="40"
                      width="40"
                      color="#fff"
                      radius="1"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{ display: 'block' }}
                    />
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary w-100">
                    LOGIN
                  </button>
                )}
              </div>
            </li>
            <li className="text-center bottom_txt">
              <p>
                Don’t have an account? <a href="/register">Register Now</a>
              </p>
            </li>
          </ul>
        </div>
      </form>
    </section>
  );
};

export default Login;
