import React, { useState } from "react";
import html2pdf from "html2pdf.js";
import { ThreeDots } from "react-loader-spinner";

const DownloadPdf = ({ societyName, regNumber, approvalDate }) => {
   
const [loadingCertificate, setLoadingCertificate] = useState(false);

const handleDownload = async () => {
  setLoadingCertificate(true);
  try {
    const response = await fetch("/assets/certificate.html");
    if (!response.ok) {
      throw new Error('Failed to load certificate template');
    }

    const htmlContent = await response.text();

    const tempDiv = document.createElement("div");
    // tempDiv.style.position = "absolute";
    tempDiv.style.left = "-9999px";
    tempDiv.style.width = "210mm"; // A4 width
    tempDiv.style.height = "296.7mm"; // A4 height
    tempDiv.style.overflow = "hidden";

    tempDiv.innerHTML = htmlContent;

    // Set the values from props
    tempDiv.querySelector("#pdfRegNumber").textContent = regNumber;
    tempDiv.querySelector("#pdfAutherisedDate").textContent = formatDate(approvalDate);
    tempDiv.querySelector("#pdfApprovalDate1").textContent = formatDate1(approvalDate);
    tempDiv.querySelector("#pdfApprovalDate2").textContent = formatDate1(approvalDate);
    tempDiv.querySelector("#pdfAsocietyName1").textContent = societyName;
    tempDiv.querySelector("#pdfAsocietyName2").textContent = societyName;

    // Append for debugging (optional)
    document.body.appendChild(tempDiv);

    // PDF options
    const opt = {
      margin: 0,
      filename: `${regNumber}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 5,
        useCORS: true,
      },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    // Generate and download the PDF
    await html2pdf().from(tempDiv).set(opt).save();

    // Remove the temporary element after saving
    document.body.removeChild(tempDiv);
    setLoadingCertificate(false);
  } catch (error) {
    console.error("Error generating PDF:", error);
    setLoadingCertificate(false);
  }
};






function formatDate(dateString) {
  // Parse the date string to get the Date object
  const date = new Date(dateString);
  
  // Array of month names
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  
  // Function to get the ordinal suffix for the day
  function getOrdinal(day) {
    if (day >= 11 && day <= 13) return day + 'th';
    switch (day % 10) {
      case 1: return day + 'st';
      case 2: return day + 'nd';
      case 3: return day + 'rd';
      default: return day + 'th';
    }
  }

  // Get the day, month, and year from the Date object
  const day = date.getUTCDate(); // Using getUTCDate() to avoid timezone shifts
  const month = months[date.getUTCMonth()]; // Using getUTCMonth() for consistency
  const year = date.getUTCFullYear(); // Using getUTCFullYear()

  // Format the date as "2nd August 2024"
  return `${getOrdinal(day)} ${month} ${year}`;
}

function formatDate1(dateString) {
  // Parse the date string to get the Date object
  const date = new Date(dateString);
  
  // Get the day, month, and year from the Date object
  const day = String(date.getUTCDate()).padStart(2, '0'); // Ensure 2 digits for day
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Ensure 2 digits for month
  const year = date.getUTCFullYear(); // No need to pad the year

  // Format the date as "DD-MM-YYYY"
  return `${day}-${month}-${year}`;
}
  return (
    <a
            className="download-logo"
            // href="./assets/images/certificate_of_registration.pdf"
            download
            onClick={(e) => handleDownload()}
            style={{color:"#fff",cursor: 'pointer'}}
            // href="#1"
          >
            {loadingCertificate ? (
               <ThreeDots
               visible={true}
               height="20"
               width="40"
               color="#fff"
               radius="1"
               ariaLabel="three-dots-loading"
               wrapperStyle={{ display: 'block' }}
             />
            ) : (
              "Certificate"
            )}
          </a>
  );
};

export default DownloadPdf;