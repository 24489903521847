import React from "react";
import {Routes, Route } from "react-router-dom";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
import Home from "../components/Home";
import Product from "../components/Product";
import ProductDetails from "../components/ProductDetails";
import EditProduct from "../components/EditProduct";
import Dashboard from "../components/Dashboard";
import PrivacyPolicy from "../components/PrivacyPolicy";
import AboutUs from "../components/AboutUs";
import Stores from "../components/Stores";
import NotFound from "../pages/NotFound";
import ForgotPassword from "../components/auth/ForgotPassword";
// import Administrator from "../administrator/Login";
import AdminDashboard from "../components/administrator/Dashboard";
// import Register from "../Auth/Register";
const Routing = ({ accessToken, setAccessToken }) => {
  return (
    <>
      {/* <BrowserRouter> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login  setAccessToken={setAccessToken} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/add-product" element={<Product />} />
          <Route path="/edit-product/:id" element={<EditProduct />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/stores" element={<Stores />} />
          {/* <Route path="/admin" element={<Administrator />} /> */}
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      {/* </BrowserRouter> */}
    </>
  );
};

export default Routing;