import React, { useState, useEffect } from "react";

import { Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";


import ExportButtons from './ExportButtons'; // Import the ExportButtons component
import SocietyDetailsModal from './SocietyDetailsModal'; 
const Society = () => {
 
  const authToken = localStorage.getItem("admin-token");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [products, setProductsData] = useState([]);      
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [selectedSociety, setSelectedSociety] = useState(null);
  const [onlyProd, setOnlyProd] = useState(true);   

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await fetch(
          `${apiUrl}admin/get_by_status/1`,
          {
            method: "GET",
            headers: {
              Authorization: `jwt ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const jsonData = await response.json();

        if (jsonData.status) {
          const transformedData = jsonData.data.map((item, index) => ({
            sl: index + 1, // Start sequence from 1
          code: item.register_number,
          name: item.name,
          district_name: item.district_name,
          taluk_name: item.taluk_name,
          village_name: item.village_name,
          phone: item.phone,
          status: item.status,
          number: item.society_number,
          id: item.id,
          address: item.address,
          contact_person: item.contact_person,
          second_phone: item.second_phone,
          approved_date: item.approved_date?.slice(0,10),
          }));

          setProductsData(transformedData);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

   
    fetchData();
  }, []);



  const table_header = [
    {
      prop: "sl",
      title: "Sl No",
    },
    {
      prop: "name",
      title: "Society Name",
      isFilterable: true,
      cell: (row) =>(<>
         <a
           href="#!"
            onClick={(e) => {
              e.preventDefault();
              setSelectedSociety(row);
              setOnlyProd(false);
              setShowDetailsModal(true);
            }}
          >
           {row.name} 
          </a>
          </>
         ) 
    },
    {
      prop: "number",
      title: "Society Number",
      isFilterable: true,
    },
    {
      prop: "code",
      title: "Reg No",
      isFilterable: true,
    },
    {
      prop: "district_name",
      title: "District",
      isFilterable: true,
    },
    {
      prop: "taluk_name",
      title: "Taluk",
      isFilterable: true,
    },
    {
      prop: "village_name",
      title: "Village",
      isFilterable: true,
    },
    {
      prop: "phone",
      title: "Phone No",
      isFilterable: true,
    },
    {
      prop: "approved_date",
      title: "Approv Date",
      isFilterable: true,
    },
    {
      prop: "sl",
      title: "Action",
      cell: (row) =>(
          <a
                className="td-item"
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  setOnlyProd(true);
                  setSelectedSociety(row);
                  setShowDetailsModal(true);
                }}
                
              >
                  Products
              </a>
              
          
        ) 
    },
  ];

  return (
    <>
    <h3>Accepted Society</h3>
    <SocietyDetailsModal
        show={showDetailsModal}
        handleClose={() => setShowDetailsModal(false)}
        society={selectedSociety}
        onlyProd ={onlyProd}
      />
    
    <DatatableWrapper
      body={products}
      headers={table_header}
      paginationOptionsProps={{
        initialState: {
          rowsPerPage: 10,
          options: [5, 10, 15, 20],
        },
      }}
    >
      <Row className="mb-4 p-2">
        <Col
          xs={6}
          sm={6}
          lg={3}
          className="d-flex flex-col justify-content-lg-start align-items-start justify-content-sm-start mb-2 mb-sm-0"
        >
          <PaginationOptions />
        </Col>
        <Col
          xs={6}
          sm={6}
          lg={3}
          className="d-flex flex-col justify-content-lg-start align-items-start justify-content-sm-start mb-2 mb-sm-0"
        >
          <ExportButtons data={products} headers={table_header} />
        </Col>
        <Col
          xs={12}
          sm={12}
          lg={6}
          className="d-flex flex-col justify-content-lg-start align-items-start justify-content-sm-start mb-2 mb-sm-0"
        >
          <Filter />
        </Col>
      </Row>
      <div style={{ overflowX: "auto", backgroundColor: "#f5fbf8", padding: "1rem", borderRadius: "0.25rem" }}>
      <Table style={{ backgroundColor: "#f5fbf8" }}>
        <TableHeader />
        <TableBody />
      </Table>
      </div>
      <Row className="mb-4 p-2">
        <Col
          xs={12}
          sm={12}
          lg={12}
          className="d-flex flex-col justify-content-end align-items-end"
        >
          <Pagination />
        </Col>
      </Row>
    </DatatableWrapper></>
  );
};

export default Society;