import React from 'react';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the plugin
import ExcelJS from 'exceljs';

const ExportButtons = ({ data, headers }) => {
  // Specify the column titles or props to exclude
  const excludedColumns = ['Action']; // Replace 'Actions' with the actual title or prop of your button column

  // Convert data to export-friendly format for CSV and Excel, excluding the unwanted columns
  const exportData = data.map((row) => {
    const exportRow = {};
    headers.forEach((header) => {
      if (!excludedColumns.includes(header.title)) {
        exportRow[header.title] = row[header.prop];
      }
      if (header.title=='Status') {
        exportRow[header.title] = row[header.prop]?'Approved':'Pending';
      }
    });
    return exportRow;
  });

// Function to export Excel file with adjusted column widths and colored headers
const exportExcel_old = () => {
  const ws = XLSX.utils.json_to_sheet(exportData);

  // Calculate max length for each column
  const maxLengths = headers.map(header => {
    const columnValues = exportData.map(row => (row[header.title] || '').toString());
    const maxLength = Math.max(
      header.title.length,
      ...columnValues.map(value => value.length)
    );
    return maxLength;
  });

  // Set column widths in the worksheet
  ws['!cols'] = maxLengths.map(length => ({
    width: length + 2 // Add some padding
  }));

  // Define header style
  const headerStyle = {
    fill: {
      fgColor: { rgb: 'FFFF00' } // Yellow background
    },
    font: {
      bold: true
    }
  };

  // Apply header style
  headers.forEach((header, index) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index }); // Row 0 (header row), column index
    if (!ws[cellAddress]) ws[cellAddress] = {}; // Ensure cell exists
    ws[cellAddress].s = headerStyle; // Apply the style to each header cell
  });

  // Create a new workbook and append the styled worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Write the workbook to a file
  XLSX.writeFile(wb, 'data.xlsx');
};


// Function to export Excel file with header colors and adjusted column widths
const exportExcel = async () => {
  // Create a new workbook and add a worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  // Define the headers, excluding the unwanted columns
  const headerTitles = headers
    .filter((header) => !excludedColumns.includes(header.title))
    .map((header) => header.title);

  // Add headers with style
  worksheet.addRow(headerTitles);
  const headerRow = worksheet.getRow(1);

  // Apply styles to the header row
  headerRow.eachCell((cell) => {
    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // White text
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF4CAF50' }, // Green background
    };
    cell.alignment = { vertical: 'middle', horizontal: 'center' };
  });

  // Add data rows
  exportData.forEach((row) => {
    worksheet.addRow(Object.values(row));
  });

  // Adjust column widths to fit the content
  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellLength = cell.value ? cell.value.toString().length : 10;
      if (cellLength > maxLength) {
        maxLength = cellLength;
      }
    });
    column.width = maxLength + 2; // Add some padding
  });

  // Write the workbook to a file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);

  // Create a link to download the file
  const a = document.createElement('a');
  a.href = url;
  a.download = 'data.xlsx';
  a.click();
  window.URL.revokeObjectURL(url);
};

  // Function to export PDF
  const exportPDF = () => {
    const doc = new jsPDF('l', 'mm', 'a4');
    const columns = headers
      .filter((header) => !excludedColumns.includes(header.title))
      .map((header) => header.title);
    const rows = exportData.map((row) => Object.values(row));

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save('data.pdf');
  };

  return (
    <div className="d-flex justify-content-end mb-2">
      {/* CSV Export */}
      <CSVLink
        data={exportData}
        filename="data.csv"
        className="btn btn-success me-2 pt-2"
      >
        CSV
      </CSVLink> &nbsp;

      {/* Excel Export */}
      <button className="btn btn-success me-2" onClick={exportExcel}>
        Excel
      </button>&nbsp;

      {/* PDF Export */}
      <button className="btn btn-success" onClick={exportPDF}>
        PDF
      </button>
    </div>
  );
};

export default ExportButtons;
