import React, { useState,useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation

const AdminLogin = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const redirect = useNavigate();
  
  const apiUrl = process.env.REACT_APP_BASE_URL;

  // Phone number validation (e.g., must be 10 digits)
  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    // Validate phone number
    if (!validatePhoneNumber(phoneNumber)) {
      validationErrors.phoneNumber = 'Please enter a valid 10-digit phone number.';
    }

    // Validate password
    if (!password) {
      validationErrors.password = 'Password is required.';
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return; // Stop submission if there are validation errors
    }

    try {
      setLoading(true);

      // Simulate a backend API call
      checkUser(phoneNumber, password);

    } catch (err) {
      setErrors({ form: 'An error occurred. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const checkUser = (phoneNumber, password) => {
    fetch(`${apiUrl}auth/admin-signin`, {
      method: "POST",
      body:JSON.stringify({phone:phoneNumber, password:password}),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((jsonResponse) => {
        if (jsonResponse.status) {
        
          localStorage.setItem('admin-token', jsonResponse.data.token);
          localStorage.setItem('adminDetails', JSON.stringify(jsonResponse.data.user));
          redirect('/admin/dashboard');
        } else {
          setErrors({...jsonResponse.message});
          setLoading(false);
        }
      });
  };


  useEffect(() => {
    const checkAuth = async () => {
      const authToken = localStorage.getItem("admin-token");
      if (authToken) {
        redirect('/admin/dashboard');
      }
    };

    checkAuth();
  }, [redirect]);
  return (
    <div className="container-fluid p-0 vh-100 d-flex flex-column" style={{ backgroundColor: "#f8f9fa" }}>
      {/* Header */}
      <header className="py-3" style={{ backgroundColor: "#28a745", color: "#fff" }}>
        <div className="container text-center">
          {/* <h1>Kerala Kaithari</h1> */}
          <div class="logo-block" style={ {
    background: '#fff',
    padding: '15px 15px',
    maxWidth: '250px',
    margin: 'auto',
    borderRadius: '15px'
}}>
                <a href="#"><img src="/assets/images/logo_main.svg" alt="logo" /></a>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-fill d-flex justify-content-center align-items-center " style={{height: '80vh'}}>
        <div className="row justify-content-center w-100">
          <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <div className="card shadow-lg border-0">
              <div className="card-body p-4">
                <h2 className="text-center mb-4" style={{ color: "#28a745" }}>Admin Login</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <label htmlFor="phone" className="form-label">Phone Number</label>
                    <input
                      type="text"
                      className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`}
                      id="phone"
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      disabled={loading}
                    />
                    {errors.phoneNumber && (
                      <div className="invalid-feedback">
                        {errors.phoneNumber}
                      </div>
                    )}
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input
                      type="password"
                      className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                      id="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={loading}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">
                        {errors.password}
                      </div>
                    )}
                  </div>

                  {errors.form && (
                    <div className="alert alert-danger">
                      {errors.form}
                    </div>
                  )}

                  <button type="submit" className="btn btn-success btn-block mb-3" disabled={loading}>
                    {loading ? 'Logging in...' : 'Login'}
                  </button>
                </form>
                <div className="text-center">
                  <a href="/admin/forgot-password" className="text-success">Forgot Password?</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="py-3 mt-auto" style={{ backgroundColor: "#28a745", color: "#fff" }}>
        <div className="container text-center">
          <p className="mb-0">© 2024 Kerala Kaithari. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default AdminLogin;
