import React, { useState, useRef, useEffect } from "react";
import { useLocation ,useNavigate} from 'react-router-dom';

const Header = ({ accessToken, setAccessToken }) => {

  let token = localStorage.getItem('token');
  const location = useLocation(); // Hook to get the current path
  const currentPath = location.pathname;

  const redirect = useNavigate();
  const handleLogout = () => {
    // Add a delay using setTimeout
    setTimeout(() => {
      // fetch('/api/logout', { method: 'POST' }); // Uncomment if needed

      localStorage.removeItem('token');
      localStorage.removeItem('socityDetails');
      setAccessToken('');
      redirect("/");
      // You can also add any other logic you want to run after the delay here
    }, 100); // Delay of 1000 milliseconds (1 second)
  };

  useEffect(() => {
    setAccessToken(token)
  }, [token]);

  // State management for dropdown menu
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // State management for off-canvas menu
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);

  const dropdownMenuRef = useRef(null);
  const dropIconRef = useRef(null);

  // Handle dropdown toggle
  const toggleDropdownMenu = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Close dropdown menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target) &&
        dropIconRef.current &&
        !dropIconRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Handle off-canvas menu toggle
  const toggleOffCanvasMenu = () => {
    setIsOffCanvasOpen((prev) => !prev);
  };

  return (
    <header>
      <div className="top_bar"></div>
      <div className="main-wrap">
        <div className="d-flex align-items-center">
          <button id="menu-toggle" className="menu-toggle" onClick={toggleOffCanvasMenu}>
            <i><img src="/assets/images/menu-ic.png" alt="icon" /></i>
          </button>
          <div className="col-xl-5 first-blk">
            <ul className="menu-links-1 pl-0 mb-0">
              <li>
                <a href="/" className={currentPath === '/' ? 'active' : ''}>
                  <i className="mr-2 icon-holder"><img src="/assets/images/home-ic.png" alt="icon" /></i>Home
                </a>
              </li>
              <li>
                <a href="/about-us" className={currentPath === '/about-us' ? 'active' : ''}>
                  <i className="mr-2 icon-holder"><img src="/assets/images/about-ic.png" alt="icon" /></i>About Us
                </a>
              </li>
              <li>
                <a href="/stores" className={currentPath === '/stores' ? 'active' : ''}>
                  <i className="mr-2 icon-holder"><img src="/assets/images/store-ic.png" alt="icon" /></i>Stores
                </a>
              </li>
            </ul>
          </div>
          <div className="col-xl-2 col-2 center-blk">
            <div className="logo-wrapper text-center">
              <a href="/"><img src="/assets/images/logo_main.svg" alt="logo" /></a>
            </div>
          </div>
          <div className="col-xl-5 col-10 right-blk">
            <ul className="menu-links-2 pl-0 mb-0">
              {accessToken ? (
                <>
                  <li><a href="/dashboard" className={currentPath === '/dashboard' ? 'active' : ''}>Dashboard</a></li>
                  <li><a href="#!" onClick={handleLogout}>Logout</a></li>
                </>
              ) : (
                <>
                  <li><a href="/login" className={currentPath === '/login' ? 'active' : ''}>Log in</a></li>
                  <li><a href="/register" className={currentPath === '/register' ? 'active' : ''}>Register</a></li>
                </>
              )}
            <li><a href="/admin/dashboard" target="_blank" >Admin</a></li>
              {/* <li>
                <select className="form-select custom_select" aria-label="Default select example">
                   <option selected>ക</option> 
                   <option value="1">E</option> 
                  <option value="1"><a href="/admin/dashboard" >Admin</a></option>
                </select>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      {/* Off-Canvas Menu */}
      <div id="off-canvas-menu" className={`off-canvas-menu ${isOffCanvasOpen ? 'open' : ''}`}>
        <button id="close-menu" onClick={toggleOffCanvasMenu}><i><img src="/assets/images/close.png" alt="close" /></i></button>
        <nav>
          <ul className="menu-links-1 pl-0 mb-0">
            <li><a href="/" className={currentPath === '/' ? 'active' : ''}>Home</a></li>
            <li><a href="/about-us" className={currentPath === '/about-us' ? 'active' : ''}>About Us</a></li>
            <li><a href="/stores" className={currentPath === '/stores' ? 'active' : ''}>Stores</a></li>
          </ul>
        </nav>
      </div>
      {/* Overlay */}
      {isOffCanvasOpen && (
        <div className="overlay visible" onClick={toggleOffCanvasMenu}></div>
      )}
    </header>
  );
};

export default Header;
