import React, { useState, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import ProductList from "./ProductList";
import DownloadPdf from "../DownloadPdf";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import html2canvas from 'html2canvas';


const SocietyDetailsModal = ({
  show,
  handleClose,
  society,
  updateList,
  onlyProd,
  acceptModal,
  rejecttModal,
}) => {
  const [isEditable, setIsEditable] = useState(true);
  const [data, setSocityData] = useState({});
  const [loading, setloading] = useState(false);
  const [districtdata, setDistricts] = useState([]);
  const [talukdata, setTaluks] = useState([]);
  const [villagedata, setVillages] = useState([]);
  const [district, setSelectedDistricts] = useState("");
  const [taluk, setSelectedTaluks] = useState("");
  const [village, setSelectedVillages] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    register_number: "",
    email: "",
    district: "",
    taluk: "",
    village: "",
    contact_person: "",
    second_phone: "",
    website: "",
    address: "",
  });

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem("admin-token");

  const handleClose1 = () => setError("");
  const handleClose2 = () => setSuccess("");

  // Fetch data only when the modal is opened
  useEffect(() => {
    if (show) {
      setIsEditable(true);
      setError("");
      setSuccess("");
      const fetchData = async () => {
        try {
          const idValue = society.id;

          const response = await fetch(
            `${apiUrl}admin/get_society/${idValue}`,
            {
              method: "GET",
              headers: {
                Authorization: `jwt ${authToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            if (response.statusText === "Unauthorized") {
              return;
            } else {
              throw new Error("Network response was not ok");
            }
          }

          const jsonData = await response.json();
          setSocityData({ ...jsonData.data });
          setSelectedDistricts(jsonData.data.district);
          setSelectedTaluks(jsonData.data.taluk);
          setSelectedVillages(jsonData.data.village);
          fetchDistricts();
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };

      fetchData();
    }
  }, [show, society, apiUrl]);

  const fetchDistricts = async () => {
    try {
      const response = await fetch(`${apiUrl}district`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setDistricts(data.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    const fetchTaluk = async () => {
      try {
        if (district) {
          const response = await fetch(`${apiUrl}taluk/${district}`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          setTaluks(data.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchTaluk();
  }, [district, apiUrl]);

  useEffect(() => {
    const fetchVillage = async () => {
      try {
        if (taluk) {
          const response = await fetch(`${apiUrl}village/${taluk}`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          setVillages(data.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchVillage();
  }, [taluk, apiUrl]);

  const handleChangeDistrict = (event) => {
    setSelectedDistricts(event.target.value);
    setSelectedVillages("");
  };

  const handleChangeTaluk = (event) => {
    setSelectedTaluks(event.target.value);
  };

  const handleChangeVillage = (event) => {
    setSelectedVillages(event.target.value);
  };

  const changeEditStatus = () => {
    setIsEditable((prev) => !prev);
    setSocityData({ ...data });
  };

  const handlePrint = async () => {
    // Wait for modal content to be fully rendered
    setTimeout(async () => {
      const modalContent = document.querySelector('.society-details-modal .product-detail-contianer');
  
      if (modalContent) {
        try {
          // Capture the content of the modal
          const canvas = await html2canvas(modalContent);
          const imgData = canvas.toDataURL('image/png');
  
          // Create a new window and print the image
          const printWindow = window.open('', '', 'height=800,width=800');
          printWindow.document.write('<html><head><title>Print</title></head><body>');
          printWindow.document.write(`<img src="${imgData}" style="width: 100%;"/>`);
          printWindow.document.write('</body></html>');
          printWindow.document.close();
          setTimeout(() => {
          printWindow.focus();
          printWindow.print();},100);
        } catch (error) {
          console.error('Error capturing or printing modal content:', error);
        }
      }
    }, 100); // Adjust the delay as needed
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {
      name: data.name ? "" : "Organization/Society Name is required.",
      phone:
        data.phone && /^\d{10}$/.test(data.phone)
          ? ""
          : "Invalid phone number.",
      email:
        data.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)
          ? ""
          : "Invalid Email.",
      website: 
          !data.website || /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(data.website)
            ? ""
            : "Invalid Website URL.",
      register_number: data.register_number
        ? ""
        : "Registration Number is required.",
      address: data.address
        ? ""
        : "Address is required.",
    
      district: data.district ? "" : "District is required.",
      taluk: data.taluk ? "" : "Taluk is required.",
      village: data.village ? "" : "Village is required.",
      contact_person: data.contact_person
        ? ""
        : "Name of the Concerned Person is required.",
      second_phone:
        data.second_phone && /^\d{10}$/.test(data.second_phone)
          ? ""
          : "Alternative Phone Number is invalid.",
    };

    setErrors(validationErrors);

    if (Object.values(validationErrors).some((error) => error)) {
      setError("Please correct the highlighted errors.");
      return;
    }

    updateSocity();
  };

  const updateSocity = () => {
    setloading(true);
    fetch(`${apiUrl}admin/update_society`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Authorization: `jwt ${authToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setloading(false);
        return res.json();
      })
      .then((jsonResponse) => {
        if (jsonResponse.status) {
          setError("");
          setSuccess(jsonResponse.message);
          changeEditStatus();
        } else {
          setSuccess("");
          setError(jsonResponse.message);
        }
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        updateList();
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      className="society-details-modal"
    >
      <Modal.Body>
        <>
          {onlyProd != true && (
            <div className="verified-bar d-flex justify-content-end p-4">
              {data.status === 1 ? (
                <DownloadPdf
                  societyName={data.name ? data.name : ""}
                  regNumber={data.society_number ? data.society_number : ""}
                  approvalDate={data.approved_date ? data.approved_date : ""}
                />
              ) : null}
             
              <a variant="pl-2 pr-2" style={{color:"#fff",cursor: 'pointer'}} onClick={handleClose}  className="download-logo">&#10006;</a>
            </div>
          )}
          <section className="product-container1"><br/>
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={handleClose2}>
                    &times;
                  </span>
                </button>
              </div>
            )}
            {success && (
              <div className="alert alert-success" role="alert">
                {success}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={handleClose2}>
                    &times;
                  </span>
                </button>
              </div>
            )}
            {onlyProd != true && (
              <form method="post" onSubmit={handleSubmit}>
                <div className="">
                  <div className="product-detail-contianer">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                    {data.status === 1 && ( <div className="profile-details-wrapper ">
                        <div className="organization-details">
                          <h4>
                            Reg. No :{" "}
                            {data.society_number ? data.society_number : ""}
                          </h4>
                        </div>
                      </div>)}
                      {data.status != 1 && (<div className="profile-details-wrapper ">
                        <div className="organization-details">
                        <h4>Reg. Date {data?.date?.slice(0,10)} </h4>
                        </div>
                      </div>)}
                      
                    
                      
                      <p className="approved-edit">
                      <a href="#!"
                          onClick={handlePrint}
                        >
                          Print
                        </a>
                        {data.status ? (
                          <a href="#!" className="pl-5" >Approved</a>
                        ) : (
                          <a onClick={changeEditStatus} href="#!" className="pl-5" >
                            Edit
                          </a>
                        )}
                        
                      </p>
                    </div>
                    {/* <ul className="form-wrapper">
                      <li> */}
                        <div className="row">
                          <div className="col-md-12 col-12" style={{paddingRight: '31px'}}>
                            <div className="form-group mb-2 mb-md-2">
                              <label htmlFor="inputPassword46" style={{color: '#191919',fontSize: '18px',lineHeight: '24px',fontWeight: '400'}}>
                                Organization/Society Name
                              </label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className={`form-control input_style ${
                                    errors.name ? "is-invalid" : ""
                                  }`}
                                  id="inputPassword46"
                                  autoComplete="off"
                                  placeholder="Enter Organization/Society Name"
                                  value={data.name || ""}
                                  readOnly={isEditable}
                                  tabIndex={1}
                                  onChange={(e) =>
                                    setSocityData({
                                      ...data,
                                      name: e.target.value,
                                    })
                                  }
                                />
                                {errors.name && (
                                  <div className="invalid-feedback">
                                    {errors.name}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul className="form-wrapper" style={{margin: '0px'}}>
                        <li>
                        <div className="row">
                          <div className="col-md-12 col-12">
                            <label htmlFor="inlineFormInputGroup">
                              Phone Number
                            </label>
                            <div className="input-group mb-2 mb-md-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">+91</div>
                              </div>
                              <input
                                type="text"
                                className={`form-control input_style ${
                                  errors.phone ? "is-invalid" : ""
                                }`}
                                placeholder="Enter Phone Number"
                                autoComplete="off"
                                value={data.phone || ""}
                                tabIndex={2}
                                disabled={isEditable}
                                onChange={(e) =>
                                  setSocityData({
                                    ...data,
                                    phone: e.target.value,
                                  })
                                }
                              />
                              {errors.phone && (
                                <div className="invalid-feedback">
                                  {errors.phone}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* </li>

                <li> */}

                        
                        <div className="row">
                          <div className="col-md-12 col-12">
                            <label htmlFor="inlineFormInputGroup">
                              District
                            </label>
                            <div className="input-group mb-2 mb-md-2">
                              <select
                                className={`form-control  input_style ${
                                  errors.district ? "is-invalid" : ""
                                }`}
                                value={district || ""}
                                disabled={isEditable}
                                tabIndex={4}
                                onChange={(e) => {
                                  setSocityData({
                                    ...data,
                                    district: e.target.value,
                                  });
                                  handleChangeDistrict(e);
                                }}
                              >
                                <option value="">Select District</option>
                                {districtdata.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.district}
                                  </option>
                                ))}
                              </select>
                              {errors.district && (
                                <div className="invalid-feedback">
                                  {errors.district}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 col-12">
                            <label htmlFor="inlineFormInputGroup">
                              Village
                            </label>
                            <div className="input-group mb-2 mb-md-2">
                              <select
                                className={`form-control custom-select1  input_style ${
                                  errors.village ? "is-invalid" : ""
                                }`}
                                value={village || ""}
                                disabled={isEditable}
                                tabIndex={6}
                                onChange={(e) => {
                                  setSocityData({
                                    ...data,
                                    village: e.target.value,
                                  });
                                  handleChangeVillage(e);
                                }}
                              >
                                <option value="">Select Village</option>
                                {villagedata.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.village}
                                  </option>
                                ))}
                              </select>
                              {errors.village && (
                                <div className="invalid-feedback">
                                  {errors.village}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 col-12">
                            <div className="form-group mb-2 mb-md-2">
                              <label htmlFor="inputPassword45">
                                Name of the Concerned Person
                              </label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className={`form-control input_style ${
                                    errors.contact_person ? "is-invalid" : ""
                                  }`}
                                  id="inputPassword45"
                                  placeholder="Name of the Concerned Person"
                                  autoComplete="off"
                                  value={data.contact_person || ""}
                                  readOnly={isEditable}
                                  tabIndex={8}
                                  onChange={(e) =>
                                    setSocityData({
                                      ...data,
                                      contact_person: e.target.value,
                                    })
                                  }
                                />
                                {errors.contact_person && (
                                  <div className="invalid-feedback">
                                    {errors.contact_person}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-md-12 col-12">
                            <div className="form-group mb-2 mb-md-2">
                              <label htmlFor="inputPassword41">
                               Address
                              </label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className={`form-control input_style ${
                                    errors.address ? "is-invalid" : ""
                                  }`}
                                  id="inputPassword41"
                                  autoComplete="off"
                                  placeholder="Enter Address"
                                  value={data.address || ""}
                                  readOnly={isEditable}
                                  tabIndex={11}
                                  onChange={(e) =>
                                    setSocityData({
                                      ...data,
                                      address: e.target.value,
                                    })
                                  }
                                />
                                {errors.address && (
                                  <div className="invalid-feedback">
                                    {errors.address}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-md-12 col-12">
                            <label htmlFor="inlineFormInputGroup">
                              Alternative Phone Number
                            </label>
                            <div className="input-group mb-2 mb-md-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">+91</div>
                              </div>
                              <input
                                type="text"
                                className={`form-control input_style ${
                                  errors.second_phone ? "is-invalid" : ""
                                }`}
                                placeholder="Enter Alternative Phone Number"
                                autoComplete="off"
                                value={data.second_phone || ""}
                                readOnly={isEditable}
                                tabIndex={10}
                                onChange={(e) =>
                                  setSocityData({
                                    ...data,
                                    second_phone: e.target.value,
                                  })
                                }
                              />
                              {errors.second_phone && (
                                <div className="invalid-feedback">
                                  {errors.second_phone}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>

                      <li>
                      <div className="row">
                          <div className="col-md-12 col-12">
                            <div className="form-group mb-2 mb-md-2">
                              <label htmlFor="inputPassword41">
                                Reg. Number
                              </label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className={`form-control input_style ${
                                    errors.register_number ? "is-invalid" : ""
                                  }`}
                                  id="inputPassword41"
                                  autoComplete="off"
                                  placeholder="Enter Reg. Number"
                                  tabIndex={3}
                                  value={data.register_number || ""}
                                  readOnly={isEditable}
                                  onChange={(e) =>
                                    setSocityData({
                                      ...data,
                                      register_number: e.target.value,
                                    })
                                  }
                                />
                                {errors.register_number && (
                                  <div className="invalid-feedback">
                                    {errors.register_number}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 col-12">
                            <div className="form-group mb-2 mb-md-2">
                              <label htmlFor="inputPassword41">
                               Email
                              </label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className={`form-control input_style ${
                                    errors.email ? "is-invalid" : ""
                                  }`}
                                  id="inputPassword41"
                                  autoComplete="off"
                                  placeholder="Enter Email"
                                  value={data.email || ""}
                                  
                                  tabIndex={5}
                                  readOnly={isEditable}
                                  onChange={(e) =>
                                    setSocityData({
                                      ...data,
                                      email: e.target.value,
                                    })
                                  }
                                />
                                {errors.email && (
                                  <div className="invalid-feedback">
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 col-12">
                            <div className="form-group mb-2 mb-md-2">
                              <label htmlFor="inputPassword42">Taluk</label>
                              <div className="position-relative">
                                <select
                                  className={`form-control custom-select1  input_style ${
                                    errors.taluk ? "is-invalid" : ""
                                  }`}
                                  value={taluk || ""}
                                  disabled={isEditable}
                                  
                                  tabIndex={7}
                                  onChange={(e) => {
                                    setSocityData((prevDetails) => ({
                                      ...data,
                                      taluk: e.target.value,
                                    }));

                                    handleChangeTaluk(e);
                                  }}
                                >
                                  <option value="">Select Taluk</option>
                                  {talukdata.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.taluk}
                                    </option>
                                  ))}
                                </select>
                                {errors.taluk && (
                                  <div className="invalid-feedback">
                                    {errors.taluk}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        
                        <div className="row">
                          <div className="col-md-12 col-12">
                            <div className="form-group mb-2 mb-md-2">
                              <label htmlFor="inputPassword41">
                               Website URL
                              </label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className={`form-control input_style ${
                                    errors.website ? "is-invalid" : ""
                                  }`}
                                  id="inputPassword41"
                                  autoComplete="off"
                                  placeholder="Enter Website URL"
                                  value={data.website || ""}
                                  readOnly={isEditable}
                                  
                                  tabIndex={9}
                                  onChange={(e) =>
                                    setSocityData({
                                      ...data,
                                      website: e.target.value,
                                    })
                                  }
                                />
                                {errors.website && (
                                  <div className="invalid-feedback">
                                    {errors.website}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-md-12 col-12">
                            <label htmlFor="inlineFormInputGroup">
                              Alternative Phone Number
                            </label>
                            <div className="input-group mb-2 mb-md-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">+91</div>
                              </div>
                              <input
                                type="text"
                                className={`form-control input_style ${
                                  errors.second_phone ? "is-invalid" : ""
                                }`}
                                placeholder="Enter Alternative Phone Number"
                                autoComplete="off"
                                value={data.second_phone || ""}
                                readOnly={isEditable}
                                tabIndex={10}
                                onChange={(e) =>
                                  setSocityData({
                                    ...data,
                                    second_phone: e.target.value,
                                  })
                                }
                              />
                              {errors.second_phone && (
                                <div className="invalid-feedback">
                                  {errors.second_phone}
                                </div>
                              )}
                            </div>
                          </div>
                        </div> */}
                        
                      </li>
                    </ul>

                    <div className="row">
                          <div className="col-md-12 col-12" style={{paddingRight: '31px'}}>
                            <div className="form-group mb-2 mb-md-2">
                              <label htmlFor="inputPassword41" style={{color: '#191919',fontSize: '18px',lineHeight: '24px',fontWeight: '400'}}>
                               Address
                              </label>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className={`form-control input_style ${
                                    errors.address ? "is-invalid" : ""
                                  }`}
                                  id="inputPassword41"
                                  autoComplete="off"
                                  placeholder="Enter Address"
                                  value={data.address || ""}
                                  readOnly={isEditable}
                                  tabIndex={11}
                                  onChange={(e) =>
                                    setSocityData({
                                      ...data,
                                      address: e.target.value,
                                    })
                                  }
                                />
                                {errors.address && (
                                  <div className="invalid-feedback">
                                    {errors.address}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                  
                  <div className="mt-4 d-flex justify-content-end w-100">
                            <div className="form-group col-md-5 d-flex justify-content-end pr-0">
                  {data.status === 0 && (<><button
                    type="button"
                    className="btn btn-primary ml-2"
                    onClick={e=>{acceptModal(true);handleClose(true)}}
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    onClick={e=>{rejecttModal(true);handleClose(true)}}
                    className="btn btn-danger  ml-2"
                  >
                    Reject
                  </button></>)}
                  {data.status === 1
                    ? // <div className="mt-4 d-flex justify-content-end w-100">
                      //   <div className="form-group col-md-5 d-flex justify-content-end pr-0">
                      //     <a
                      //       href="/add-product"
                      //       type="button"
                      //       className="btn btn-primary w-60 d-flex align-items-center justify-content-center"
                      //     >
                      //       <span className="mr-2">
                      //         <img src="./assets/images/add_icon.png" alt="" />
                      //       </span>
                      //       Add New Product
                      //     </a>
                      //   </div>
                      // </div>
                      null
                    : isEditable || (
                        <>
                          
                              {loading ? (
                                <button
                                  type="button"
                                  className="btn btn-primary w-60 d-flex align-items-center justify-content-center ml-2"
                                >
                                  <ThreeDots
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#fff"
                                    radius="1"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{ display: "block" }}
                                    wrapperClass=""
                                  />
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-secondary w-60 d-flex align-items-center justify-content-center ml-2"
                                >
                                  Submit
                                </button>
                              )}
                            
                          
                        </>
                      )}
                    </div>
                    </div>
                </div>
              </form>
            )}
            {data.status == 1 ? (
              <ProductList
                status={data.status}
                adminStatus={true}
                socityId={society.id}
              />
            ) : null}
          </section>
        </>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default SocietyDetailsModal;
