import React, { useState, useEffect ,useRef} from "react";

import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import ReCAPTCHA from 'react-google-recaptcha';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Register = () => {


  const [show, setShow] = useState(false);
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate(); // useNavigate should be at the top level

  const handleModalClose = () => setShow(false);
  // const handleModalShow = () => setShow(true);
  
  const [otpStatus, setOtpStatus] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", ""]);
  const [otpErrors, setOtpErrors] = useState(["", "", "", "", ""]);

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyUp = (e, index) => {
    // Handle backspace to move to the previous input
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const [formDetails, setFormDetails] = useState({
    name: '',
    register_number: '',
    phone: '',
    email: '',
    district: '',
    taluk: '',
    village: '',
    address: '',
    contact_person: '',
    second_phone: '',
    password: '',
    reenter_password: '',
    captcha: '',
    website: '',
  });
  
const [formErrors, setFormErrors] = useState({
  name: '',
  register_number: '',
  phone: '',
  email: '',
  district: '',
  taluk: '',
  village: '',
  address: '',
  contact_person: '',
  second_phone: '',
  password: '',
  reenter_password: '',
  captcha: '',
  website: '',
});

  const [districtdata, setDistricts] = useState([]);
  const [talukdata, setTaluks] = useState([]);
  const [villagedata, setVillages] = useState([]);
  
  const [loading, setLoading] = useState(false);
  
  const [error,setError]= useState('');
  const [success,setSuccess]= useState('');
  const handleClose = () => setError('');
  const handleClose2 = () => setSuccess('');

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const capthaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;


  const [district, setSelectedDistricts] = useState("");
  const [taluk, setSelectedTaluks] = useState("");
  const [village, setSelectedVillages] = useState("");

  const recaptcha = useRef()

  const inputRefs = useRef([]);
  const validateForm = () => {
    const errors = {};
  
    // Regular expression for validating email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    // Regular expression for validating phone number
    const phoneRegex = /^\d{10}$/;
  
    const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    // Validate required fields
    if (!formDetails.name.trim()) errors.name = 'Organization/Society Name is required';
    if (!formDetails.register_number.trim()) errors.register_number = 'Reg. Number is required';
    if (!formDetails.phone.trim()) {
      errors.phone = 'Phone Number is required';
    } else if (!phoneRegex.test(formDetails.phone)) {
      errors.phone = 'Phone Number must be a 10-digit number';
    }
    if (!formDetails.email.trim()) {
      errors.email = 'Email is required';
    } else if (!emailRegex.test(formDetails.email)) {
      errors.email = 'Invalid email format';
    }
    if (formDetails.website && formDetails.website.trim() && !urlRegex.test(formDetails.website.trim())) {
      errors.website = 'Invalid website URL format';
    }
    if (!formDetails.district) errors.district = 'District is required';
    if (!formDetails.taluk) errors.taluk = 'Taluk is required';
    if (!formDetails.village) errors.village = 'Village is required';
    if (!formDetails.address.trim()) errors.address = 'Address is required';
    if (!formDetails.contact_person.trim()) errors.contact_person = 'Name of the Concerned Person is required';
    if (!formDetails.second_phone.trim()) {
      errors.second_phone = 'Alternative Phone Number is required';
    } else if (!phoneRegex.test(formDetails.second_phone)) {
      errors.second_phone = 'Alternative Phone Number must be a 10-digit number';
    }else if(formDetails.phone === formDetails.second_phone){
      errors.second_phone = 'Alternative phone number shuld be different';
    }
    if (!formDetails.password) errors.password = 'Password is required';
    if (!formDetails.reenter_password) errors.reenter_password = 'Re-enter Password is required';
    if (formDetails.password !== formDetails.reenter_password) errors.reenter_password = 'Passwords do not match';
    
     const captchaValue = recaptcha.current.getValue()
    if (!captchaValue) {
      errors.captcha = 'Please verify the reCAPTCHA!';
    }else{
      setFormDetails({...formDetails,captcha:recaptcha.current.getValue()});
    }
    setFormErrors(errors);
  
    return Object.keys(errors).length === 0;
  };
  

  // Redirect if the token exists
  useEffect(() => {
    if (authToken) {
      navigate("/dashboard");
    }
  }, [authToken, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // createSociety();
    if (validateForm()) {
      // Proceed with form submission if validation passes
     
      validateSociety();
    }
  };

  const validateSociety = () => {
    setLoading(true);
    fetch(`${apiUrl}auth/validate-signup`, {
      method: "POST",
      body: JSON.stringify(formDetails),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((jsonResponse) => {
        if (jsonResponse.status) {
          setError('');
          setSuccess('');
          setShow(true);
        } else {
          setSuccess('');
          setError(jsonResponse.message);
        }
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setLoading(false);
        recaptcha.current.reset();
      });
  };

  const createSociety = () => {
    setLoading(true);
    fetch(`${apiUrl}auth/society-signup`, {
      method: "POST",
      body: JSON.stringify(formDetails),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((jsonResponse) => {
        if (jsonResponse.status) {
          setError('');
          setSuccess(jsonResponse.message);
          setFormDetails({
            name: '',
            register_number: '',
            phone: '',
            email: '',
            website: '',
            district: '',
            taluk: '',
            village: '',
            address: '',
            contact_person: '',
            second_phone: '',
            password: '',
            reenter_password: '',
          });
        } else {
          setSuccess('');
          setError(jsonResponse.message);
        }
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        setLoading(false);
        
      });
  };

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await fetch(`${apiUrl}district`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setDistricts(data.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchDistricts();
  }, [apiUrl]);

  useEffect(() => {
    if (!district) return;
    const fetchTaluk = async () => {
      try {
        const response = await fetch(`${apiUrl}taluk/${district}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setTaluks(data.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchTaluk();
  }, [district, apiUrl]);

  useEffect(() => {
    if (!taluk) return;
    const fetchVillage = async () => {
      try {
        const response = await fetch(`${apiUrl}village/${taluk}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setVillages(data.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchVillage();
  }, [taluk, apiUrl]);


  const handleSubmitOtp = () => {
    // Basic validation: Ensure all OTP fields are filled
    const newOtpErrors = otp.map((value) =>
      value.trim() === "" ? "This field is required" : ""
    );
    setOtpErrors(newOtpErrors);
    const otp_value = parseInt(otp.join(""));
    if (newOtpErrors.every((error) => error === "")) {
      try {
        fetch(`${apiUrl}check_otp`, {
          method: "POST",
          body: JSON.stringify({ phoneNumber: formDetails.phone, otp: otp_value }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((jsonResponse) => {
            if (jsonResponse.status) {
              createSociety();
              setOtp(["", "", "", "", ""]);
              handleModalClose();
              return true;
            } else {
              setOtpStatus(jsonResponse.data);
              return false;
            }
          });
      } catch (error) {
        handleModalClose(); // Close the modal on successful submission
        console.error("Error validating phone number:", error);
        // setPhoneError("An error occurred. Please try again.");
      }

      // Proceed with OTP submission
      // console.log("OTP Submitted:", otp);
    }
  };

  const handleChangeDistrict = (event) => {
    setSelectedDistricts(event.target.value);
    setSelectedVillages("");
    setSelectedTaluks("");
  };

  const handleChangeTaluk = (event) => {
    setSelectedTaluks(event.target.value);
  };

  const handleChangeVillage = (event) => {
    setSelectedVillages(event.target.value);
    setSelectedTaluks("");
  };

  return (
<>

      <Modal
        show={show}
        onHide={handleModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Enter OTP</Modal.Title>
          <span aria-hidden="true" onClick={handleModalClose}>
            <i className="bi bi-x-lg"></i>Close
          </span>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
        </Modal.Header>
        <Modal.Body>
          {otpStatus ? (
            <div
            className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
             
              {otpStatus}
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  onClick={(e) => {
                    setOtpStatus(null);
                  }}
                >
                  &times;
                </span>
              </button>
            </div>
          ) : null}
          <div className="row m-2">
            <div className="form-group mb-3 mb-md-2 enter_otp px-3">
              <div className="row">
                <ul className="otp_ul list-unstyled d-flex justify-content-between">
                {otp.map((value, index) => (
                    <li key={index} className="otp_list_item">
                      <input
                        type="text"
                        className={`form-control input_style ${
                          otpErrors[index] ? "is-invalid" : ""
                        }`}
                        value={value}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        onKeyUp={(e) => handleKeyUp(e, index)}
                        maxLength={1}
                        ref={(el) => (inputRefs.current[index] = el)} // Store reference to each input
                      />
                      {/* {otpErrors[index] && (
                        <div className="invalid-feedback">
                          {otpErrors[index]}
                        </div>
                      )} */}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmitOtp}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>



    <section className="pt-2 pb-2 pt-md-5 pb-md-4">
      <form method="post" onSubmit={handleSubmit}>
        <div className="form_main_560">
          <div className="text-center pb-2 pb-md-4">
            <h2>Register</h2>
          </div>
          { error &&
        <div className="alert alert-danger" role="alert">
            {error}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" >
              <span aria-hidden="true" onClick={handleClose}>&times;</span>
            </button>
        </div>
       }
       {  success &&
        <div className="alert alert-success" role="alert">
         {success}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" >
              <span aria-hidden="true" onClick={handleClose2}>&times;</span>
            </button>
        </div>
       }
          <ul className="form_ul">
            <li>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3 mb-md-2">
                    <label htmlFor="socity_name">
                      Organization/Society Name
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className={`form-control input_style ${formErrors.name ? 'is-invalid' : ''}`}
                        id="socity_name"
                        placeholder="Enter Organization/Society Name"
                        autoComplete="off"
                        value={formDetails.name || ""}
                        onChange={(e) =>
                          setFormDetails({
                            ...formDetails,
                            name: e.target.value,
                          })
                        }
                      />
                      {formErrors.name && (
                          <div className="invalid-feedback">{formErrors.name}</div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3 mb-md-2">
                    <label htmlFor="reg_number">Reg. Number</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className={`form-control input_style ${formErrors.register_number ? 'is-invalid' : ''}`}
                        id="reg_number"
                        placeholder="Enter Reg. Number"
                        autoComplete="off"
                        value={formDetails.register_number || ""}
                        onChange={(e) =>
                          setFormDetails({
                            ...formDetails,
                            register_number: e.target.value,
                          })
                        }
                      />
                       {formErrors.register_number && (
                          <div className="invalid-feedback">{formErrors.register_number}</div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            {/* </li>

            <li> */}
              <div className="row">
                <div className="col-md-6">
                  <div className="d-flex justify-content-between">
                    <label htmlFor="inlineFormInputGroup">Phone Number</label>
                  </div>

                  <div className="input-group mb-3 mb-md-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">+91</div>
                    </div>
                    <input
                      type="text"
                      className={`form-control input_style ${formErrors.phone ? 'is-invalid' : ''}`}
                      placeholder="Enter Phone Number"
                      autoComplete="off"
                      value={formDetails.phone || ""}
                      
                      maxLength={10}
                      onChange={(e) =>
                        setFormDetails({
                          ...formDetails,
                          phone: e.target.value,
                        })
                      }
                    />
                     {formErrors.phone && (
                          <div className="invalid-feedback">{formErrors.phone}</div>
                        )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3 mb-md-2">
                    <label htmlFor="inputPassword4">Email</label>
                    <div className="position-relative">
                      <input
                        type="email"
                        className={`form-control input_style ${formErrors.email ? 'is-invalid' : ''}`}
                        id="inputPassword4"
                        placeholder="Enter Email ID"
                        autoComplete="off"
                        value={formDetails.email || ""}
                        onChange={(e) =>
                          setFormDetails({
                            ...formDetails,
                            email: e.target.value,
                          })
                        }
                      />
                       {formErrors.email && (
                          <div className="invalid-feedback">{formErrors.email}</div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            {/* </li>

            <li> */}
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="inlineFormInputGroup">District</label>
                  <div className="input-group mb-3 mb-md-2">
                    <select
                      className={`form-control input_style ${formErrors.district ? 'is-invalid' : ''}`}
                      value={formDetails.district || ""}
                      onChange={(e) =>{ setFormDetails({
                        ...formDetails,
                        district: e.target.value,
                      })
                      handleChangeDistrict(e)}
                       
                      }
                    >
                      <option value="">Select District</option>
                       {districtdata.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.district}
                          </option>
                        ))}
                    </select>
                    {formErrors.district && (
                          <div className="invalid-feedback">{formErrors.district}</div>
                        )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3 mb-md-2">
                    <label htmlFor="inputPassword4">Taluk</label>
                    <div className="position-relative">
                      <select
                        className={`form-control input_style ${formErrors.taluk ? 'is-invalid' : ''}`}
                        value={formDetails.taluk || ""}
                        onChange={(e) =>{  setFormDetails({
                          ...formDetails,
                          taluk: e.target.value,
                        })
                        handleChangeTaluk(e);
                      }
                        
                        }
                      >
                       <option value="">Select Taluk</option>
                          {talukdata.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.taluk}
                            </option>
                          ))}
                      </select>
                      {formErrors.taluk && (
                          <div className="invalid-feedback">{formErrors.taluk}</div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            {/* </li>

            <li> */}
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="inlineFormInputGroup">Village</label>
                  <div className="input-group mb-3 mb-md-2">
                    <select
                       className={`form-control input_style ${formErrors.village ? 'is-invalid' : ''}`}
                        value={formDetails.village || ""}
                      onChange={(e) =>{ setFormDetails({
                        ...formDetails,
                        village: e.target.value,
                      })
                      handleChangeVillage(e);
                    }
                       
                      }
                    >
                      <option value="">Select Village</option>
                        {villagedata.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.village}
                          </option>
                        ))}
                    </select>
                    {formErrors.village && (
                          <div className="invalid-feedback">{formErrors.village}</div>
                        )}
                  </div>
                </div>
                <div className="col-md-6">

                <div className="form-group mb-3 mb-md-2">
                    <label htmlFor="inputPassword42">Website URL</label>
                    <div className="position-relative">
                      
                      <input
                        type="text"
                        className={`form-control input_style ${formErrors.website ? 'is-invalid' : ''}`}
                        id="inputPassword42"
                        placeholder="Enter Website URL"
                        autoComplete="off"
                        value={formDetails.website || ""}
                        onChange={(e) =>
                          setFormDetails({
                            ...formDetails,
                            website: e.target.value,
                          })
                        }
                      />
                       {formErrors.website && (
                          <div className="invalid-feedback">{formErrors.website}</div>
                        )}
                    </div>
                  </div>

                  
                </div>
              </div>
            {/* </li>
            <li> */}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3 mb-md-2">
                    <label htmlFor="inputPassword466">
                      Name of the Concerned Person
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className={`form-control input_style ${formErrors.contact_person ? 'is-invalid' : ''}`}
                        id="inputPassword466"
                        placeholder="Concerned Person"
                        autoComplete="off"
                        
                        value={formDetails.contact_person || ""}
                        onChange={(e) =>
                          setFormDetails({
                            ...formDetails,
                            contact_person: e.target.value,
                          })
                        }
                      />
                       {formErrors.contact_person && (
                          <div className="invalid-feedback">{formErrors.contact_person}</div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="inlineFormInputGroup">
                    Alternative Phone Number
                  </label>
                  <div className="input-group mb-3 mb-md-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">+91</div>
                    </div>
                    <input
                      type="text"
                      className={`form-control input_style ${formErrors.second_phone ? 'is-invalid' : ''}`}
                      placeholder="Enter Phone Number"
                      autoComplete="off"
                      value={formDetails.second_phone || ""}
                      maxLength={10}
                      onChange={(e) =>
                        setFormDetails({
                          ...formDetails,
                          second_phone: e.target.value,
                        })
                      }
                    />
                     {formErrors.second_phone && (
                          <div className="invalid-feedback">{formErrors.second_phone}</div>
                        )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                <div className="form-group mb-3 mb-md-2">
                    <label htmlFor="inputPassword411">Address</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className={`form-control input_style ${formErrors.address ? 'is-invalid' : ''}`}
                        id="inputPassword411"
                        placeholder="Address"
                        autoComplete="off"
                        value={formDetails.address || ""}
                        onChange={(e) =>
                          setFormDetails({
                            ...formDetails,
                            address: e.target.value,
                          })
                        }
                      />
                       {formErrors.address && (
                          <div className="invalid-feedback">{formErrors.address}</div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3 mb-md-2">
                    <label htmlFor="inputPassword42">Set Password</label>
                    <div className="position-relative">
                      {/* <a className="show_pass" href="#">
                        <img src="./assets/images/show_pass.svg" alt="" />
                      </a> */}
                      <input
                        type="password"
                        className={`form-control input_style ${formErrors.password ? 'is-invalid' : ''}`}
                        id="inputPassword42"
                        placeholder="Enter Password"
                        autoComplete="off"
                        value={formDetails.password || ""}
                        onChange={(e) =>
                          setFormDetails({
                            ...formDetails,
                            password: e.target.value,
                          })
                        }
                      />
                       {formErrors.password && (
                          <div className="invalid-feedback">{formErrors.password}</div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3 mb-md-2">
                    <label htmlFor="inputPassword47">Re Enter Password</label>
                    <div className="position-relative">
                      {/* <a className="show_pass" href="#">
                        <img src="./assets/images/show_pass.svg" alt="" />
                      </a> */}
                      <input
                        type="password"
                        className={`form-control input_style ${formErrors.reenter_password ? 'is-invalid' : ''}`}
                        id="inputPassword47"
                        placeholder="Enter Password"
                        value={formDetails.reenter_password || ""}
                         onChange={(e) =>
                          setFormDetails({
                            ...formDetails,
                            reenter_password: e.target.value,
                          })
                        }
                      />
                       {formErrors.reenter_password && (
                          <div className="invalid-feedback">{formErrors.reenter_password}</div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="row">
              <ReCAPTCHA
                  sitekey={capthaSiteKey}
                  ref={recaptcha}
                  // onChange={(token) =>
                  //   setFormDetails({
                  //     ...formDetails,
                  //     captcha: token, // Use the token directly
                  //   })
                  // }
                />
                  {formErrors.captcha && (
                          <div className="invalid-feedback" style={{display:'block',paddingLeft:12}}>{formErrors.captcha}</div>
                        )}
              </div>
             
              <br/>
             
             
            </li>

            <li>
             
            </li>
            <li className="mb-0 mb-md-2">
              <div className="form-group  ">

              {
                                   loading ? <button type="button" className="btn btn-primary w-100"> <ThreeDots
                                   visible={true}
                                   height="40"
                                   width="40"
                                   color="#fff"
                                   radius="1"
                                   ariaLabel="three-dots-loading"
                                   wrapperStyle={{display: 'block'}}
                                   wrapperClass=""
                                   />  </button>
                                          :  <button type="submit" className="btn btn-primary w-100">
                                          SUBMIT
                                        </button>
               }

                
              </div>
            </li>

            <li className="text-center bottom_txt">
              <p>
                Already have an account? <a href="/login">Login</a>
              </p>
            </li>
          </ul>
        </div>
      </form>
    </section>
    </>
  );
};

export default Register;
