import React, { useState,useCallback ,useEffect} from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import  { toast } from 'react-hot-toast';

import { useDropzone } from "react-dropzone";
import def_data from './../../data.json';
const ProductModal = ({ show, handleClose, data ,fetchData}) => {
  const [formDetails, setFormDetails] = useState({
    product_name: '',
    weft: '',
    warp: '',
    warp_material: '',
    weft_material: '',
    epi: '',
    length: '',
    ppi: '',
    width: '',
    weaver: '',
    structure: '',
    weaver_position: '',
    agree: false,
    name: '',
    new_value: '',
    society_id: '',
    // image: null,
    // video: null,
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formDetails.product_name) newErrors.product_name = "Product name is required";
    if (!formDetails.weft) newErrors.weft = "Count of WEFT is required";
    if (!formDetails.warp) newErrors.warp = "Count of WARP is required";
    if (!formDetails.warp_material) newErrors.warp_material = "WARP Material is required";
    if (!formDetails.weft_material) newErrors.weft_material = "WEFT Material is required";
    if (!formDetails.epi) newErrors.epi = "EPI is required";
    else if (isNaN(formDetails.epi) || formDetails.epi <= 0) newErrors.epi = "EPI must be a number";
    else if (formDetails.epi > 120 || formDetails.epi < 10) newErrors.epi = "EPI must be between 10 and 120.";
    if (!formDetails.length) newErrors.length = "Length is required";
    else if (isNaN(formDetails.length) || formDetails.length <= 0) newErrors.length = "Length must be a number";
    // else if (formDetails.length > 110 || formDetails.length < 5) newErrors.length = "Length must be between 5 and 110.";
    if (!formDetails.ppi) newErrors.ppi = "PPI is required";
    else if (isNaN(formDetails.ppi) || formDetails.ppi <= 0) newErrors.ppi = "PPI must be a number";
    else if (formDetails.ppi > 120 || formDetails.ppi < 10) newErrors.ppi = "PPI must be between 10 and 120.";
    if (!formDetails.width) newErrors.width = "Width is required";
    else if (isNaN(formDetails.width) || formDetails.width <= 0) newErrors.width = "Width must be a positive number";
    // else if (formDetails.width > 110 || formDetails.width < 5) newErrors.width = "Width must be between 5 and 110.";
    if (!formDetails.weaver) newErrors.weaver = "Weaver name is required";
    if (!formDetails.structure) newErrors.structure = "Structure of weaving is required";
    if (!formDetails.weaver_position) newErrors.weaver_position = "Weaver position is required";
    if (!formDetails.agree) newErrors.agree = "You must agree to the terms";
    if (!formDetails.name) newErrors.name = "Name is required";
    if (!formDetails.society_id) newErrors.society_id = "Society is required";
    if (formDetails.product_name === "Other" && !formDetails.new_value) newErrors.new_value = "Value is required when 'Other' is selected";
    // if (!formDetails.image) newErrors.image = "Image upload is required";
    // if (!formDetails.video) newErrors.video = "Video upload is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const authToken = localStorage.getItem("admin-token");
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    setFormDetails({
      ...formDetails,
      [name]: type === "file" ? files[0] : (type === "checkbox" ? checked : value),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
    

    
        const formData = new FormData();
        Object.keys(formDetails).forEach((key) => {
          if (key === "image") {
            Array.from(formDetails[key]).forEach((file) => {
              formData.append(key, file);
            });
          } else {
            formData.append(key, formDetails[key]);
          }
        });
    
    
        setLoading(true);
        fetch(`${apiUrl}admin/product_add`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `jwt ${authToken}`,
          },
        })
          .then((res) => res.json())
          .then((jsonResponse) => {
            setLoading(false);
            if (jsonResponse.status) {
              setError("");
              setSuccess(jsonResponse.message);
              setFormDetails({});
              setImageFiles([]);
              setVideoFile(null);
              toast.success('Product added successfully!');
              fetchData();
              handleClose();
            } else {
              setSuccess("");
              setError(jsonResponse.message);
              toast.error('Failed to add product!');
              if (jsonResponse.message === "Unauthorised User") {
                // redirect("/login");
              }
            }
            // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          });


      // Close the modal
    }
  };


// For file upload

const [imagefiles, setImageFiles] = useState([]);
const [imageErrorMessage, setImageErrorMessage] = useState("");
const handleRemoveImageFile = (fileId) => {
  setImageFiles((prevFiles) =>
    prevFiles.filter((file) => file.id !== fileId)
  );
};

const onDrop = useCallback(
  (acceptedFiles) => {
    const filteredFiles = acceptedFiles.filter((file) => {
      if (file.size > 2048576) {
        // 1 MB in bytes
        setImageErrorMessage(
          `${file.name} is too large. Maximum file size is 2 MB.`
        );
        return false; // Exclude the file
      }

      // Check if the file is already in the state
      const isFileAlreadyAdded = imagefiles.some(
        (existingFile) =>
          existingFile.name === file.name && existingFile.size === file.size
      );

      if (isFileAlreadyAdded) {
        setImageErrorMessage(`${file.name} is already uploaded.`);
        return false; // Exclude the file
      }

      return true;
    });

    // Check if adding these files will exceed the limit of 5 files
    if (imagefiles.length + filteredFiles.length > 5) {
      setImageErrorMessage("You can only upload a maximum of 5 images.");
      return;
    }

    // Reset the error message
    setImageErrorMessage("");

    // Process and preview the new files
    const newFiles = filteredFiles.map((file) =>
      Object.assign(file, {
        id: `${file.name}-${file.lastModified}`, // Create a unique identifier for each file
        preview: URL.createObjectURL(file), // Create a preview URL for each file
      })
    );

    setImageFiles((prevFiles) => {
      const updatedFiles = [...prevFiles, ...newFiles];
      setFormDetails((prevDetails) => ({ ...prevDetails, image: updatedFiles })); // Update form details
      return updatedFiles;
    });
  },
  [imagefiles]
);

const { getRootProps, getInputProps, isDragActive } = useDropzone({
  onDrop,
  accept: {
    "image/*": [],
  },
  multiple: true,
  // maxFiles:5,
});

const [videofile, setVideoFile] = useState(null);
const [videoErrorMessage, setVideoErrorMessage] = useState("");

const handleRemoveVideo = () => {
  setVideoFile(null);
};

const onDropVideo = useCallback(
  (acceptedFiles) => {
    // console.log(formDetails);
    const selectedVideo = acceptedFiles[0];

    // Check if a video file is selected
    if (!selectedVideo) return;

    // Check for file size and type
    if (selectedVideo.size > 10485760) {
      // 10 MB in bytes
      setVideoErrorMessage("Video file size must be less than 10 MB.");
      return;
    }

    // Reset the error message
    setVideoErrorMessage("");

    // Create a preview URL for the selected video file
    const newVideoFile = Object.assign(selectedVideo, {
      preview: URL.createObjectURL(selectedVideo),
    });

    // Update state with the new video file
    setVideoFile(newVideoFile);
    setFormDetails((prevDetails) => ({ ...prevDetails, video: newVideoFile }));
    // console.log(formDetails);
  },
  [videofile]
);

const {
  getRootProps: getVideoRootProps,
  getInputProps: getVideoInputProps,
  isDragActive: isVideoDragActive,
} = useDropzone({
  onDrop: onDropVideo, // Correctly reference the onDrop function
  accept: {
    "video/*": [], // Accept all video types
  },
  multiple: false, // Only allow one video file at a time
});


const [societies, setSocieties] = useState([]);

useEffect(() => {
  const fetchSocieties = async () => {
   
    try {
      const response = await fetch(`${apiUrl}get_society`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const respo = await response.json();
      setSocieties(respo.data)
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  fetchSocieties();
}, []);
//end file upload

  return (
    <Modal show={show} onHide={handleClose} size='xl'>
      <Modal.Header >
        <Modal.Title>Add New Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
            <Form.Group controlId="society_id" className='form-group mb-2 mb-md-2'>
                <Form.Label>Society</Form.Label>
                <Form.Control
                  as="select"
                  name="society_id"
                  value={formDetails.society_id}
                  onChange={handleChange}
                  tabIndex={1}
                  isInvalid={!!errors.society_id}
                >
                  <option value="">Select Society</option>
                  {/* { def_data.weft_material.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                    
                  ))} */}

                  {societies.map((society) => (
                    <option key={society.id} value={society.id}>
                      {society.name}
                    </option>))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.society_id}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="productName" className='form-group mb-2 mb-md-2'>
                <Form.Label>Name of Product</Form.Label>
                <Form.Control
                  as="select"
                  name="product_name"
                  value={formDetails.product_name}
                  onChange={handleChange}
                  tabIndex={3}
                  isInvalid={!!errors.product_name}
                >
                  <option value="">Select Product</option>
                  { def_data.product_name.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.product_name}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="weft" className='form-group mb-2 mb-md-2'>
                <Form.Label>Count of WEFT</Form.Label>
                <Form.Control
                  as="select"
                  name="weft"
                  tabIndex={5}
                  value={formDetails.weft}
                  onChange={handleChange}
                  isInvalid={!!errors.weft}
                >
                  <option value="">Count of WEFT</option>
                  { def_data.weft.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.weft}</Form.Control.Feedback>
              </Form.Group>

              

              <Form.Group controlId="warpMaterial" className='form-group mb-2 mb-md-2'>
                <Form.Label>Type of Material of WARP</Form.Label>
                <Form.Control
                  as="select"
                  name="warp_material"
                  value={formDetails.warp_material}
                  onChange={handleChange}
                  tabIndex={7}
                  isInvalid={!!errors.warp_material}
                >
                  <option value="">Type of Material of WARP</option>
                  { def_data.warp_material.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.warp_material}</Form.Control.Feedback>
              </Form.Group>

             
              <Form.Group controlId="epi" className='form-group mb-2 mb-md-2'>
                <Form.Label>EPI <small>(10 to 120)</small></Form.Label>
                <Form.Control
                  type="text"
                  name="epi"
                  placeholder='EPI'
                  value={formDetails.epi}
                  tabIndex={9}
                  onChange={handleChange}
                  isInvalid={!!errors.epi}
                />
                <Form.Control.Feedback type="invalid">{errors.epi}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="length" className='form-group mb-2 mb-md-2'>
                <Form.Label>Length <small>(in cm)</small></Form.Label>
                <Form.Control
                  type="text"
                  name="length"
                  placeholder='Length'
                  value={formDetails.length}
                  tabIndex={11}
                  onChange={handleChange}
                  isInvalid={!!errors.length}
                />
                <Form.Control.Feedback type="invalid">{errors.length}</Form.Control.Feedback>
              </Form.Group>
          
            <Form.Group controlId="structure" className='form-group mb-2 mb-md-2'>
                <Form.Label>Structure of Weaving</Form.Label>
                <Form.Control
                  as="select"
                  name="structure"
                  value={formDetails.structure}
                  onChange={handleChange}
                  tabIndex={13}
                  isInvalid={!!errors.structure}
                >
                  <option value="">Select Structure</option>
                  { def_data.structure.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.structure}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="product_varient" className='form-group mb-2 mb-md-2'>
                <Form.Label>Product Varient <small>(Colors,Size,etc)</small></Form.Label>
                <Form.Control
                  type="text"
                  name="product_varient"
                  placeholder='Product Varient'
                  value={formDetails.product_varient}
                  tabIndex={15}
                  onChange={handleChange}
                  isInvalid={!!errors.product_varient}
                />
                <Form.Control.Feedback type="invalid">{errors.product_varient}</Form.Control.Feedback>
              </Form.Group>

             

            </Col>
            <Col md={6}>
            <Form.Group controlId="weaver" className='form-group mb-2 mb-md-2'>
                <Form.Label>Weaver Name</Form.Label>
                <Form.Control
                  type="text"
                  name="weaver"
                  placeholder='Weaver Name'
                  value={formDetails.weaver}
                  tabIndex={2}
                  onChange={handleChange}
                  isInvalid={!!errors.weaver}
                />
                <Form.Control.Feedback type="invalid">{errors.weaver}</Form.Control.Feedback>
              </Form.Group>

            <Form.Group controlId="newValue" className='form-group mb-2 mb-md-2'>
                <Form.Label>Enter Product Name <small>(If the product is not in the list)</small></Form.Label>
                <Form.Control
                  type="text"
                  name="new_value"
                  value={formDetails.product_name == "Other"?formDetails.new_value:''}
                  placeholder='Enter Product Name'
                  onChange={handleChange}
                  tabIndex={4}
                  isInvalid={!!errors.new_value}
                  disabled={formDetails.product_name !== "Other"}
                />
                <Form.Control.Feedback type="invalid">{errors.new_value}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="warp" className='form-group mb-2 mb-md-2'>
                <Form.Label>Count of WARP</Form.Label>
                <Form.Control
                  as="select"
                  name="warp"
                  value={formDetails.warp}
                  onChange={handleChange}
                  tabIndex={6}
                  isInvalid={!!errors.warp}
                >
                  <option value="">Count of WARP</option>
                  { def_data.warp.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.warp}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="weftMaterial" className='form-group mb-2 mb-md-2'>
                <Form.Label>Type of Material of WEFT</Form.Label>
                <Form.Control
                  as="select"
                  name="weft_material"
                  value={formDetails.weft_material}
                  onChange={handleChange}
                  tabIndex={8}
                  isInvalid={!!errors.weft_material}
                >
                  <option value="">Type of Material of WEFT</option>
                  { def_data.weft_material.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.weft_material}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="ppi" className='form-group mb-2 mb-md-2'>
                <Form.Label>PPI <small>(10 to 120)</small></Form.Label>
                <Form.Control
                  type="text"
                  name="ppi"
                  value={formDetails.ppi}
                  tabIndex={10}
                  placeholder='PPI'
                  onChange={handleChange}
                  isInvalid={!!errors.ppi}
                />
                <Form.Control.Feedback type="invalid">{errors.ppi}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="width" className='form-group mb-2 mb-md-2'>
                <Form.Label>Width <small>(in cm)</small></Form.Label>
                <Form.Control
                  type="text"
                  name="width"
                  placeholder='Width'
                  value={formDetails.width}
                  tabIndex={12}
                  onChange={handleChange}
                  isInvalid={!!errors.width}
                />
                <Form.Control.Feedback type="invalid">{errors.width}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="weaverPosition" className='form-group mb-2 mb-md-2'>
                <Form.Label>Weaver Position</Form.Label>
                <Form.Control
                  type="text"
                  name="weaver_position"
                  placeholder='Weaver Position'
                  value={formDetails.weaver_position}
                  tabIndex={14}
                  onChange={handleChange}
                  isInvalid={!!errors.weaver_position}
                />
                <Form.Control.Feedback type="invalid">{errors.weaver_position}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="name" className='form-group mb-2 mb-md-2'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder='Name'
                  value={formDetails.name}
                  tabIndex={16}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>

              </Col>
              <Col  md={12}>
              <div className='file-upload-wrapper p-4 position-relative form-group mb-2 mb-md-2'>
                  {/* Video Upload Dropzone */}
                  <div
                    {...getVideoRootProps()}
                    className={`dropzone d-flex flex-column align-items-center justify-content-center ${
                      isVideoDragActive ? "active" : ""
                    }`}
                  >
                    <div>
                      <img src="./assets/images/upload.png" alt="" />
                    </div>
                    <input {...getVideoInputProps()} name="video" 
                  tabIndex={17}/>
                    {isVideoDragActive ? (
                      <p className="upload-text mt-3">Drop the video here...</p>
                    ) : (
                      <p className="upload-text mt-3">
                        Drag 'n' drop a video here, or click to select a video
                        (Max size 10 MB)
                      </p>
                    )}
                  </div>

                  {/* Error message display for video */}

                  {/* Video Preview Area */}
                  {videofile && (
                    <div style={{ marginTop: "20px", position: "relative" }}>
                      <video
                        src={videofile.preview}
                        controls
                        style={{ width: "300px", borderRadius: "8px" }}
                      ></video>
                      <button
                        onClick={handleRemoveVideo}
                        style={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          backgroundColor: "#ff4d4f",
                          border: "none",
                          color: "#fff",
                          borderRadius: "50%",
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        &times;
                      </button>
                    </div>
                  )}
                </div>
                {videoErrorMessage && (
                  <p style={{ color: "red", marginTop: "10px" }}>
                    {videoErrorMessage}
                  </p>
                )}

              <div className='file-upload-wrapper p-4 position-relative form-group mb-2 mb-md-2'>
                  {/* Dropzone area */}
                  <div
                    {...getRootProps()}
                    className={`dropzone d-flex flex-column align-items-center justify-content-center ${
                      isDragActive ? "active" : ""
                    }`}
                  >
                    <div>
                      <img src="./assets/images/upload.png" alt="" />
                    </div>
                    <input {...getInputProps()} name="image"  tabIndex={18} />
                    {isDragActive ? (
                      <p className="upload-text mt-3">Drop the files here...</p>
                    ) : (
                      <p className="upload-text mt-3">
                        Drag 'n' drop product images here, or click to select
                        files (Max 5 Images)
                      </p>
                    )}
                  </div>

                  <br />
                  {/* Preview area */}
                  <div className="previews" style={{ marginTop: "20px" }}>
                    {imagefiles.map((file) => (
                      <div
                        key={file.id} // Use the unique identifier for the key
                        style={{
                          display: "inline-block",
                          marginRight: "10px",
                          position: "relative", // Position relative for the remove button
                        }}
                      >
                        <div>
                          <img
                            src={file.preview}
                            alt={file.name}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                        </div>
                        <div>
                          <button
                            onClick={() => handleRemoveImageFile(file.id)} // Use the unique identifier to remove
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              backgroundColor: "#ff4d4f",
                              border: "none",
                              color: "#fff",
                              borderRadius: "50%",
                              width: "25px",
                              height: "25px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            &times;
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {imageErrorMessage && (
                  <p style={{ color: "red", marginTop: "10px" }}>
                    {imageErrorMessage}
                  </p>
                )}

              <Form.Group controlId="agree" className='form-group mb-2 mb-md-2'>
                <Form.Check
                  type="checkbox"
                  name="agree"
                  label="I agree to the terms"
                  checked={formDetails.agree}
                  tabIndex={19}
                  onChange={handleChange}
                  isInvalid={!!errors.agree}
                />
                <Form.Control.Feedback type="invalid">{errors.agree}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit" className='form-group mb-2 mb-md-2' tabIndex={20}>Submit</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ProductModal;
