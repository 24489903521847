import React, { useState, useEffect } from "react";
import { Tabs, Tab, Button, Form, Col, Row, Alert } from "react-bootstrap";
import  { toast } from 'react-hot-toast'; // For success/error messages

const ProfileEditPage = () => {
  const [userData, setUserData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const authToken = localStorage.getItem("admin-token");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  let user = localStorage.getItem('adminDetails');
  user = JSON.parse(user);
  const userId = user.id;

  // Fetch user data from API
  const fetchUserData = async () => {
    try {
      const response = await fetch(`${apiUrl}admin/user/${userId}`, {
        method: 'GET',
        headers: {
          Authorization: `jwt ${authToken}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setUserData(data.data[0]);
    } catch (error) {
      setApiError("Error fetching user data.");
      setShowAlert(true);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  // Profile form validation
  const validateProfileForm = () => {
    const errors = {};
    const usernameRegex = /^[a-zA-Z0-9]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;

    // Validate username
    if (!userData.username.trim()) {
      errors.username = "Username is required.";
    } else if (!usernameRegex.test(userData.username)) {
      errors.username = "Username can only contain letters and numbers.";
    }

    // Validate phone number
    if (!userData.phone.trim()) {
      errors.phone = "Phone number is required.";
    } else if (!phoneRegex.test(userData.phone)) {
      errors.phone = "Phone number must be a 10-digit number.";
    }

    // Validate email
    if (!userData.email.trim()) {
      errors.email = "Email is required.";
    } else if (!emailRegex.test(userData.email)) {
      errors.email = "Invalid email format.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Password form validation
  const validatePasswordForm = () => {
    const errors = {};

    if (!passwordData.currentPassword.trim()) {
      errors.currentPassword = "Current password is required.";
    }

    if (!passwordData.newPassword.trim()) {
      errors.newPassword = "New password is required.";
    }

    if (!passwordData.confirmPassword.trim()) {
      errors.confirmPassword = "Confirm password is required.";
    } else if (passwordData.newPassword !== passwordData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle profile update
  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    if (!validateProfileForm()) return;

    try {
      const response = await fetch(`${apiUrl}admin/edit_user/${userId}`, {
        method: 'PUT',
        headers: {
          Authorization: `jwt ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });
      const result = await response.json();

      if (result.status) {
        toast.success('Profile updated successfully!');
        setEditMode(false);
        fetchUserData();
      } else {
        setApiError(result.message || 'Failed to update profile.');
        setShowAlert(true);
      }
    } catch (error) {
      setApiError('An error occurred while updating the profile.');
      setShowAlert(true);
    }
  };

  // Handle password update
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (!validatePasswordForm()) return;

    try {
      const response = await fetch(`${apiUrl}admin/edit_user_password/${userId}`, {
        method: 'POST',
        headers: {
          Authorization: `jwt ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(passwordData),
      });

      const result = await response.json();

      if (result.status) {
        toast.success('Password updated successfully!');
        setPasswordData({
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
      } else {
        setApiError(result.message || 'Failed to update password.');
        setShowAlert(true);
      }
    } catch (error) {
      setApiError('An error occurred while updating the password.');
      setShowAlert(true);
    }
  };

  // Auto-hide alert after a few seconds
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);
  return (
    <>
      {showAlert && <Alert variant="danger" onClose={() => setShowAlert(false)} >{apiError}</Alert>}
      
      <Tabs defaultActiveKey="profile" id="profile-edit-tabs">
        {/* Profile Tab */}
        <Tab eventKey="profile" title="Profile">
          <Form onSubmit={handleProfileSubmit} className="profile-form">
            <Row className="mt-4 mb-4">
              <Col sm={6}>
                <Form.Group controlId="username">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    value={userData.username || ''}
                    isInvalid={!!errors.username}
                    readOnly={!editMode}
                    onChange={(e) => setUserData({ ...userData, username: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </Form.Group>
              
                <Form.Group controlId="phone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    value={userData.phone || ''}
                    isInvalid={!!errors.phone}
                    readOnly={!editMode}
                    onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={userData.email || ''}
                    isInvalid={!!errors.email}
                    readOnly={!editMode}
                    onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="address">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={1}
                    value={userData.address || ''}
                    isInvalid={!!errors.address}
                    readOnly={!editMode}
                    onChange={(e) => setUserData({ ...userData, address: e.target.value })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" onClick={() => setEditMode(!editMode)} className="edit-button mr-2">
              {editMode ? 'Cancel Edit' : 'Edit Profile'}
            </Button>
            {editMode && (
              <Button variant="success" type="submit" className="save-button">
                Save Changes
              </Button>
            )}
          </Form>
        </Tab>

        {/* Password Tab */}
        <Tab eventKey="password" title="Change Password">
          <Form onSubmit={handlePasswordSubmit} className="password-form">
            <Row className="mt-4 mb-4">
              <Col sm={6}>
                <Form.Group controlId="currentPassword">
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={passwordData.currentPassword}
                    isInvalid={!!errors.currentPassword}
                    onChange={(e) =>
                      setPasswordData({ ...passwordData, currentPassword: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.currentPassword}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="newPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={passwordData.newPassword}
                    isInvalid={!!errors.newPassword}
                    onChange={(e) =>
                      setPasswordData({ ...passwordData, newPassword: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.newPassword}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="confirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={passwordData.confirmPassword}
                    isInvalid={!!errors.confirmPassword}
                    onChange={(e) =>
                      setPasswordData({ ...passwordData, confirmPassword: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Button variant="success" type="submit" className="update-password-button">
              Update Password
            </Button>
          </Form>
        </Tab>
      </Tabs>
    </>
  );
};

export default ProfileEditPage;
