import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import ProductList from "./ProductList";

import DownloadPdf from "./DownloadPdf"; 


const Dashboard = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isEditable, setIsEditable] = useState(true);
  const [data, setSocityData] = useState({});
  const [loading, setloading] = useState(false);
  const [districtdata, setDistricts] = useState([]);
  const [talukdata, setTaluks] = useState([]);
  const [villagedata, setVillages] = useState([]);
  const [district, setSelectedDistricts] = useState("");
  const [taluk, setSelectedTaluks] = useState("");
  const [village, setSelectedVillages] = useState("");
  const redirect = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem("token");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  
 
  const [downloadLogo, setdownloadLogo] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    register_number: "",
    district: "",
    taluk: "",
    village: "",
    contact_person: "",
    second_phone: "",
    email: "",
    website: "",
    address: "",
  });

  const handleClose = () => setError("");
  const handleClose2 = () => setSuccess("");

  const handleDownloadLogo = () => {
    setdownloadLogo(true); // Show loader
    downloadFile("./assets/images/HandloomLogo.png", "HandloomLogo.png");
    downloadFile("./assets/images/HandloomLogos.png", "HandloomLogos.png");
    setTimeout(() => setdownloadLogo(false), 3000); // Hide loader after downloads
  };

  const downloadFile = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (!authToken) {
      redirect("/login");
      return;
    }else{
      setIsAuthenticated(true)
    }

    const fetchData = async () => {
      try {
        let societyDetails = localStorage.getItem("societyDetails");
        societyDetails = JSON.parse(societyDetails);
        const idValue = societyDetails.id;

        const response = await fetch(`${apiUrl}society/get/${idValue}`, {
          method: "GET",
          headers: {
            Authorization: `jwt ${authToken}`,
            "Content-Type": "application/json",
          },
        });


        if (!response.ok) {
          if (response.statusText === "Unauthorized") {
            localStorage.removeItem("token");
            localStorage.removeItem("societyDetails");
           
          } else {
            throw new Error("Network response was not ok");
          }
          redirect("/login");
          return;
        }

        const jsonData = await response.json();
        if(jsonData.status == false){
          redirect("/login");
          return;
        }
        setSocityData({ ...jsonData.data });
        setSelectedDistricts(jsonData.data.district);
        setSelectedTaluks(jsonData.data.taluk);
        setSelectedVillages(jsonData.data.village);
        fetchDistricts();
      } catch (error) {
        console.error("Error fetching data: ", error);
        redirect("/login");
        return;
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs only once

  const fetchDistricts = async () => {
    try {
      const response = await fetch(`${apiUrl}district`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      setDistricts(data.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) return;
    const fetchTaluk = async () => {
      try {
        const response = await fetch(`${apiUrl}taluk/${district}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setTaluks(data.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchTaluk();
  }, [district]);

  useEffect(() => {
    if (!isAuthenticated) return;
    const fetchVillage = async () => {
      try {
        const response = await fetch(`${apiUrl}village/${taluk}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setVillages(data.data); // Assuming data contains an array of options
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchVillage();
  }, [taluk]);

  const handleChangeDistrict = (event) => {
    setSelectedDistricts(event.target.value);
    setSelectedVillages("");
  };

  const handleChangeTaluk = (event) => {
    setSelectedTaluks(event.target.value);
  };

  const handleChangeVillage = (event) => {
    setSelectedVillages(event.target.value);
  };

  const changeEditStatus = () => {
    setIsEditable((prev) => !prev);
    setSocityData({ ...data });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation
    const websiteRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const validationErrors = {
      name: data.name ? "" : "Organization/Society Name is required.",
      phone: data.phone && /^\d{10}$/.test(data.phone) ? "" : "Invalid phone number.",
      email: data.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email) ? "" : "Invalid Email Id.",
      website: data.website?.trim() && !websiteRegex.test(data.website) ? "Invalid Website URL." : "",

      register_number: data.register_number ? "" : "Registration Number is required.",
      district: data.district ? "" : "District is required.",
      address: data.address ? "" : "Address is required.",
      taluk: data.taluk ? "" : "Taluk is required.",
      village: data.village ? "" : "Village is required.",
      contact_person: data.contact_person ? "" : "Name of the Concerned Person is required.",
      second_phone: data.second_phone && /^\d{10}$/.test(data.second_phone) ? "" : "Alternative Phone Number is invalid.",
    };

    setErrors(validationErrors);

    if (Object.values(validationErrors).some(error => error)) {
      setError("Please correct the highlighted errors.");
      return; // Prevent form submission if validation fails
    }

    updateSocity(); // Proceed with the API call if validation passes
  };

  const updateSocity = () => {
    setloading(true);
    fetch(`${apiUrl}society/update`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `jwt ${authToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setloading(false);
        return res.json();
      })
      .then((jsonResponse) => {
        if (jsonResponse.status) {
          setError("");
          setSuccess(jsonResponse.message);
          changeEditStatus();
        } else {
          setSuccess("");
          setError(jsonResponse.message);
        }
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      });
  };




  return (
    <>
      <div className="verified-bar d-flex justify-content-end p-4">
      

        {data.status === 1 ? (
          <>
          <a 
              className="download-logo"
              href="#"
              onClick={(e) => {
                e.preventDefault(); // Prevent default anchor behavior
                handleDownloadLogo();
              }}
        >
          {downloadLogo? (
             <ThreeDots
             visible={true}
             height="20"
             width="40"
             color="#fff"
             radius="1"
             ariaLabel="three-dots-loading"
             wrapperStyle={{ display: 'block' }}
           />
          ) : (
            "Download Logo"
          )}
        </a>
          <DownloadPdf societyName={data.name ? data.name : ""} regNumber={data.society_number ? data.society_number : ""} approvalDate={data.approved_date ? data.approved_date : ""} />
          </>
        ) : null
        }


      </div>
     
      <section className="product-container mb-5">

      {data.status === 2 ? (
               <div className="alert alert-warning alert-dismissible fade show" role="alert">
               <strong>Application is rejected </strong> <br/>{data.reject_reason}
               <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                 <span aria-hidden="true">&times;</span>
               </button>
             </div>
                ) : null}

        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={handleClose}>
                &times;
              </span>
            </button>
          </div>
        )}
        {success && (
          <div className="alert alert-success" role="alert">
            {success}
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={handleClose2}>
                &times;
              </span>
            </button>
          </div>
        )}
        <form method="post" onSubmit={handleSubmit}>
          <div className="">
            <div className="product-detail-contianer">
              <h3>Dashboard</h3>
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="profile-details-wrapper mt-5">
                  {/* <div className="profile_image">
                    <span className="profile">
                      <img src="./assets/images/profile.jpg" alt="" />
                    </span>
                    <span className="profile-edit-btn">
                      <img src="./assets/images/edit_icon.png" alt="" />
                    </span>
                  </div> */}
                  <div className="organization-details">
                    <h6>{data.name ? data.name : ""}</h6>
                    {data.status === 1 && ( <p>Reg. No : {data.society_number ? data.society_number : ""}</p>)}
                  </div>
                </div>
                <p className="approved-edit">
                {data.status === 1 ? (
                  <a href="#!" class="approved">Approved</a>
                ) : (
                  <a onClick={changeEditStatus} href="#!" class="edit">
                    Edit
                  </a>
                )}
                </p>
              </div>
              <ul className="form-wrapper">
                <li>
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="form-group mb-2 mb-md-2">
                        <label htmlFor="inputPassword46">Organization/Society Name</label>
                        <div className="position-relative">
                          <input
                            type="text"
                            className={`form-control input_style ${errors.name ? 'is-invalid' : ''}`}
                            id="inputPassword46"
                            autoComplete="off"
                            placeholder="Enter Organization/Society Name"
                            value={data.name || ""}
                            readOnly={isEditable}
                            tabIndex={1}
                            onChange={(e) =>
                              setSocityData({
                                ...data,
                                name: e.target.value,
                              })
                            }
                          />
                          {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                        </div>
                      </div>
                    </div>
                    </div>
                    
                    <div className="row">
                    <div className="col-md-12 col-12">
                      <label htmlFor="inlineFormInputGroup">Phone Number</label>
                      <div className="input-group mb-2 mb-md-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">+91</div>
                        </div>
                        <input
                          type="text"
                          className={`form-control input_style ${errors.phone ? 'is-invalid' : ''}`}
                          placeholder="Enter Phone Number"
                          autoComplete="off"
                          value={data.phone || ""}
                          // readOnly={isEditable}
                          readOnly
                          tabIndex={3}
                          maxLength={10}
                          onChange={(e) =>
                            setSocityData({
                              ...data,
                              phone: e.target.value,
                            })
                          }
                        />
                        {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="form-group mb-2 mb-md-2">
                        <label htmlFor="inputPassword41">Reg. Number</label>
                        <div className="position-relative">
                          <input
                            type="text"
                            className={`form-control input_style ${errors.register_number ? 'is-invalid' : ''}`}
                            id="inputPassword41"
                            autoComplete="off"
                            placeholder="Enter Reg. Number"
                            value={data.register_number || ""}
                            readOnly={isEditable}
                            tabIndex={2}
                            onChange={(e) =>
                              setSocityData({
                                ...data,
                                register_number: e.target.value,
                              })
                            }
                          />
                          {errors.register_number && <div className="invalid-feedback">{errors.register_number}</div>}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-12">
                      <label htmlFor="inlineFormInputGroup">District</label>
                      <div className="input-group mb-2 mb-md-2">
                        <select
                          className={`form-control custom-select-lg input_style ${errors.district ? 'is-invalid' : ''}`}
                          value={district || ""}
                          disabled={isEditable}
                          tabIndex={4}
                          onChange={(e) => {
                            setSocityData({
                              ...data,
                              district: e.target.value,
                            })
                            handleChangeDistrict(e)
                          }}
                        >
                          <option value="">Select District</option>
                          {districtdata.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.district}
                            </option>
                          ))}
                        </select>
                        {errors.district && <div className="invalid-feedback">{errors.district}</div>}
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="form-group mb-2 mb-md-2">
                        <label htmlFor="inputPassword42">Taluk</label>
                        <div className="position-relative">
                          <select
                            className={`form-control custom-select1 custom-select-lg input_style ${errors.taluk ? 'is-invalid' : ''}`}
                            value={taluk || ""}
                            disabled={isEditable}
                            tabIndex={5}
                            onChange={(e) => {
                              setSocityData(prevDetails => ({
                                  ...data,
                                  taluk:  e.target.value
                              }));
              
                              handleChangeTaluk(e);
                          }}
                          >
                            <option value="">Select Taluk</option>
                            {talukdata.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.taluk}
                              </option>
                            ))}
                          </select>
                          {errors.taluk && <div className="invalid-feedback">{errors.taluk}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <label htmlFor="inlineFormInputGroup">Village</label>
                      <div className="input-group mb-2 mb-md-2">
                        <select
                          className={`form-control custom-select1 custom-select-lg input_style ${errors.village ? 'is-invalid' : ''}`}
                          value={village || ""}
                          disabled={isEditable}
                          tabIndex={6}
                          onChange={(e) => {
                            setSocityData({
                              ...data,
                              village: e.target.value,
                            })
                            handleChangeVillage(e);
                          }}
                        >
                          <option value="">Select Village</option>
                          {villagedata.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.village}
                          </option>
                        ))}
                        </select>
                        {errors.village && <div className="invalid-feedback">{errors.village}</div>}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="form-group mb-2 mb-md-2">
                        <label htmlFor="inputPassword45">Name of the Concerned Person</label>
                        <div className="position-relative">
                          <input
                            type="text"
                            className={`form-control input_style ${errors.contact_person ? 'is-invalid' : ''}`}
                            id="inputPassword45"
                            placeholder="Name of the Concerned Person"
                            autoComplete="off"
                            value={data.contact_person || ""}
                            readOnly={isEditable}
                            tabIndex={7}
                            onChange={(e) =>
                              setSocityData({
                                ...data,
                                contact_person: e.target.value,
                              })
                            }
                          />
                          {errors.contact_person && <div className="invalid-feedback">{errors.contact_person}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <label htmlFor="inlineFormInputGroup">Email</label>
                      <div className="input-group mb-2 mb-md-2">
                        <input
                          type="text"
                          className={`form-control input_style ${errors.email ? 'is-invalid' : ''}`}
                          placeholder="Enter Email"
                          autoComplete="off"
                          value={data.email || ""}
                          readOnly={isEditable}
                          tabIndex={8}
                          onChange={(e) =>
                            setSocityData({
                              ...data,
                              email: e.target.value,
                            })
                          }
                        />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <label htmlFor="inlineFormInputGroup">Alternative Phone Number</label>
                      <div className="input-group mb-2 mb-md-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">+91</div>
                        </div>
                        <input
                          type="text"
                          className={`form-control input_style ${errors.second_phone ? 'is-invalid' : ''}`}
                          placeholder="Enter Alternative Phone Number"
                          autoComplete="off"
                          value={data.second_phone || ""}
                          readOnly={isEditable}
                          tabIndex={8}
                          maxLength={10}
                          onChange={(e) =>
                            setSocityData({
                              ...data,
                              second_phone: e.target.value,
                            })
                          }
                        />
                        {errors.second_phone && <div className="invalid-feedback">{errors.second_phone}</div>}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <label htmlFor="inlineFormInputGroup">Website URL</label>
                      <div className="input-group mb-2 mb-md-2">
                        
                        <input
                          type="text"
                          className={`form-control input_style ${errors.website ? 'is-invalid' : ''}`}
                          placeholder="Enter Website URL"
                          autoComplete="off"
                          value={data.website || ""}
                          readOnly={isEditable}
                          tabIndex={8}
                          onChange={(e) =>
                            setSocityData({
                              ...data,
                              website: e.target.value,
                            })
                          }
                        />
                        {errors.website && <div className="invalid-feedback">{errors.website}</div>}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                <div className="row">
                        <div className="col-md-12 col-12">
                          <label htmlFor="addressTextarea">Address</label>
                          <div className="input-group mb-2 mb-md-2">
                            <textarea
                              id="addressTextarea"
                              className={`form-control input_style1 ${errors.address ? 'is-invalid' : ''}`}
                              placeholder="Enter Address"
                              autoComplete="off"
                              value={data.address || ""}
                              readOnly={isEditable}
                              tabIndex={8}
                              rows={2} // Adjust the number of rows as needed
                              onChange={(e) =>
                                setSocityData({
                                  ...data,
                                  address: e.target.value,
                                })
                              }
                            />
                            {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                          </div>
                        </div>
                      </div>

                </li>
              </ul>
            </div>
            {data.status === 1 ? (
              <div className="mt-4 d-flex justify-content-end w-100">
                <div className="form-group col-md-5 d-flex justify-content-end pr-0">
                  <a
                    href="/add-product"
                    type="button"
                    className="btn btn-primary w-60 d-flex align-items-center justify-content-center"
                  >
                    <span className="mr-2">
                      <img src="./assets/images/add_icon.png" alt="" />
                    </span>
                    Add New Product
                  </a>
                </div>
              </div>
            ) : (
              isEditable || (
                <>
                  <div className="mt-4 d-flex justify-content-end w-100">
                    <div className="form-group col-md-5 d-flex justify-content-end pr-0">
                      {loading ? (
                        <button
                          type="button"
                          className="btn btn-primary w-60 d-flex align-items-center justify-content-center"
                        >
                          <ThreeDots
                            visible={true}
                            height="40"
                            width="40"
                            color="#fff"
                            radius="1"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{ display: "block" }}
                            wrapperclassName=""
                          />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary w-60 d-flex align-items-center justify-content-center"
                        >
                          SUBMIT
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )
            )}
          </div>
        </form>
        <br />
        {data.status == 1 && <ProductList status={data.status} />}
      </section>
    </>
  );
};

export default Dashboard;
