import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Modal, Form ,Alert} from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import  { toast } from 'react-hot-toast';
import ExportButtons from './ExportButtons'; // Import the ExportButtons component

const Users = () => {
  const authToken = localStorage.getItem("admin-token");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [products, setProductsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    phone: '',
    district: '',
    email: '',
    address: '',  // Added address field
    status: false,
  });
  const [errors, setErrors] = useState({});
  const [editingUser, setEditingUser] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [alert, setAlert] = useState({ show: false, message: '' });

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}admin/users`, {
        method: "GET",
        headers: {
          Authorization: `jwt ${authToken}`,
          "Content-Type": "application/json",
        },
      });

      const jsonData = await response.json();

      if (jsonData.status) {
        const transformedData = jsonData.data.map((item, index) => ({
          sl: index + 1,
          username: item.username,
          phone: item.phone,
          district: item.district,
          district_name: item.district_name,
          email: item.email,
          address: item.address, // Included in the data transformation
          status: item.status,
          id: item.id,
        }));

        setProductsData(transformedData);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await fetch(`${apiUrl}district`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jsonData = await response.json();
      if (jsonData.status) {
        setDistricts(jsonData.data);
      }
    } catch (error) {
      console.error("Error fetching districts: ", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDistricts();
  }, []);

  const handleShowModal = (user = null) => {
    if (user) {
      setEditingUser(user);
      setFormData({
        username: user.username,
        phone: user.phone,
        district: user.district,
        email: user.email,
        address: user.address, // Populate with user address for editing
        status: user.status,
      });
    } else {
      setEditingUser(null);
      setFormData({
        username: '',
        password: '',
        phone: '',
        district: '',
        email: '',
        address: '', // Clear address on new user
        status: false,
      });
    }
    setErrors({});
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const validateForm = () => {
    const errors = {};
    const usernameRegex =  /^[a-zA-Z0-9\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;

    // Validate username
    if (!formData.username.trim()) {
      errors.username = "Username is required.";
    } else if (!usernameRegex.test(formData.username)) {
      errors.username = "Username can only contain letters and numbers, no special characters.";
    }

    // Validate password
    if (!editingUser && !formData.password.trim()) {
      errors.password = "Password is required.";
    }

    // Validate phone number
    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required.";
    } else if (!phoneRegex.test(formData.phone)) {
      errors.phone = "Phone number must be a 10-digit number.";
    }

    // Validate email
    if (!formData.email.trim()) {
      errors.email = "Email is required.";
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Invalid email format.";
    }

    // Validate district
    if (!formData.district) {
      errors.district = "District is required.";
    }

    // Validate address
    if (!formData.address.trim()) {
      errors.address = "Address is required.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      status: e.target.checked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const url = editingUser ? `${apiUrl}admin/edit_user/${editingUser.id}` : `${apiUrl}admin/add_user`;
    const method = editingUser ? "PUT" : "POST";

    try {
      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `jwt ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok && result.status) {
        toast.success(editingUser ? 'User updated successfully!' : 'User added successfully!');
        fetchData();
        handleCloseModal();
      } else {
        setAlert({ show: true, message: result.message });
        setTimeout(() => setAlert({ show: false, message: '' }), 5000);
      }
    } catch (error) {
      setAlert({ show: true, message: 'Failed to submit user data.' });
      setTimeout(() => setAlert({ show: false, message: '' }), 5000);
    }
  };

  const table_header = [
    {
      prop: "sl",
      title: "Sl No",
    },
    {
      prop: "username",
      title: "Name",
      isFilterable: true,
    },
    {
      prop: "phone",
      title: "Phone",
      isFilterable: true,
    },
    {
      prop: "district_name",
      title: "District",
      isFilterable: true,
    },
    {
      prop: "email",
      title: "Email",
      isFilterable: true,
    },
    {
      prop: "address",
      title: "Address",
      isFilterable: true,
    },
    {
      prop: "status",
      title: "Status",
      cell: (row) => (
        row.status ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>
      ),
    },
    {
      prop: "sl",
      title: "Action",
      cell: (row) => (
        <>
          <a
            className="td-item"
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              handleShowModal(row); // Open modal for editing
            }}
          >
            Edit
          </a>
        </>
      ),
    },
  ];

  return (
    <>
      <h3>Users</h3>
      <DatatableWrapper
        body={products}
        headers={table_header}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10,
            options: [5, 10, 15, 20],
          },
        }}
      >
        <Row className="mb-4 p-2">
          <Col
            xs={6}
            sm={6}
            lg={3}
            className="d-flex flex-col justify-content-lg-start align-items-start justify-content-sm-start mb-2 mb-sm-0"
          >
            <PaginationOptions />
          </Col>
          <Col
            xs={6}
            sm={6}
            lg={3}
            className="d-flex flex-col justify-content-lg-start align-items-start justify-content-sm-start mb-2 mb-sm-0"
          >
            <ExportButtons data={products} headers={table_header} />
          </Col>
          <Col
            xs={12}
            sm={12}
            lg={6}
            className="d-flex flex-col justify-content-lg-start align-items-start justify-content-sm-start mb-2 mb-sm-0"
          >
            <Filter />
          </Col>
        </Row>
        <div style={{ overflowX: "auto", backgroundColor: "#f5fbf8", padding: "1rem", borderRadius: "0.25rem" }}>
        <Table style={{ backgroundColor: "#f5fbf8" }}>
          <TableHeader />
          <TableBody />
        </Table>
        </div>
        <Row className="mb-4 p-2">
          <Col xs={12} sm={12} lg={6}>
            <Button onClick={() => handleShowModal()}>Add User</Button>
          </Col>
          <Col xs={12} sm={12} lg={6} className="d-flex flex-col justify-content-end align-items-end">
            <Pagination />
          </Col>
        </Row>
      </DatatableWrapper>

      {/* Modal for Add/Edit User */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header >
          <Modal.Title>{editingUser ? 'Edit User' : 'Add User'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {alert.show && <Alert variant="danger">{alert.message}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="username">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                isInvalid={!!errors.username}
              />
              <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
            </Form.Group>

            {!editingUser && (
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </Form.Group>
            )}

            <Form.Group controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                maxLength={10}
                value={formData.phone}
                onChange={handleInputChange}
                isInvalid={!!errors.phone}
              />
              <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="district">
              <Form.Label>District</Form.Label>
              <Form.Control
                as="select"
                name="district"
                value={formData.district}
                onChange={handleInputChange}
                isInvalid={!!errors.district}
              >
                <option value="">Select District</option>
                {districts.map((district) => (
                  <option key={district.id} value={district.id}>
                    {district.district}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.district}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                isInvalid={!!errors.address}
              />
              <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="status">
              <Form.Check
                type="checkbox"
                label="Active"
                name="status"
                checked={formData.status}
                onChange={handleCheckboxChange}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-2">
              {editingUser ? 'Update User' : 'Add User'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Users;
