import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Modal } from "react-bootstrap";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from "react-bs-datatable";
import  { toast } from 'react-hot-toast';
import ExportButtons from './ExportButtons'; // Import the ExportButtons component
import ProductDetailsModal from './ProductDetailsModal'; // Import the ProductDetailsModal component
import QRCode from 'qrcode.react'; // Import the QRCode component

import EditProductModal from "./EditProductModal"; // Import EditProductModal component

const RejectedProducts = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [products, setProductsData] = useState([]);
  const authToken = localStorage.getItem("admin-token");
  const [selectedProduct, setSelectedProduct] = useState(null); // State to hold selected product
  const [showProductModal, setShowProductModal] = useState(false); // State to control modal visibility
  const [qrModal, setQrModal] = useState({ show: false, code: '' }); // State to control QR modal visibility and content


  const fetchData = async () => {
    try {
      const response = await fetch(
        `${apiUrl}product/get-list/2`,
        {
          method: "GET",
          headers: {
            Authorization: `jwt ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      const jsonData = await response.json();

      if (jsonData.status) {
        const transformedData = jsonData.data.map((item, index) => ({
          sl: index + 1, // Start sequence from 1
          name: item.name,
          register_number: item.register_number,
          product_name: item.product_name,
          product_code: item.product_code,
          code: item.product_code,
          reject_reason: item.reject_reason,
          date: item.date.substring(0, 10),
          status: item.status,
          id: item.id,
        }));

        setProductsData(transformedData);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const acceptProducts = async (productId,approvers) => {
    try {
      const response = await fetch(`${apiUrl}product/approve_product`, {
        method: 'POST',
        headers: {
          Authorization: `jwt ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productId ,approvers}),
      });

      const result = await response.json();

      if (response.ok && result.status) {
        toast.success('Product approved successfully!');
        fetchData();
      } else {
        toast.error('Failed to approve product!');
      }
    } catch (error) {
      console.error('Error approving society:', error);
      toast.error('Failed to approve product!');
    }
  };

  const [showEditModal, setShowEditModal] = useState(false); // State to control Edit modal visibility
  const [editProduct, setEditProduct] = useState(null); // State to hold selected product for editing
  const handleEditClick = (product) => {
    setEditProduct(product); // Set the selected product for editing
    setShowEditModal(true); // Show the EditProductModal
  };
  

  const table_header = [
    {
      prop: "sl",
      title: "Sl No",
    },
    // {
    //   prop: "product_code",
    //   title: "Product Code",
    //   isFilterable: true,
    //   cell: (row) => (
    //     <a
    //       href="#!"
    //       onClick={(e) => {
    //         e.preventDefault();
    //         setSelectedProduct(row); // Set the selected product
    //         setShowProductModal(true); // Show the modal
    //       }}
    //     >
    //       {row.code}
    //     </a>
    //   ),
    // },
    {
      prop: "product_name",
      title: "Product Name",
      isFilterable: true,
    },
    {
      prop: "name",
      title: "Society Name",
      isFilterable: true,
    },
    {
      prop: "register_number",
      title: "Reg No",
      isFilterable: true,
    },
    {
      prop: "date",
      title: "Date",
      isFilterable: true,
    },
    {
      prop: "reject_reason",
      title: "Reject Reason",
      isFilterable: true,
    },
    // {
    //   prop: "status",
    //   title: "Status",
    //   isFilterable: true,
    //   cell: (row) => (row.status ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>)
    // },
    {
      prop: "sl",
      title: "Action",
      cell: (row) => (
        <>
             <a
                className="td-item"
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  handleEditClick(row); // Open Edit modal with selected product
                }}
              >
                Update
              </a>
            </>
          )}
    
  ];

  return (
    <>
      <h3>Products</h3>
      <DatatableWrapper
        body={products}
        headers={table_header}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10,
            options: [5, 10, 15, 20],
          },
        }}
      >
        <Row className="mb-4 p-2">
          <Col
            xs={6}
            sm={6}
            lg={3}
            className="d-flex flex-col justify-content-lg-start align-items-start justify-content-sm-start mb-2 mb-sm-0"
          >
            <PaginationOptions />
          </Col>
          <Col
            xs={6}
            sm={6}
            lg={3}
            className="d-flex flex-col justify-content-lg-start align-items-start justify-content-sm-start mb-2 mb-sm-0"
          >
            <ExportButtons data={products} headers={table_header} />
          </Col>
          
          <Col
            xs={12}
            sm={12}
            lg={6}
            className="d-flex flex-col justify-content-lg-start align-items-start justify-content-sm-start mb-2 mb-sm-0"
          >
            <Filter />
          </Col>
        </Row>
        <div style={{ overflowX: "auto", backgroundColor: "#f5fbf8", padding: "1rem", borderRadius: "0.25rem" }}>
        <Table style={{ backgroundColor: "#f5fbf8" }}>
          <TableHeader />
          <TableBody />
        </Table>
        </div>
        <Row className="mb-4 p-2">
          <Col
            xs={12}
            sm={12}
            lg={12}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Pagination />
          </Col>
        </Row>
      </DatatableWrapper>
     
      <ProductDetailsModal
        show={showProductModal}
        handleClose={() => setShowProductModal(false)}
        prods={selectedProduct} // Pass the selected product to the modal
      
        
      />

      {/* QR Code Modal */}
      <Modal show={qrModal.show} onHide={() => setQrModal({ show: false, code: '' })}>
        <Modal.Header >
          <Modal.Title>QR Code for Product</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{textAlign:"center"}}>
          <QRCode value={qrModal.code} size={256} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setQrModal({ show: false, code: '' })}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <EditProductModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        product={editProduct} // Pass selected product to EditProductModal
        fetchData={fetchData} // Refresh data after edit
      />
      
    </>
  );
};

export default RejectedProducts;
