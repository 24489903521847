import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import ExportButtons from "./ExportButtons";

const UserReport = () => {
  const authToken = localStorage.getItem("admin-token");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [products, setProductsData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [districtName, setDistrictName] = useState(""); // State for district filter
  const [districtOptions, setDistrictOptions] = useState([]); // State for district options

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}admin/users`, {
        method: "GET",
        headers: {
          Authorization: `jwt ${authToken}`,
          "Content-Type": "application/json",
        },
      });

      const jsonData = await response.json();

      if (jsonData.status) {
        const transformedData = jsonData.data.map((item, index) => ({
          sl: index + 1,
          date: item.date?.split("T")[0],
          username: item.username,
          phone: item.phone,
          email: item.email,
          address: item.address,
          district_name: item.district_name,
          id: item.id,
        }));

        setProductsData(transformedData);
        setFilteredProducts(transformedData);

        // Extract unique district names for the dropdown
        const districts = [...new Set(transformedData.map(item => item.district_name))];
        setDistrictOptions(districts);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let filteredData = products;

    if (districtName) {
      filteredData = filteredData.filter((product) =>
        product.district_name.toLowerCase().includes(districtName.toLowerCase())
      );
    }

    if (fromDate && toDate) {
      filteredData = filteredData.filter((product) => {
        const productDate = new Date(product.date);
        return productDate >= new Date(fromDate) && productDate <= new Date(toDate);
      });
    }

    setFilteredProducts(filteredData);
  }, [fromDate, toDate, districtName, products]);

  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);
  const handleDistrictChange = (e) => setDistrictName(e.target.value);

  const table_header = [
    {
      prop: "sl",
      title: "Sl No",
    },
    {
      prop: "username",
      title: "Name",
      isFilterable: true,
    },
    {
      prop: "phone",
      title: "Phone",
      isFilterable: true,
    },
    {
      prop: "email",
      title: "Email",
      isFilterable: true,
    },
    {
      prop: "address",
      title: "Address",
      isFilterable: true,
    },
    {
      prop: "district_name",
      title: "District",
      isFilterable: true,
    },
    {
      prop: "date",
      title: "Date",
      isFilterable: true,
    }
  ];

  return (
    <>
      <h3>User Report</h3>

      <Row className="mb-4 p-2">
        <Col xs={12} sm={6} lg={3}>
          <label>Filter by District:</label>
          <select
            className="form-control"
            value={districtName}
            onChange={handleDistrictChange}
          >
            <option value="">All Districts</option>
            {districtOptions.map((district, index) => (
              <option key={index} value={district}>
                {district}
              </option>
            ))}
          </select>
        </Col>

        <Col xs={12} sm={6} lg={3}>
          <label>From Date:</label>
          <input
            type="date"
            className="form-control"
            value={fromDate}
            onChange={handleFromDateChange}
          />
        </Col>

        <Col xs={12} sm={6} lg={3}>
          <label>To Date:</label>
          <input
            type="date"
            className="form-control"
            value={toDate}
            onChange={handleToDateChange}
          />
        </Col>
      </Row>

      <DatatableWrapper
        body={filteredProducts}
        headers={table_header}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10,
            options: [5, 10, 15, 20],
          },
        }}
      >
        <Row className="mb-4 p-2">
          <Col
            xs={12}
            sm={12}
            lg={6}
            className="d-flex flex-col justify-content-lg-start align-items-start justify-content-sm-start mb-2 mb-sm-0"
          >
            <PaginationOptions />
          </Col>
          <Col xs={12} sm={12} lg={6} className="mt-3 float-right">
            <ExportButtons data={filteredProducts} headers={table_header} />
          </Col>
        </Row>

        <div style={{ overflowX: "auto", backgroundColor: "#f5fbf8", padding: "1rem", borderRadius: "0.25rem" }}>
          <Table style={{ backgroundColor: "#f5fbf8" }}>
            <TableHeader />
            <TableBody />
          </Table>
        </div>

        <Row className="mb-4 p-2">
          <Col
            xs={12}
            sm={12}
            lg={12}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Pagination />
          </Col>
        </Row>
      </DatatableWrapper>
    </>
  );
};

export default UserReport;
