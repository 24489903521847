import React from "react";
const AboutUs =()=>{
   
    return (<>
    

  <section className="about_us_new">
    <div className="section-1 bg_1">
    <div className="container-new">
        <div className="custom_head">
          <h5>About</h5>
          <h2>Kerala Handloom Mark (Kaithari Indication Tag - KIT)</h2>
          </div>
          <p>Welcome to the official platform for the Kerala Handloom Mark, also known as the Kaithari Indication Tag  ( KIT) – a symbol of authenticity and pride in the traditional handloom craftsmanship of Kerala. The Kaithari Indication Tag is an exclusive mark of identity that certifies the authenticity, quality, and heritage of Kerala's handloom products, ensuring that every piece carrying this tag is a genuine representation of our rich cultural fabric.</p>
      </div>
    </div>
    <div className="section-2">
      <div className="container-new">
        <div className="row">
        <div className="col-md-4">
          <div className="img-box">
              <img src="/assets/images/about-img-2.png" alt="image" className="w-100"/>
          </div>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-center">
          <div className="content">
            <h5>Our Mission</h5>
            <p>At Kerala Handloom Mark, our mission is to protect, promote, and preserve the centuries-old handloom weaving tradition of Kerala. We are committed to ensuring that the artisans who have devoted their lives to perfecting these crafts are recognized and their products are given the rightful status they deserve in the global market. Our Kaithari Indication Tag acts as a bridge between consumers seeking authentic Kerala handloom products and the weavers who pour their skill and soul into creating them.</p>
            <h5>What is the Kaithari Indication Tag ( KIT )</h5>
            <p>The Kaithari Indication Tag is more than just a label – it is a promise of authenticity and quality. Handloom products bearing this tag are verified through a rigorous process to ensure they meet the highest standards of craftsmanship, materials, and traditional techniques. This certification helps consumers distinguish genuine Kerala handloom products from imitations, offering confidence in their purchase.</p>
            <h5>Why Choose Kerala Handloom Mark?</h5>
            <ul>
              <li>Authenticity: The Kaithari Indication Tag certifies that the product is 100% handwoven by Kerala’s skilled artisans, maintaining the traditional weaving practices that have been passed down through generations.</li>
              <li>Heritage Preservation: By choosing a product with the Kerala Handloom Mark, you are not just buying a piece of fabric – you are supporting the preservation of a living tradition and the livelihoods of local artisans.</li>
              <li>Quality Assurance: Each product is meticulously crafted, and the tag ensures that it meets high standards in terms of material, design, and durability, reflecting the best of Kerala's handloom industry.</li>
            </ul>
          </div>
        </div>
        <div className="col-md-12">
          <h5>Registration and Certification Process</h5>
          <p>We invite handloom weavers, cooperatives, and manufacturers from across Kerala to register with us and become a part of this prestigious certification program. The registration process is simple and transparent, designed to ensure that only genuine handloom products receive the Kaithari Indication Tag.</p>
          <h5>Steps for Registration:</h5>
          <ul>
            <li>Application Submission: Submit the necessary documentation and product samples.</li>
            <li>Verification: Our team of experts will verify the authenticity and quality of the submitted handloom products.</li>
            <li>Certification: Upon successful verification, the products will be awarded the Kaithari Indication Tag.</li>
            <li>Ongoing Support: Registered members will receive continued support, including marketing assistance and access to exclusive trade events.</li>
          </ul>
          <p>By promoting this initiative, we are strengthening the roots of Kerala’s handloom tradition while providing weavers with the recognition they deserve.</p>
          <h5>Join Us</h5>
          <p>Whether you are a weaver, a cooperative, or a patron of Kerala’s rich handloom heritage, we invite you to join hands with us in promoting and safeguarding this timeless craft. Together, we can ensure that the legacy of Kerala's handloom industry continues to thrive, bringing its beauty to markets around the world.</p>
        </div>

        </div>
        </div>
      </div>
  </section>
    </>);
}
export default AboutUs;
