import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Modal, Form } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { toast } from "react-toastify";
import ExportButtons from "./ExportButtons";
import SocietyModal from "./SocietyModal";
import SocietyDetailsModal from "./SocietyDetailsModal";

const Society = ({ status }) => {
  const authToken = localStorage.getItem("admin-token");
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showApproverModal, setShowApproverModal] = useState(false);
  const [products, setProductsData] = useState([]);
  const [selectedSociety, setSelectedSociety] = useState(null);
  const [approverName, setApproverName] = useState(""); // State for approver name
  const [approverNameError, setApproverNameError] = useState(""); // State for validation message

  // const [approver1Name, setApprover1Name] = useState("");
  const [approver1Designation, setApprover1Designation] = useState("");
  // const [approver2Name, setApprover2Name] = useState("");
  // const [approver2Designation, setApprover2Designation] = useState("");

  const [showRejectModal, setShowRejectModal] = useState(false);
  // const [rejecter1Name, setRejecter1Name] = useState("");
  const [rejecter1Designation, setRejecter1Designation] = useState("");
  // const [rejecter2Name, setRejecter2Name] = useState("");
  // const [rejecter2Designation, setRejecter2Designation] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [rejectErrors, setRejectErrors] = useState({});

  const [socity_stat, setSocityStatus] = useState(status);

  const [errors, setErrors] = useState({});

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}admin/get_by_status/${socity_stat}`, {
        method: "GET",
        headers: {
          Authorization: `jwt ${authToken}`,
          "Content-Type": "application/json",
        },
      });
      const jsonData = await response.json();

      if (jsonData.status) {
        const transformedData = jsonData.data.map((item, index) => ({
          sl: index + 1,
          code: item.register_number,
          name: item.name,
          district_name: item.district_name,
          taluk_name: item.taluk_name,
          village_name: item.village_name,
          phone: item.phone,
          status: item.status,
          number: item.society_number,
          id: item.id,
          address: item.address,
          contact_person: item.contact_person,
          second_phone: item.second_phone,
          // date: item.date?.slice(0,10),
        }));
        setProductsData(transformedData);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [socity_stat]);

  // const handleApproverSubmit = async () => {
  //   if (!approverName) {
  //     setApproverNameError("Approver's name is required.");
  //     return;
  //   }
  //   setApproverNameError(""); // Clear any previous errors

  //   try {
  //     const response = await fetch(`${apiUrl}admin/approve_socity`, {
  //       method: 'POST',
  //       headers: {
  //         Authorization: `jwt ${authToken}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ societyId: selectedSociety.id, approverName }),
  //     });

  //     const result = await response.json();

  //     if (response.ok && result.status) {
  //       toast.success('Society approved successfully!');
  //       setShowApproverModal(false);
  //       setApproverName(""); // Reset the approver name
  //       fetchData();
  //     } else {
  //       toast.error('Failed to approve society.');
  //     }
  //   } catch (error) {
  //     toast.error('Failed to approve society.');
  //   }
  // };

  const handleApproverSubmit = async () => {
    const validationErrors = {};

    // if (!approver1Name)
    //   validationErrors.approver1Name = "Approver 1's name is required.";
    if (!approver1Designation)
      validationErrors.approver1Designation =
        "Approver's designation is required.";
    // if (!approver2Name)
    //   validationErrors.approver2Name = "Approver 2's name is required.";
    // if (!approver2Designation)
    //   validationErrors.approver2Designation =
    //     "Approver 2's designation is required.";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({}); // Clear previous errors

    try {
      const response = await fetch(`${apiUrl}admin/approve_socity`, {
        method: "POST",
        headers: {
          Authorization: `jwt ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          societyId: selectedSociety.id,
          societyDistrict: selectedSociety.district_name,
          societyName: selectedSociety.name,
          phone: selectedSociety.phone,
          // approver1Name,
          approver1Designation,
          // approver2Name,
          // approver2Designation,
        }),
      });

      const result = await response.json();

      if (response.ok && result.status) {
        toast.success("Society approved successfully!");
        setShowApproverModal(false);
        fetchData(); // Refresh data

        // setApprover1Name("");
        setApprover1Designation("");
        // setApprover2Name("");
        // setApprover2Designation("");

        setShowRejectModal(false);
      } else {
        toast.error("Failed to approve society.");
      }
    } catch (error) {
      toast.error("Failed to approve society.");
    }
  };

  const handleRejectSubmit = async () => {
    const validationErrors = {};

    // if (!rejecter1Name)
    //   validationErrors.rejecter1Name = "Rejecter 1's name is required.";
    if (!rejecter1Designation)
      validationErrors.rejecter1Designation =
        "Rejecter's designation is required.";
    // if (!rejecter2Name)
    //   validationErrors.rejecter2Name = "Rejecter 2's name is required.";
    // if (!rejecter2Designation)
    //   validationErrors.rejecter2Designation =
    //     "Rejecter 2's designation is required.";
    if (!rejectReason)
      validationErrors.rejectReason = "Reject reason is required.";

    if (Object.keys(validationErrors).length > 0) {
      setRejectErrors(validationErrors);
      return;
    }

    setRejectErrors({}); // Clear previous errors

    try {
      const response = await fetch(`${apiUrl}admin/reject_socity`, {
        method: "POST",
        headers: {
          Authorization: `jwt ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          societyId: selectedSociety.id,
          phone: selectedSociety.phone,
          // rejecter1Name,
          rejecter1Designation,
          // rejecter2Name,
          // rejecter2Designation,
          rejectReason,
        }),
      });

      const result = await response.json();

      if (response.ok && result.status) {
        toast.success("Society removed successfully!");
        // setRejecter1Name("");
        setRejecter1Designation("");
        // setRejecter2Name("");
        // setRejecter2Designation("");
        setRejectReason("");
        setShowRejectModal(false);
        fetchData();
      } else {
        toast.error("Failed to remove society.");
      }
    } catch (error) {
      toast.error("Failed to remove society.");
    }
  };

  const table_header = [
    {
      prop: "sl",
      title: "Sl No",
    },
    {
      prop: "name",
      title: "Society Name",
      isFilterable: true,
      cell: (row) => (
        <>
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              setSelectedSociety(row);
              setShowDetailsModal(true);
            }}
          >
            {row.name}
          </a>
        </>
      ),
    },
    // {
    //   prop: "number",
    //   title: "Society Number",
    //   isFilterable: true,
    // },
    {
      prop: "code",
      title: "Reg No",
      isFilterable: true,
    },
    {
      prop: "district_name",
      title: "District",
      isFilterable: true,
    },
    {
      prop: "taluk_name",
      title: "Taluk",
      isFilterable: true,
    },
    {
      prop: "village_name",
      title: "Village",
      isFilterable: true,
    },
    {
      prop: "phone",
      title: "Phone No",
      isFilterable: true,
    },
    // {
    //   prop: "date",
    //   title: "Reg Date",
    //   isFilterable: true,
    // }, 
    {
      prop: "status",
      title: "Status",
      isFilterable: true,
      cell: (row) => (row.status ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>)
    },
    {
      prop: "sl",
      title: "Action",
      cell: (row) =>
        row.status == 0 && (
        <>
          <a
            className="td-item"
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              setSelectedSociety(row);
              setShowApproverModal(true);
            }}
          >
            Accept
          </a>
          &nbsp;
          <a
            className="td-item btn-danger"
            href="#!"
            onClick={(e) => {
              e.preventDefault();
              setSelectedSociety(row);
              setShowRejectModal(true);
            }}
          >
            Reject
          </a>
        </>
      ),
    },
  ];

  return (
    <>
      <h3>Society</h3>
      <SocietyModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        updateList={() => fetchData()}
      />
      <SocietyDetailsModal
        show={showDetailsModal}
        handleClose={() => setShowDetailsModal(false)}
        updateList={() => fetchData()}
        society={selectedSociety}
        acceptModal={setShowApproverModal}
        rejecttModal={setShowRejectModal}
        
      />
      <DatatableWrapper
        body={products}
        headers={table_header}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10,
            options: [5, 10, 15, 20],
          },
        }}
      >
        <Row className="mb-4 p-2">
          <Col xs={6} sm={6} lg={2}>
            <PaginationOptions />
          </Col>
          <Col xs={6} sm={6} lg={3}>
            <ExportButtons data={products} headers={table_header} />
          </Col>
          <Col xs={12} sm={6} lg={3}>
          <div className="input-group" >
              {/* <label>Filter by Status</label> */}
              <select className="form-control"  value={socity_stat} onChange={(e)=>setSocityStatus(e.target.value)} style={{height: 'calc(2.25rem + 13px)'}}>
                <option value="">All Status</option>
                <option key={0} value={0}>Pending</option>
                <option key={1} value={1}>Approved</option>
            </select>
          </div>
        </Col>
          <Col xs={12} sm={12} lg={4}>
            <Filter />
          </Col>
        </Row>
        <div style={{ overflowX: "auto", backgroundColor: "#f5fbf8", padding: "1rem", borderRadius: "0.25rem" }}>
        <Table style={{ backgroundColor: "#f5fbf8" }}>
          <TableHeader />
          <TableBody />
        </Table>
        </div>
        <Row className="mb-4 p-2">
          <Col xs={12} sm={12} lg={6}>
            <Button onClick={() => setShowModal(true)}>Add Society</Button>
          </Col>
          <Col xs={12} sm={12} lg={6} className="d-flex justify-content-end">
            <Pagination />
          </Col>
        </Row>
      </DatatableWrapper>

      {/* Modal for entering approver's name */}
      <Modal
        show={showApproverModal}
        onHide={() => setShowApproverModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Approve Society</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group controlId="approver1Name">
              <Form.Label>Approver 1's Name</Form.Label>
              <Form.Control
                type="text"
                value={approver1Name}
                onChange={(e) => setApprover1Name(e.target.value)}
                placeholder="Enter approver 1's name"
              />
              {errors.approver1Name && (
                <Form.Text className="text-danger">
                  {errors.approver1Name}
                </Form.Text>
              )}
            </Form.Group> */}

            <Form.Group controlId="approver1Designation">
              <Form.Label>Approver's Designation</Form.Label>
              <Form.Control
                type="text"
                value={approver1Designation}
                onChange={(e) => setApprover1Designation(e.target.value)}
                placeholder="Enter approver's designation"
              />
              {errors.approver1Designation && (
                <Form.Text className="text-danger">
                  {errors.approver1Designation}
                </Form.Text>
              )}
            </Form.Group>

            {/* <Form.Group controlId="approver2Name">
              <Form.Label>Approver 2's Name</Form.Label>
              <Form.Control
                type="text"
                value={approver2Name}
                onChange={(e) => setApprover2Name(e.target.value)}
                placeholder="Enter approver 2's name"
              />
              {errors.approver2Name && (
                <Form.Text className="text-danger">
                  {errors.approver2Name}
                </Form.Text>
              )}
            </Form.Group> */}

            {/* <Form.Group controlId="approver2Designation">
              <Form.Label>Approver 2's Designation</Form.Label>
              <Form.Control
                type="text"
                value={approver2Designation}
                onChange={(e) => setApprover2Designation(e.target.value)}
                placeholder="Enter approver 2's designation"
              />
              {errors.approver2Designation && (
                <Form.Text className="text-danger">
                  {errors.approver2Designation}
                </Form.Text>
              )}
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowApproverModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleApproverSubmit}>
            Approve
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
        <Modal.Header>
          <Modal.Title>Reject Society</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group controlId="rejecter1Name">
              <Form.Label>Rejecter 1's Name</Form.Label>
              <Form.Control
                type="text"
                value={rejecter1Name}
                onChange={(e) => setRejecter1Name(e.target.value)}
                placeholder="Enter rejecter 1's name"
              />
              {rejectErrors.rejecter1Name && (
                <Form.Text className="text-danger">
                  {rejectErrors.rejecter1Name}
                </Form.Text>
              )}
            </Form.Group> */}

            <Form.Group controlId="rejecter1Designation">
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type="text"
                value={rejecter1Designation}
                onChange={(e) => setRejecter1Designation(e.target.value)}
                placeholder="designation"
              />
              {rejectErrors.rejecter1Designation && (
                <Form.Text className="text-danger">
                  {rejectErrors.rejecter1Designation}
                </Form.Text>
              )}
            </Form.Group>

            {/* <Form.Group controlId="rejecter2Name">
              <Form.Label>Rejecter 2's Name</Form.Label>
              <Form.Control
                type="text"
                value={rejecter2Name}
                onChange={(e) => setRejecter2Name(e.target.value)}
                placeholder="Enter rejecter 2's name"
              />
              {rejectErrors.rejecter2Name && (
                <Form.Text className="text-danger">
                  {rejectErrors.rejecter2Name}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="rejecter2Designation">
              <Form.Label>Rejecter 2's Designation</Form.Label>
              <Form.Control
                type="text"
                value={rejecter2Designation}
                onChange={(e) => setRejecter2Designation(e.target.value)}
                placeholder="Enter rejecter 2's designation"
              />
              {rejectErrors.rejecter2Designation && (
                <Form.Text className="text-danger">
                  {rejectErrors.rejecter2Designation}
                </Form.Text>
              )}
            </Form.Group> */}

            <Form.Group controlId="rejectReason">
              <Form.Label>Reject Reason</Form.Label>
              <Form.Control
                type="text"
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
                placeholder="Enter reason for rejection"
              />
              {rejectErrors.rejectReason && (
                <Form.Text className="text-danger">
                  {rejectErrors.rejectReason}
                </Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRejectModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleRejectSubmit}>
            Reject
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Society;
