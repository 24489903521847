import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; 

const DashbordView = ({ redirectViewPage,productStatus,societyStatus }) => {
  const [data, setData] = useState({});
  const authToken = localStorage.getItem("admin-token");

  const redirect = useNavigate();

  const apiUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await fetch(`${apiUrl}admin/dashboard`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `jwt ${authToken}`,
          },
        });

        const jsonData = await response.json();

        if (jsonData.status) {
          setData(jsonData.data);
        }else{
          localStorage.removeItem('admin-token');
          localStorage.removeItem('adminDetails');
          redirect('/admin/login');
        }

      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchDashboardData();
  }, []);
  return (
    <>
      <div className="container-fluid">
        {/*  <!-- Page Heading --> */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
          {/* <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                                        className="fas fa-download fa-sm text-white-50"></i> Generate Report</a> */}
        </div>

        <div className="row">
          <div className="col-lg-5 col-md-12 mb-4">
            {/* <!-- Illustrations --> */}
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Registrations
                </h6>
              </div>
              <div className="card-body" onClick={(e)=>{societyStatus('');redirectViewPage('society')}} style={{cursor: 'pointer'}}>
                <div className="text-center">
                  <img
                    className="img-fluid px-3 px-sm-4 mt-1 mb-1 a8"
                    src="/assets/images/undraw_posting_photo.svg"
                    alt="..."
                  />

                  <div className="h5 m-3 font-weight-bold text-gray-800">
                    {data.registration_count}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 mb-4">
            {/*  <!-- Earnings (Monthly) Card Example --> */}
            <div className="row">
              <div className="col-lg-6  col-md-12 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body" onClick={(e)=>redirectViewPage('accepted_society')} style={{cursor: 'pointer'}}>
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="font-weight-bold text-primary text-uppercase mb-1">
                          Registrations (Approved)
                        </div>
                        <div className="h5 m-3 font-weight-bold text-gray-800">
                          {data.registration_approved}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*  <!-- Earnings (Monthly) Card Example --> */}
              <div className="col-lg-6  col-md-12 mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body" onClick={(e)=>{societyStatus(0);redirectViewPage('society')}} style={{cursor: 'pointer'}}>
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="font-weight-bold text-success text-uppercase mb-1">
                          Registrations (Pending)
                        </div>
                        <div className="h5 m-3 font-weight-bold text-gray-800">
                          {data.registration_pending}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6  col-md-12 mb-4">
                <div className="card border-left-info shadow h-100 py-2">
                  <div className="card-body" onClick={async (e)=>{productStatus(1);redirectViewPage('products')}} style={{cursor: 'pointer'}}>
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className=" font-weight-bold text-info text-uppercase mb-1">
                          Products (Approved)
                        </div>
                        <div className="row no-gutters align-items-center">
                          <div className="col-auto">
                            <div className="h5 m-3 mr-3 font-weight-bold text-gray-800">
                              {data.products_approved}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*  <!-- Pending Requests Card Example --> */}
              <div className="col-lg-6  col-md-12 mb-4">
                <div className="card border-left-warning shadow h-100 py-2">
                  <div className="card-body"onClick={async (e)=>{await productStatus(0);redirectViewPage('products')}} style={{cursor: 'pointer'}}>
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="font-weight-bold text-warning text-uppercase mb-1">
                          Products (Pending)
                        </div>
                        <div className="h5 m-3 font-weight-bold text-gray-800">
                          {data.products_pending}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashbordView;
