import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Table } from "react-bootstrap";


import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { ThreeDots } from "react-loader-spinner";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";

import QRCode from 'qrcode';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";

const ProductList = ({ status ,adminStatus}) => {
  const [products, setProductsData] = useState([]);
  const [loadingQRCode, setLoadingQRCode] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState(false);
  // const [loadingCertificate, setLoadingCertificate] = useState(false);

  const redirect = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        let societyDetails = localStorage.getItem("societyDetails");
        societyDetails = JSON.parse(societyDetails);
        const idValue = societyDetails.id;

        const response = await fetch(
          `${apiUrl}product/get-by-socity/${idValue}`,
          {
            method: "GET",
            headers: {
              Authorization: `jwt ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          if (response.statusText === "Unauthorized") {
            localStorage.removeItem("token");
            localStorage.removeItem("societyDetails");
            redirect("/login");
            return;
          } else {
            throw new Error("Network response was not ok");
          }
        }

        const jsonData = await response.json();

        if (jsonData.status) {
          const transformedData = jsonData.data.map((item, index) => ({
            sl: index + 1, // Start sequence from 1
            code: item.product_code,
            name: item.product_name,
            status: item.status,
            reject_reason: item.reject_reason,
            id: item.id,
          }));

          setProductsData(transformedData);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    if (status) {
      fetchData();
    }
  }, [status,deleteStatus]);

  const handleGenerateQRCode = async (data) => {
    setLoadingQRCode(data.code); // Set loading state to current product code
    const currentDomain = window.location.origin;
    const url = `${currentDomain}/product/${data.code}`;
  
    try {
      // Generate QR Codes
      const qrCodeDataUrl1 = await QRCode.toDataURL(url, {
        width: 150,
        margin: 1,
      });
  
      const qrCodeDataUrl2 = await QRCode.toDataURL(url, {
        width: 100,
        margin: 1,
      });
  
      // HTML content with styles included
      const htmlContent = `
        <html>
        <head>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 0;
              }
              .container {
                  max-width: 210mm;
                  margin: 0 auto;
                  padding: 20px;
              }
              .qr-code-container {
                  text-align: center;
                  margin-bottom: 20px;
              }
              .directions {
                  margin-top: 30px;
              }
              .directions h2 {
                  font-size: 18px;
                  font-weight: bold;
                  margin-bottom: 10px;
              }
              .directions ul {
                  list-style-type: disc;
                  padding-left: 20px;
              }
              .directions ul li {
                  font-size: 14px;
                  line-height: normal;
                  font-weight: 500;
                  color: #333333;
                  padding-top: 5px;
                  padding-bottom: 5px;
              }
              .qr-code-container.border {
                  border: 1px dashed #000000;
                  margin-left: auto;
                  margin-right: auto;
              }
              .qr-code-container.two {
                  transform: rotate(270deg); /* Rotates the container */
              }
              .qr-code-container.two img {
                  display: block;
                  margin: 15px auto 0px;
              }
          </style>
        </head>
        <body>
          <div class="container">
              <div class="qr-code-container">
                  <img src="${qrCodeDataUrl1}" alt="QR Code 1" style="width:150px;">
                  <div style="font-size:12px;color:#333333;font-weight:500;">${data.code}</div>
              </div>
               <div class="qr-code-container two border" style="width:120px;height:250px;text-align:center;margin-top:-40px;">
                   <img src="/assets/images/logo-4qr.png" alt="Kerala Kaithari Logo" style="width:70px;">
                  <img src="${qrCodeDataUrl2}" alt="QR Code 2" style="margin-top:10px;">
                  <div style="font-size:7px;color:#333333;font-weight:500;margin-top:5px;">${data.code}</div>
              </div>
              <div class="directions" style="margin-top:-30px;">
                <h2 style="color:#333333;font-weight:500;font-size:16px;text-decoration: underline;">Guideline for Printing Kerala Kaithari Mark QR Codes on Dress Materials</h2>
                <p>1. QR Code Options:</p>
                <ul>
                    <li>Option 1: 2 x 2 inch QR Code (Sticker Type).</li>
                    <li>This QR code will be placed as a sticker on the dress material or packaging.</li>
                    <li>Option 2: 2 x 1 inch QR Code with Kerala Kaithari Mark Logo.</li>
                    <li>This QR code includes the Kerala Kaithari logo and links to the product details as well.</li>
                    <li>It will primarily serve as a label attached to the fabric, symbolizing the authenticity and quality of Kerala handloom products.</li>
                </ul>
                <p>2. Placement Guidelines:</p>
                <ul>
                    <li>2 x 2 inch QR Code (Sticker Type):</li>
                    <li>The sticker should be placed on the packaging or on a tag attached to the dress material.</li>
                    <li>Ensure that the surface where the sticker is applied is smooth and clean for optimal adhesion.</li>
                    <li>Place the QR code in a visible area where customers can easily scan it.</li>
                    <li>2 x 1 inch QR Code with Kerala Kaithari Mark:</li>
                    <li>The label should be attached directly to the garment (such as on the hem, neck tag, or side seam).</li>
                    <li>Make sure the label is sewn in securely but does not affect the aesthetics or comfort of the dress material.</li>
                    <li>The QR code should be clearly visible and easily accessible to scan.</li>
                </ul>
                <p>3. Printing Specifications:</p>
                <ul>
                    <li>Resolution: Ensure a high-resolution print (300 DPI minimum) for the QR code so it remains clear and scannable after printing.</li>
                </ul>
            </div>
          </div>
        </body>
        </html>
      `;
  
      // Create a temporary HTML element to render the content
      const tempIframe = document.createElement("iframe");
      tempIframe.style.position = "absolute";
      tempIframe.style.width = "210mm";
      tempIframe.style.height = "297mm";
      document.body.appendChild(tempIframe);
  
      const doc = tempIframe.contentWindow.document;
      doc.open();
      doc.write(htmlContent);
      doc.close();
  
      // Wait for images to load before capturing the canvas
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust timeout as needed
  
      // Convert HTML to Canvas
      const canvas = await html2canvas(doc.body, {
        scale: 4, // Increase scale for better resolution
      });
  
      // Create a new jsPDF instance
      const pdf = new jsPDF("p", "mm", "a4");
      const imgData = canvas.toDataURL("image/png");
  
      // Add the generated image to the PDF
      pdf.addImage(imgData, "PNG", 0, 0, 210, 297); // A4 size (210 x 297 mm)
      pdf.save(`QR-${data.code}.pdf`);
  
      // Remove the temporary element
      document.body.removeChild(tempIframe);
    } catch (error) {
      console.error("Error generating QR code or PDF:", error);
    } finally {
      setLoadingQRCode(null); // Reset loading state
    }
  };
  

  // const deleteProduct = async (data) => {
  //   confirmAlert({
  //     title: 'Confirm to submit',
  //     message: 'Are you sure to delete this product.',
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => {
  //           fetch(`${apiUrl}product/delete_product`, {
  //             method: "POST",
  //             body: JSON.stringify({ productId: data.id }), // Ensure the body is stringified
  //             headers: {
  //               Authorization: `jwt ${authToken}`,
  //               "Content-Type": "application/json",
  //             },
  //           })
  //           .then((res) => res.json())
  //           .then((jsonResponse) => {
  //             if (jsonResponse.status) {
  //               toast.success('Product deleted successfully!');
  //               // Optionally refresh the product list or update UI
  //               setDeleteStatus(deleteStatus?false:true);
  //             } else {
  //               toast.error('Failed to delete product. Please try again.');
  //             }
  //           })
  //           .catch((error) => {
  //             console.error('Error deleting product:', error);
  //             toast.error('An error occurred while deleting the product.');
  //           });
  //         }
  //       },
  //       {
  //         label: 'No',
  //         // onClick: () => alert('Click No')
  //       }
  //     ]
  //   });
  // };

  // const handleDownloadCertificate = () => {
  //   setLoadingCertificate(true);
  //   setTimeout(() => {
  //     setLoadingCertificate(false);
  //   }, 1000); // Simulate a 2-second delay
  // };


  const table_header = [
    {
      prop: "sl",
      title: "Sl No",
    },
    {
      prop: "code",
      title: "Code",
      isFilterable: true,
      cell: (row) => (
        row.status === 1 ? (<a href={`/product/${row.code}`} target="_blank" rel="Product Details">{row.code}</a>): row.code
      ),
    },
    {
      prop: "name",
      title: "Name",
      isFilterable: true,
    },
    {
      prop: "reject_reason",
      title: "Remark",
      isFilterable: false,
      cell: (row) => row.status === 2 ? (<span className="text-danger"> {row.reject_reason} </span>)  : null
    },
    {
      prop: "edit",
      cell: (row) =>
        row.status !== 1 && adminStatus !== true ? ( // Display edit option only when adminStatus is not true
          <div className="product-edit">
            <a href={`/edit-product/${row.code}`}>
              <img src="./assets/images/product_edit.png" alt="" />
            </a>
          </div>
        ) : null,
    },
    // {
    //   prop: "delete",
    //   cell: (row) => (
    //     <div className="product-delete">
    //       <a href="#!" onClick={(e) => deleteProduct(row)}>
    //         <img src="./assets/images/product_delete.png" alt="" />
    //       </a>
    //     </div>
    //   ),
    // },
    {
      prop: "qr",
      cell: (row) =>
        row.status === 1 ? (
          <a
            className="td-item"
            href="#!"
            onClick={(e) => handleGenerateQRCode(row)}
          >
            {loadingQRCode === row.code ? (
               <ThreeDots
               visible={true}
               height="20"
               width="40"
               color="#fff"
               radius="1"
               ariaLabel="three-dots-loading"
               wrapperStyle={{ display: 'block' }}
             />
            ) : (
              "QR Code"
            )}
          </a>
        ) : null,
    },
    
  ];

  return (
    <>
    {adminStatus != true && (<h3>Products</h3>)}
    <DatatableWrapper
      body={products}
      headers={table_header}
      paginationOptionsProps={{
        initialState: {
          rowsPerPage: 10,
          options: [5, 10, 15, 20],
        },
      }}
    >
      <Row className="mb-4 p-2">
        <Col
          xs={12}
          sm={12}
          lg={12}
          className="d-flex flex-col justify-content-lg-start align-items-start justify-content-sm-start mb-2 mb-sm-0"
        >
          <PaginationOptions />
        </Col>
      </Row>
      <Table style={{ backgroundColor: "#f5fbf8" }}>
        <TableHeader />
        <TableBody />
      </Table>
      <Row className="mb-4 p-2">
        <Col
          xs={12}
          sm={12}
          lg={12}
          className="d-flex flex-col justify-content-end align-items-end"
        >
          <Pagination />
        </Col>
      </Row>
    </DatatableWrapper></>
  );
};

export default ProductList;
