import React from "react";
const NotFound = () => {
  return (
    <>
      <style>
        {`
    
    html {
      color: #888;
      display: table;
      font-family: sans-serif;
      height: 100%;
      text-align: center;
      width: 100%;
    }

    body {
      display: table-cell;
      verticalAlign: middle;
      margin: 2em auto;
    }

    h1 {
      color: #555;
      fontSize: 2em;
      fontWeight: 400;
     text-align: center;
    }

    p {
      margin: 0 auto;
      width: 280px;
    }

    @media only screen and (max-width: 280px) {

      body,
      p {
        width: 95%;
      }

      h1 {
        fontSize: 1.5em;
        margin: 0 0 0.3em;
      }

    }
      `}
      </style>
      <h1>Page Not Found</h1>
      <p>Sorry, but the page you were trying to view does not exist.</p>
    </>
  );
};
export default NotFound;
