import React, { useState, useEffect } from 'react';
import {
  DatatableWrapper,
  TableHeader,
  TableBody,
  Pagination,
  PaginationOptions,
} from 'react-bs-datatable'; // Import required components
// import 'bootstrap/dist/css/bootstrap.min.css';

const Stores = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [societies, setSocieties] = useState([]);

  useEffect(() => {
    const fetchSocieties = async () => {
      try {
        const response = await fetch(`${apiUrl}get_society`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const respo = await response.json();
        
        // Add serial numbers to the societies
        const societiesWithSerial = respo.data.map((society, index) => ({
          ...society,
          serialNumber: index + 1, // Adds serial numbers starting from 1
          website: (
            <a href={`https://${society.website}`} target="_blank" rel="noopener noreferrer">
              {society.website}
            </a>
          ), // Website link opens in a new tab
        }));

        setSocieties(societiesWithSerial);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchSocieties();
  }, [apiUrl]);

  // Columns for the table
  const header = [
    { prop: 'serialNumber', title: 'Serial Number' }, // Serial Number column
    { prop: 'name', title: 'Name' },
    { prop: 'address', title: 'Address' },
    { prop: 'district', title: 'District' },
    { prop: 'website', title: 'Website' },
  ];

  return (
    <>
      <section className="inner_banner d-flex align-items-center justify-content-center">
        <div className="container">
          <h1>Stores</h1>
        </div>
      </section>

      <section className="stores __web-inspector-hide-shortcut__">
        <div className="container-new">
          <div className="table-container table-responsive"></div>

          {societies.length > 0 ? (
            <DatatableWrapper
              body={societies}
              headers={header}
              paginationOptionsProps={{
                initialState: { rowsPerPage: 20, options: [5, 10, 15, 20] },
              }}
            >
              {/* <PaginationOptions /> */}
              <table className="table">
                <TableHeader />
                <TableBody />
              </table>
              <Pagination />
            </DatatableWrapper>
          ) : (
            <p>No societies available.</p>
          )}
        </div>
      </section>
    </>
  );
};

export default Stores;
