import React from "react";
const Home =()=>{
   
    return (<>
    
    <div className="banner-wrapper position-relative">
        <div
          className="d-flex justify-content-between align-items-start banner-container"
        >
          <div className="banner-image-wrapper">
            <img src="./assets/images/home/banner_img_one.jpg" alt="" />
          </div>
          <div className="banner-image-wrapper">
            <img src="./assets/images/home/banner_img_two.jpg" alt="" />
          </div>
        </div>
        <div
          className="d-flex justify-content-between align-items-start banner-container"
        >
          <div className="banner-image-wrapper">
            <img src="./assets/images/home/banner_img_three.jpg" alt="" />
          </div>
          <div className="banner-image-wrapper">
            <img src="./assets/images/home/banner_img_four.jpg" alt="" />
          </div>
        </div>
        <div className="banner-badge-wrapper">
          <div className="position-relative">
            <div className="banner-badge-image">
              <img src="./assets/images/home/banner_badge.png" alt="" />
            </div>
            <p className="rooted">rooted</p>
            <p className="tradition">in tradition</p>
            <p className="celebrate">
              Celebrate the handloom traditions of India. Explore a range of
              authentic handwoven products featuring indigenous weaves by
              artisans from across the country.
            </p>
          </div>
        </div>
      </div>
      <section className="border-0">
    <div className="home-sec-1">
      <div className="container py-5 d-flex align-items-center justify-content-center flex-column">
        <div className="row">
          <div className="col-lg-4 box-l">
            <div className="box box-1">
              <h3>Kerala Kaithari Mark</h3>
              <p className="home-text mb-0">
                The Directorate of Handloom and Textiles, under the Ministry of Industries and Commerce, Government of
                Kerala,
                is proud to introduce the "Kerala Kaithari Mark," an emblematic identity for Kerala handloom. This
                initiative is
                rooted in our commitment to preserving and promoting the rich legacy of Kerala's handloom industry,
                which has
                been an integral part of our cultural heritage for centuries.
              </p>
            </div>
          </div>
          <div className="col-lg-8 box-r">
            <div className="box box-2">
              <h3>Preserving Tradition, Empowering Artisans</h3>
              <p className="home-text">Kerala has a rich tradition of handloom weaving, with each region in the state having
                its
                distinct style, patterns, and techniques. The introduction of the Kerala Kaithari Mark is a significant
                step
                towards protecting this heritage from the threat of imitation and mass-produced goods. It assures
                consumers that
                the products they purchase are genuine, handcrafted items, made with meticulous attention to detail by
                skilled
                artisans.</p>
              <p className="home-text mb-0">Our weavers are the custodians of this age-old craft, and through Kerala
                Kaithari Mark,
                we aim to empower them by ensuring that their work is recognized and valued. This initiative also
                focuses on
                providing weavers with better access to resources, training, and market opportunities, thereby improving
                their
                livelihoods and sustaining the industry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="home-img-wrapper position-relative">
      <img src="./assets/images/home/home_img.jpg" alt="" />
      <div className="content-abs">
        <h3>Quality and Authenticity</h3>
        <p className="mb-0">The Kerala Kaithari Mark is more than just a label; it is a symbol of quality and authenticity.
          Products that bear this mark are guaranteed to be crafted using traditional methods, with natural fibers, and
          embodying the true essence of Kerala's handloom tradition. The mark serves as a certification that the
          handloom products are not only aesthetically superior but also environmentally sustainable, as they are
          produced using eco-friendly materials and practices.</p>
      </div>
    </div>

    <div className="about_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-blk">
            <div className="img-box">
              <img src="./assets/images/home/about-the-portal-img-three-new.jpg" alt="" className="w-100"/>
            </div>
          </div>
          <div className="col-md-4 col-blk">
            <div className="img-box">
              <img src="./assets/images/home/about-the-portal-img-one-new.jpg" alt="" className="w-100"/>
            </div>
          </div>
          <div className="col-md-4 col-blk">
            <div className="img-box">
              <img src="./assets/images/home/about-the-portal-img-two-new.jpg" alt="" className="w-100"/>
            </div>
          </div>
        </div>
        <div className="mision-wrap">
        <div className="row">
          <div className="col-lg-8">
            <div className="content-blk mission">
              <h3>Our Mission</h3>
              <p className="mb-0">
                Kerala Kaithari Mark aims to safeguard the authenticity and uniqueness of Kerala handloom products. By
                establishing this identity, we strive to provide our weavers with a platform that not only honors their
                craftsmanship but also enhances the visibility of their work in national and international markets. The
                mark stands as a testament to the quality, tradition, and excellence that Kerala handloom represents.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="content-blk vision">
              <h3>Our Vision for the Future</h3>
              <p className="mb-0">
                As we look towards the future, our vision is to see Kerala handloom recognized globally for its
                unparalleled craftsmanship and heritage. The Kerala Kaithari Mark will serve as a beacon </p>
            </div>
          </div>
        </div>
        </div>

        <div className="row">
          <div className="col-md-4 col-blk">
            <div className="img-box">
              <img src="./assets/images/home/about-the-portal-img-four-new.jpg" alt="" className="w-100"/>
            </div>
          </div>
          <div className="col-md-4 col-blk">
            <div className="img-box">
              <img src="./assets/images/home/about-the-portal-img-five-new.jpg" alt="" className="w-100"/>
            </div>
          </div>
          <div className="col-md-4 col-blk">
            <div className="img-box">
              <img src="./assets/images/home/about-the-portal-img-six-new.jpg" alt="" className="w-100"/>
            </div>
          </div>
        </div>
        <div className="col-12 p-0">
          <div className="bottom-content">
            <h3>A Commitment to Sustainable Development </h3>
            <p className="mb-0">In line with our vision for sustainable development, the Kerala Kaithari Mark
              promotes eco-conscious practices within the handloom sector. The use of natural dyes, organic fibers, and
              traditional weaving techniques ensures that the production process has a minimal impact on the
              environment. This initiative also aligns with the broader goals of the Government of Kerala to promote
              sustainable and inclusive economic growth.</p>
          </div>
        </div>

      </div>
    </div>
  </section>
    </>);
}
export default Home;
