import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const inputRefs = useRef([]);
  const [show, setShow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpStatus, setOtpStatus] = useState("");
  const [setPassword, setPasswordStatus] = useState(true);
  const [newPswdError, setNewPswdError] = useState("");
  const [newPswdSet, setnewPswdSet] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", ""]);
  const [phoneError, setPhoneError] = useState("");
  const [otpErrors, setOtpErrors] = useState(["", "", "", "", ""]);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordMismatchError, setPasswordMismatchError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const redirect = useNavigate();
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values
    setPhoneNumber(value.replace(/[^0-9]/g, ""));
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);


    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleSubmitPhoneNumber = async () => {
    // Validate phone number
    if (phoneNumber.trim() === "") {
      setPhoneError("Phone number is required.");
      return;
    }
    if (!/^\d{10}$/.test(phoneNumber)) {
      setPhoneError("Phone number must be 10 digits.");
      return;
    }

    try {
      fetch(`${apiUrl}send_otp`, {
        method: "POST",
        body: JSON.stringify({ phoneNumber }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((jsonResponse) => {
          if (jsonResponse.status) {
            handleShow(); // Show OTP modal if phone number is valid
            setPhoneError(""); // Clear phone number errors
          } else {
            setPhoneError(jsonResponse.data);
          }
        });
    } catch (error) {
      console.error("Error validating phone number:", error);
      setPhoneError("An error occurred. Please try again.");
    }
  };

  const handleSubmitOtp = () => {
    // Basic validation: Ensure all OTP fields are filled
    const newOtpErrors = otp.map((value) =>
      value.trim() === "" ? "This field is required" : ""
    );
    setOtpErrors(newOtpErrors);
    const otp_value = parseInt(otp.join(""));
    if (newOtpErrors.every((error) => error === "")) {
      try {
        fetch(`${apiUrl}check_otp`, {
          method: "POST",
          body: JSON.stringify({ phoneNumber, otp: otp_value }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((jsonResponse) => {
            if (jsonResponse.status) {
              setOtp(["", "", "", "", ""]);
              handleClose();
              setPasswordStatus(false);
            } else {
              setOtpStatus(jsonResponse.data);
            }
          });
      } catch (error) {
        handleClose(); // Close the modal on successful submission
        console.error("Error validating phone number:", error);
        setPhoneError("An error occurred. Please try again.");
      }
    }
  };

  const handleResetPassword = async () => {
    // Validate passwords
    if (newPassword !== confirmNewPassword) {
      setPasswordMismatchError("Passwords do not match.");
      return;
    }

    try {
      fetch(`${apiUrl}auth/change-password`, {
        method: "POST",
        body: JSON.stringify({ phoneNumber, newPassword }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((jsonResponse) => {
          if (jsonResponse.status) {
            setNewPswdError('');
            setPasswordMismatchError('');
            setPhoneError(""); // Clear phone number errors
            setConfirmNewPassword("");
            setNewPassword("");
            setPasswordStatus(true);
            setPhoneNumber('');
            setnewPswdSet(jsonResponse.message);
            redirect('/login');
          } else {
            setNewPswdError(jsonResponse.message);
          }
        });
    } catch (error) {
      console.error("Error validating phone number:", error);
      setNewPswdError("An error occurred. Please try again.");
    }
  };

  const handleKeyUp = (e, index) => {
    // Handle backspace to move to the previous input
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      inputRefs.current[index - 1].focus();
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Enter OTP</Modal.Title>
          <span aria-hidden="true" onClick={handleClose}>
            <i className="bi bi-x-lg"></i>Close
          </span>
        </Modal.Header>
        <Modal.Body>
          {otpStatus && (
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
              {otpStatus}
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  onClick={() => setOtpStatus(null)}
                >
                  &times;
                </span>
              </button>
            </div>
          )}
          <div className="row m-2">
            <div className="form-group mb-2 mb-md-4 enter_otp px-3">
              <div className="row">
                <ul className="otp_ul list-unstyled d-flex justify-content-between">
                  {otp.map((value, index) => (
                    <li key={index} className="otp_list_item">
                      <input
                        type="text"
                        className={`form-control input_style ${
                          otpErrors[index] ? "is-invalid" : ""
                        }`}
                        value={value}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        onKeyUp={(e) => handleKeyUp(e, index)}
                        maxLength={1}
                        ref={(el) => (inputRefs.current[index] = el)} // Store reference to each input
                      />
                      {/* {otpErrors[index] && (
                        <div className="invalid-feedback">
                          {otpErrors[index]}
                        </div>
                      )} */}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmitOtp}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {setPassword ? (
        <section className="pt-2 pb-2 pt-md-5 pb-md-4">
          <div className="form_main_560 reset_pwd">
            <div className="text-center pb-2 pb-md-4">
              <h2>Reset Your Password</h2>
            </div>
            {newPswdSet && (
              <div className="alert alert-success alert-dismissible fade show" role="alert">
                {newPswdSet}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span
                    aria-hidden="true"
                    onClick={() => setnewPswdSet(null)}
                  >
                    &times;
                  </span>
                </button>
              </div>
            )}
            <ul className="form_ul list-unstyled">
              <li>
                <label htmlFor="inputPhoneNumber">Phone Number</label>
                <div className="input-group mb-2 mb-md-4">
                  <div className="input-group-prepend">
                    <div className="input-group-text">+91</div>
                  </div>
                  <input
                    type="text"
                    className={`form-control input_style ${phoneError ? "is-invalid" : ""}`}
                    id="inputPhoneNumber"
                    placeholder="Enter Phone Number"
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                  />
                  {phoneError && (
                    <div className="invalid-feedback">{phoneError}</div>
                  )}
                </div>
              </li>

              <li className="mb-2 mb-md-4">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={handleSubmitPhoneNumber}
                  >
                    SUBMIT
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </section>
      ) : (
        <section className="pt-2 pb-2 pt-md-5 pb-md-4">
          <div className="form_main_560">
            <div className="text-center pb-2 pb-md-4">
              <h2>Set Password</h2>
            </div>
            {newPswdError && (
              <div className="alert alert-danger alert-dismissible fade show" role="alert">
                {newPswdError}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span
                    aria-hidden="true"
                    onClick={() => setNewPswdError(null)}
                  >
                    &times;
                  </span>
                </button>
              </div>
            )}
            {passwordMismatchError &&  !newPswdError && (
              <div className="alert alert-danger alert-dismissible fade show" role="alert">
                {passwordMismatchError}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span
                    aria-hidden="true"
                    onClick={() => setPasswordMismatchError(null)}
                  >
                    &times;
                  </span>
                </button>
              </div>
            )}
            <ul className="form_ul">
              <li>
                <div className="form-group mb-2 mb-md-4">
                  <label htmlFor="inputPassword4">Set Password</label>
                  <div className="position-relative">
                    <input
                      type="password"
                      className={`form-control input_style ${passwordMismatchError ? "is-invalid" : ""}`}
                      id="inputPassword4"
                      placeholder="Enter Password"
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                        setPasswordMismatchError(newPassword !== confirmNewPassword ? "Passwords do not match." : "");
                      }}
                      value={newPassword}
                    />
                  </div>
                </div>
              </li>
              <li>
                <div className="form-group mb-2 mb-md-4">
                  <label htmlFor="inputConfirmPassword">Re-enter Password</label>
                  <div className="position-relative">
                    <input
                      type="password"
                      className={`form-control input_style ${passwordMismatchError ? "is-invalid" : ""}`}
                      id="inputConfirmPassword"
                      placeholder="Re-enter Password"
                      onChange={(e) => {
                        setConfirmNewPassword(e.target.value);
                        setPasswordMismatchError(newPassword !== e.target.value ? "Passwords do not match." : "");
                      }}
                      value={confirmNewPassword}
                    />
                  </div>
                </div>
              </li>

              <li className="mb-2 mb-md-4">
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    onClick={handleResetPassword}
                  >
                    SUBMIT
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </section>
      )}
    </>
  );
};

export default ForgotPassword;
