import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Routing from './routing/Routing';
import AdminDashboard from './components/administrator/Dashboard';
import AdminLogin from './components/administrator/AdminLogin';
import AdminForgotPassword from './components/administrator/AdminForgotPassword';

function App() {
  const [accessToken, setAccessToken] = useState('');

  return (
    <Router>
      <Routes>
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/login" element={<AdminLogin />} />        
        <Route path="/admin/forgot-password" element={<AdminForgotPassword />} />
        {/* <Route path="/admin/login" element={<AdminDashboard />} /> */}
        <Route
          path="*"
          element={
            <>
              <Header accessToken={accessToken} setAccessToken={setAccessToken} />
              <Routing accessToken={accessToken} setAccessToken={setAccessToken} />
              <Footer />
            </>
          }
        />
      </Routes>
      
    </Router>
  );
}

export default App;


