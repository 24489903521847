import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import html2canvas from "html2canvas";

const ProductDetailsModal = ({ show, handleClose, prods,handleAccept ,handleReject}) => {
  const { code: product_code,id:prod_id } = { ...prods }; // Ensure `product` is defined
  const [data, setData] = useState({});
  const [product, setProdData] = useState({});
  const [society, setSocityData] = useState({});
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}product/get/${product_code}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          console.error("Error fetching data");
        }

        const jsonData = await response.json();

        if (!jsonData.status) {
          console.error("Error fetching data");
        }

        setData(jsonData.data || {}); // Use default empty object if `jsonData.data` is undefined
        setProdData(jsonData.data.product || {}); // Use default empty object if `jsonData.data` is undefined
        setSocityData(jsonData.data.society || {}); // Use default empty object if `jsonData.data` is undefined
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (product_code) {
      fetchData(); // Only fetch data if id is defined
    }
  }, [product_code]); // Removed `navigate` as it's not defined

  // Destructure with different names to avoid conflict with the `product` prop

  // useEffect(() => {
  //   setCurrentImage(
  //       data?.product?.image && data.product.image.length > 0
  //       ? `${apiUrl}${data.product.image[0].path}`
  //       : "/assets/images/big-img.jpg"
  //   );
  // }, [ apiUrl,data]);

  const images = data?.product?.image || [];
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);

  // Handle previous image navigation
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Handle next image navigation
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Handle touch start
  const handleTouchStart = (e) => {
    touchStartX.current = e.changedTouches[0].clientX;
  };

  // Handle touch move
  const handleTouchMove = (e) => {
    touchEndX.current = e.changedTouches[0].clientX;
  };

  // Handle touch end and determine swipe direction
  const handleTouchEnd = () => {
    if (touchStartX.current - touchEndX.current > 50) {
      handleNext(); // Swipe left to go to the next image
    }
    if (touchEndX.current - touchStartX.current > 50) {
      handlePrev(); // Swipe right to go to the previous image
    }
  };

  const handlePrint = async () => {
    // Wait for modal content to be fully rendered
    setTimeout(async () => {
      const modalContent = document.querySelector(".product-container");

      if (modalContent) {
        try {
          // Hide video tags before capturing (if needed)
          const videos = modalContent.querySelectorAll("video");
          videos.forEach((video) => (video.style.display = "none"));

          // Capture the content of the modal with html2canvas
          const canvas = await html2canvas(modalContent, {
            useCORS: true, // Enable cross-origin handling
            allowTaint: false, // Prevent tainting of the canvas
            logging: true, // Enable logging for debugging purposes
          });

          const imgData = canvas.toDataURL("image/png");

          // Restore video tags after capturing
          videos.forEach((video) => (video.style.display = ""));

          // Create a new window and print the image
          const printWindow = window.open("", "", "height=800,width=800");
          printWindow.document.write(
            "<html><head><title>Print</title></head><body>"
          );
          printWindow.document.write(
            `<img src="${imgData}" style="width: 100%;"/>`
          );
          printWindow.document.write("</body></html>");
          printWindow.document.close();

          setTimeout(() => {
            printWindow.focus();
            printWindow.print();
          }, 100);
        } catch (error) {
          console.error("Error capturing or printing modal content:", error);
        }
      }
    }, 100); // Adjust the delay as needed
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      {/* <Modal.Header >
        <Modal.Title>Product Details</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        {product ? (
          <>
            <div className="verified-bar">
              {product.status ==1 ? (
                <>
                  <p>Verified</p>
                  <span>
                    <img src="/assets/images/verify.svg" alt="Verified" />
                  </span>
                </>
              ) : null}
            </div>
            <section className="product-container">
              <div className="">
                <div className="product-detail-contianer">
                  <h3>Product Details</h3>
                  <div className="product-detail-wrapper">
                    <div className="row">
                      {/* <div className="col-lg-7 col-md-12 col-12">
                        <div className="row">
                          <div className="col-md-3 ">
                            <div className="image-thumb-container">
                              {product.image &&
                                product.image.length > 0 &&
                                product.image.map((image, index) => (
                                  <div key={index} className="thumb-wrapper">
                                    <img
                                      src={`${apiUrl}${image.path}`} // Assuming `path` is the URL or relative path to the image
                                      alt={image.originalname}
                                      className="img-fluid"
                                      onClick={() =>
                                        setCurrentImage(
                                          `${apiUrl}${image.path}`
                                        )
                                      }
                                    />
                                  </div>
                                ))}
                            </div>
                          </div>

                          <div className="col-md-9 mob-none">
                            <div className="image-container">
                              <img
                                className="img-fluid"
                                src={currentImage}
                                alt="Big Image"
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {images.length > 0 ? (
                        <div className="col-lg-7 col-md-12 col-12 d-print-none">
                          <div className="row">
                            <div className="col-md-3 col-3">
                              <div className="image-thumb-container">
                                {images.map((image, index) => (
                                  <div key={index} className="thumb-wrapper">
                                    <img
                                      src={`${apiUrl}${image.path}`}
                                      alt={image.originalname}
                                      className="img-fluid"
                                      onClick={() => setCurrentIndex(index)}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="col-md-9 col-9">
                              <div
                                className="image-slider"
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                              >
                                <button
                                  className="nav-button left"
                                  onClick={handlePrev}
                                >
                                  &#9001;
                                </button>
                                <img
                                  className="img-fluid"
                                  src={`${apiUrl}${images[currentIndex].path}`}
                                  alt="Big Image"
                                />
                                <button
                                  className="nav-button right"
                                  onClick={handleNext}
                                >
                                  &#9002;
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="col-md-12 col-lg-5 col-12">
                        <div className="details-container">
                          <h6>{product.product_name}</h6>
                          <ul>
                            <li>
                              <span>Product Code:</span> {product.product_code}
                            </li>
                            <li>
                              <span>Count of WARP:</span> {product.warp}
                            </li>
                            <li>
                              <span>Count of WEFT:</span> {product.weft}
                            </li>
                            <li>
                              <span>EPI (3 Digit Number):</span> {product.epi}
                            </li>
                            <li>
                              <span>Length:</span> {product.length}
                            </li>
                            <li>
                              <span>PPI (3 Digit Number):</span> {product.ppi}
                            </li>
                            <li>
                              <span>Width:</span> {product.width}
                            </li>
                            <li>
                              <span>Structure of Weaving:</span>{product.structure}
                            </li>
                            <li>
                              <span>Varient:</span>{product.product_varient}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="product-detail-contianer">
                  <h3>Weaver Details</h3>
                  <div className="mt-3 mt-md-4">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <p>
                          <span>Weaver Name:</span> {product.weaver}
                        </p>
                      </div>
                      <div className="col-12 col-md-6">
                        <p>
                          <span>Weaver Designation:</span>{" "}
                          {product.weaver_position}
                        </p>
                      </div>
                    </div>
                  </div>

                  {product.video && product.video.length > 0 && (
                    <div className="video-wrapper position-relative ">
                      <video
                        controls
                        src={`${apiUrl}${product.video[0].path}`} // Assuming `path` is the URL or relative path to the video
                        className="video-fluid"
                      >
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-4">
                <div className="product-detail-contianer">
                  <h3>Organization/Society Details</h3>
                  <h6>{society.name}</h6>
                  <p>Organisation ID: {society.society_number}</p>
                  <ul>
                    <li>
                      <span>Phone Number:</span> {society.phone}
                    </li>
                    <li>
                      <span>Reg Number:</span> {society.register_number}
                    </li>
                    <li>
                      <span>Taluk:</span> {society.taluk_name}
                    </li>
                    <li>
                      <span>District:</span> {society.district_name}
                    </li>
                    <li>
                      <span>Village:</span> {society.village_name}
                    </li>
                    <li>
                      <span>Name of the Concerned Person:</span>
                      {society.contact_person}
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </>
        ) : (
          <p>No product details available</p>
        )}
      </Modal.Body>
      <Modal.Footer>
      {handleAccept && product.status !=1 && (
        <Button variant="primary" onClick={()=>{handleClose(false);handleAccept(prod_id)}}>
          Accept
        </Button>)}
        {handleReject && product.status !=1 && (
        <Button variant="danger" onClick={()=>{handleClose(false);handleReject(prod_id)}}>
          Reject
        </Button>)}
        <Button variant="secondary" onClick={handlePrint}>
          Print
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductDetailsModal;
