import React, { useState, useCallback, useEffect } from "react";
import { Form, Modal, Row, Col, Button } from 'react-bootstrap';
import data from "../../data.json";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";

const EditProductModal = ({ show, handleClose, product, fetchData }) => {
  const navigate = useNavigate();

  // Check if product is not null or undefined before destructuring
  const { product_code } = product || {}; // Use optional chaining to avoid destructuring error

  const [formDetails, setFormDetails] = useState({});
  const [errors, setErrors] = useState({});
  const authToken = localStorage.getItem("admin-token");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const redirect = useNavigate();

  useEffect(() => {
    if (!product_code) return; // Check if product_code exists before fetching data

    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}admin/edit/${product_code}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `jwt ${authToken}`,
          },
        });

        if (!response.ok) {
          if (response.statusText === "Unauthorized") {
            return;
          } else {
            throw new Error("Network response was not ok");
          }
        }

        const jsonData = await response.json();
        setFormDetails(jsonData.data.product);

        // Setup video file for preview
        const videoData = jsonData.data.product.video;
        if (videoData && videoData[0]?.path) {
          const videoFile = {
            ...videoData,
            preview: `${apiUrl}${videoData[0]?.path}`, // Construct the preview URL from your API or static file path
          };
          setVideoFile(videoFile);
        }

        // Setup image files for preview
        const imageData = jsonData.data.product.image;
        if (imageData && imageData.length > 0) {
          const imageFiles = imageData.map((img) => ({
            ...img,
            id: `${img.filename}-${img.size}`, // Unique identifier
            preview: `${apiUrl}${img.path}`, // Construct the preview URL from your API or static file path
          }));
          setImageFiles(imageFiles);
          setFormDetails((prevDetails) => ({ ...prevDetails, image: imageFiles })); // Update form details with existing images
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
        return;
      }
    };

    fetchData();
  }, [product_code]);


  const validateForm = () => {
    const newErrors = {};
    // Add validation logic for each field
    if (!formDetails.product_name)
      newErrors.product_name = "Product name is required";
    if (!formDetails.weft) newErrors.weft = "Count of WEFT is required";
    if (!formDetails.warp) newErrors.warp = "Count of WARP is required";
    if (!formDetails.epi) {
      newErrors.epi = "EPI is required";
    } else if (isNaN(formDetails.epi) || formDetails.epi <= 0) {
      newErrors.epi = "EPI must be a number";
    } else if (formDetails.epi > 120 || formDetails.epi < 10) {
      newErrors.epi = "EPI must be between 10 and 120.";
    }
    if (!formDetails.length) {
      newErrors.length = "Length is required";
    } else if (isNaN(formDetails.length) || formDetails.length <= 0) {
      newErrors.length = "Length must be a number";
    }
    //  else if (formDetails.length > 110 || formDetails.length < 5) {
    //   newErrors.length = "Length must be between 5 and 110.";
    // }
    if (!formDetails.ppi) {
      newErrors.ppi = "PPI is required";
    } else if (isNaN(formDetails.ppi) || formDetails.ppi <= 0) {
      newErrors.ppi = "PPI must be a number";
    } else if (formDetails.ppi > 120 || formDetails.ppi < 10) {
      newErrors.ppi = "PPI must be between 10 and 120.";
    }
    if (!formDetails.width) {
      newErrors.width = "Width is required";
    } else if (isNaN(formDetails.width) || formDetails.width <= 0) {
      newErrors.width = "Width must be a positive number";
    } 
    // else if (formDetails.width > 110 || formDetails.width < 5) {
    //   newErrors.width = "Width must be between 5 and 110.";
    // }
    if (!formDetails.weaver) newErrors.weaver = "Weaver name is required";
    if (!formDetails.structure)
      newErrors.structure = "Structure of weaving is required";
    if (!formDetails.weaver_position)
      newErrors.weaver_position = "Weaver position is required";
    if (!formDetails.agree) newErrors.agree = "You must agree to the terms";
    if (!formDetails.name) newErrors.name = "Name is required";
    if (formDetails.product_name == "Other" && !formDetails.new_value) newErrors.new_value = "Value is required";
    if (!formDetails.warp_material)
      newErrors.warp_material = "WARP Material is required";
    if (!formDetails.weft_material)
      newErrors.weft_material = "WEFT Material is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formData = new FormData();
    Object.keys(formDetails).forEach((key) => {
      if (key === "image") {
        Array.from(formDetails[key]).forEach((file) => {
          formData.append(key, file);
        });
      } else {
        formData.append(key, formDetails[key]);
      }
    });
    formData.append('deletedImages',deletedImages);
    formData.append('deletedVideo',deletedVideo);
    if (!authToken) {
      redirect("/login");
      return;
    }

    setLoading(true);
    fetch(`${apiUrl}admin/product_edit`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `jwt ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((jsonResponse) => {
        setLoading(false);
        if (jsonResponse.status) {
          setError("");
          setSuccess(jsonResponse.message);
          // setFormDetails({});
          // redirect("/dashboard");
          fetchData();
          handleClose();
        } else {
          setSuccess("");
          setError(jsonResponse.message);
          // if (jsonResponse.message === "Unauthorised User") {
          //   redirect("/login");
          // }
        }
        toast.success('Product updated successfully!');
        // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      });
  };



// For file upload

const [imagefiles, setImageFiles] = useState([]);
const [imageErrorMessage, setImageErrorMessage] = useState("");
const [deletedImages, setdeletedImages] = useState([]);
const handleRemoveImageFile = (fileId,filename) => {
  setImageFiles((prevFiles) =>
    prevFiles.filter((file) => file.id !== fileId)
  );
setdeletedImages([...deletedImages,filename])

};

const onDrop = useCallback(
  (acceptedFiles) => {
    const filteredFiles = acceptedFiles.filter((file) => {
      if (file.size > 2048576) {
        // 1 MB in bytes
        setImageErrorMessage(
          `${file.name} is too large. Maximum file size is 2 MB.`
        );
        return false; // Exclude the file
      }

      // Check if the file is already in the state
      const isFileAlreadyAdded = imagefiles.some(
        (existingFile) =>
          existingFile.name === file.name && existingFile.size === file.size
      );

      if (isFileAlreadyAdded) {
        setImageErrorMessage(`${file.name} is already uploaded.`);
        return false; // Exclude the file
      }

      return true;
    });

    // Check if adding these files will exceed the limit of 5 files
    if (imagefiles.length + filteredFiles.length > 5) {
      setImageErrorMessage("You can only upload a maximum of 5 images.");
      return;
    }

    // Reset the error message
    setImageErrorMessage("");

    // Process and preview the new files
    const newFiles = filteredFiles.map((file) =>
      Object.assign(file, {
        id: `${file.name}-${file.lastModified}`, // Create a unique identifier for each file
        preview: URL.createObjectURL(file), // Create a preview URL for each file
      })
    );

    setImageFiles((prevFiles) => {
      const updatedFiles = [...prevFiles, ...newFiles];
      setFormDetails((prevDetails) => ({ ...prevDetails, image: updatedFiles })); // Update form details
      return updatedFiles;
    });
  },
  [imagefiles]
);

const { getRootProps, getInputProps, isDragActive } = useDropzone({
  onDrop,
  accept: {
    "image/*": [],
  },
  multiple: true,
  // maxFiles:5,
});

const [videofile, setVideoFile] = useState(null);
const [videoErrorMessage, setVideoErrorMessage] = useState("");

const [deletedVideo, setdeletedVideo] = useState(0);
const handleRemoveVideo = () => {
  setVideoFile(null);
  setdeletedVideo(1);
};

const onDropVideo = useCallback(
  (acceptedFiles) => {
    // console.log(formDetails);
    const selectedVideo = acceptedFiles[0];

    // Check if a video file is selected
    if (!selectedVideo) return;

    // Check for file size and type
    if (selectedVideo.size > 10485760) {
      // 10 MB in bytes
      setVideoErrorMessage("Video file size must be less than 10 MB.");
      return;
    }

    // Reset the error message
    setVideoErrorMessage("");

    // Create a preview URL for the selected video file
    const newVideoFile = Object.assign(selectedVideo, {
      preview: URL.createObjectURL(selectedVideo),
    });

    // Update state with the new video file
    setVideoFile(newVideoFile);
    setFormDetails((prevDetails) => ({ ...prevDetails, video: newVideoFile }));
    // console.log(formDetails);
  },
  [videofile]
);

const {
  getRootProps: getVideoRootProps,
  getInputProps: getVideoInputProps,
  isDragActive: isVideoDragActive,
} = useDropzone({
  onDrop: onDropVideo, // Correctly reference the onDrop function
  accept: {
    "video/*": [], // Accept all video types
  },
  multiple: false, // Only allow one video file at a time
});

//end file upload


if (formDetails.product_name && !data.product_name.some(item => item.name === formDetails.product_name)) {
  // If not, add it to the options
  data.product_name.push({ id:  formDetails.product_name, name: formDetails.product_name });
}

  return (
    <>
     <Modal show={show} onHide={handleClose} size='xl'>
     <Modal.Header>
    <Modal.Title>Edit Product</Modal.Title>
  </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
            <Form.Group controlId="productName" className='form-group mb-2 mb-md-2'>
            <Form.Label>Name of Product</Form.Label>
            <Form.Control
              as="select"
              name="product_name"
              tabIndex={1}
              className={`form-control input_style1 ${
                errors.product_name ? "is-invalid" : ""
              }`}
              value={formDetails.product_name || ""}
              onChange={(e) =>{ 
                setFormDetails({
                  ...formDetails,
                  product_name: e.target.value,
                })
              }}
            >
              <option value="">Select Product</option>
              {data.product_name.map((item) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.product_name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="weft" className='form-group mb-2 mb-md-2'>
            <Form.Label>Count of WEFT</Form.Label>
            <Form.Control
              as="select"
              name="weft"
              tabIndex={3}
              className={`form-control input_style1 ${
                errors.weft ? "is-invalid" : ""
              }`}
              value={formDetails.weft || ""}
              onChange={(e) =>
                setFormDetails({
                  ...formDetails,
                  weft: e.target.value,
                })
              }
            >
              <option value="">Count of WEFT</option>
              {data.warp.map((item) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.weft}</Form.Control.Feedback>
          </Form.Group>
              

              <Form.Group controlId="warpMaterial" className='form-group mb-2 mb-md-2'>
                <Form.Label>Type of Material of WARP</Form.Label>
                <Form.Control
                  as="select"
                  name="weft_material"
                  className={`form-control input_style1 ${
                    errors.weft_material ? "is-invalid" : ""
                  }`}
                  value={formDetails.weft_material || ""}
                  tabIndex={5}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      weft_material: e.target.value,
                    })
                  }
                >
                  <option value="">Type of Material of WEFT</option>
                  {data.weft_material.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.warp_material}</Form.Control.Feedback>
              </Form.Group>

             
              <Form.Group controlId="epi" className='form-group mb-2 mb-md-2'>
                <Form.Label>EPI <small>(10 to 120)</small></Form.Label>
                <Form.Control
                  type="text"
                  className={`form-control input_style1 ${
                    errors.epi ? "is-invalid" : ""
                  }`}
                  tabIndex={7}
                  autoComplete="off"
                  value={formDetails.epi || ""}
                  placeholder="Enter EPI"
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      epi: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">{errors.epi}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="length" className='form-group mb-2 mb-md-2'>
                <Form.Label>Length <small>(in cm)</small></Form.Label>
                <Form.Control
                  type="text"
                  className={`form-control input_style1 ${
                    errors.length ? "is-invalid" : ""
                  }`}
                  autoComplete="off"
                  value={formDetails.length || ""}
                  placeholder="Length"
                  tabIndex={9}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      length: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">{errors.length}</Form.Control.Feedback>
              </Form.Group>
          
            <Form.Group controlId="structure" className='form-group mb-2 mb-md-2'>
                <Form.Label>Structure of Weaving</Form.Label>
                <Form.Control
                  as="select"
                  name="structure"
                          tabIndex={11}
                          className={`form-control input_style1 ${
                            errors.structure ? "is-invalid" : ""
                          }`}
                          value={formDetails.structure || ""}
                          onChange={(e) =>
                            setFormDetails({
                              ...formDetails,
                              structure: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Structure of Weaving</option>
                          {data.structure.map((item) => (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.structure}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="product_varient" className='form-group mb-2 mb-md-2'>
                <Form.Label>Product Varient <small>(Colors,Size,etc)</small></Form.Label>
                <Form.Control
                  type="text"
                  className={`form-control input_style1 ${
                    errors.product_varient ? "is-invalid" : ""
                  }`}
                  autoComplete="off"
                  value={formDetails.product_varient || ""}
                  tabIndex={13}
                  placeholder="Product Varient"
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      product_varient: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">{errors.product_varient}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="name" className='form-group mb-2 mb-md-2'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  className={`form-control input_style1 ${
                    errors.name ? "is-invalid" : ""
                  }`}
                  autoComplete="off"
                  value={formDetails.name || ""}
                  placeholder="Name"
                  tabIndex={15}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      name: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>

            </Col>
            <Col md={6}>
            <Form.Group controlId="newValue" className='form-group mb-2 mb-md-2'>
                <Form.Label>Enter Product Name <small>(If the product is not in the list)</small></Form.Label>
                <Form.Control
                  type="text"
                  className={`form-control input_style1 ${
                    errors.new_value ? "is-invalid" : ""
                  }`}
                  autoComplete="off"
                  tabIndex={2}
                  placeholder="Enter Product Name"
                  disabled={formDetails.product_name =="Other"?false:true}
                  value={formDetails.product_name =="Other"? formDetails.new_value : ""}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      new_value: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">{errors.new_value}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="warp" className='form-group mb-2 mb-md-2'>
                <Form.Label>Count of WARP</Form.Label>
                <Form.Control
                  as="select"
                  name="warp"
                  tabIndex={4}
                  className={`form-control input_style1 ${
                    errors.warp ? "is-invalid" : ""
                  }`}
                  value={formDetails.warp || ""}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      warp: e.target.value,
                    })
                  }
                >
                  <option value="">Count of WARP</option>
                  {data.weft.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.warp}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="weftMaterial" className='form-group mb-2 mb-md-2'>
                <Form.Label>Type of Material of WEFT</Form.Label>
                <Form.Control
                  as="select"
                  name="warp_material"
                  className={`form-control input_style1 ${
                    errors.warp ? "is-invalid" : ""
                  }`}
                  value={formDetails.warp_material || ""}
                  tabIndex={6}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      warp_material: e.target.value,
                    })
                  }
                >
                  <option value="">Type of Material of WARP</option>
                  {data.warp_material.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.weft_material}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="ppi" className='form-group mb-2 mb-md-2'>
                <Form.Label>PPI <small>(10 to 120)</small></Form.Label>
                <Form.Control
                  type="text"
                  className={`form-control input_style1 ${
                    errors.ppi ? "is-invalid" : ""
                  }`}
                  autoComplete="off"
                  value={formDetails.ppi || ""}
                  placeholder="PPI"
                  tabIndex={8}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      ppi: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">{errors.ppi}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="width" className='form-group mb-2 mb-md-2'>
                <Form.Label>Width <small>(in cm)</small></Form.Label>
                <Form.Control
                  type="text"
                  className={`form-control input_style1 ${
                    errors.width ? "is-invalid" : ""
                  }`}
                  autoComplete="off"
                  placeholder="Width"
                  tabIndex={10}
                  value={formDetails.width || ""}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      width: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">{errors.width}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="weaver" className='form-group mb-2 mb-md-2'>
                <Form.Label>Weaver Name</Form.Label>
                <Form.Control
                  type="text"
                  className={`form-control input_style1 ${
                    errors.weaver ? "is-invalid" : ""
                  }`}
                  autoComplete="off"
                  tabIndex={12}
                  placeholder="Weaver Name"
                  value={formDetails.weaver || ""}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      weaver: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">{errors.weaver}</Form.Control.Feedback>
              </Form.Group>

             

              <Form.Group controlId="weaverPosition" className='form-group mb-2 mb-md-2'>
                <Form.Label>Weaver Position</Form.Label>
                <Form.Control
                  type="text"
                  className={`form-control input_style1 ${
                    errors.weaver_position ? "is-invalid" : ""
                  }`}
                  autoComplete="off"
                  tabIndex={14}
                  value={formDetails.weaver_position || ""}
                  placeholder="Weaver Position"
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      weaver_position: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">{errors.weaver_position}</Form.Control.Feedback>
              </Form.Group>

             

              </Col>
              <Col  md={12}>
              <div className='file-upload-wrapper p-4 position-relative form-group mb-2 mb-md-2'>
                  {/* Video Upload Dropzone */}
                  <div
                    {...getVideoRootProps()}
                    className={`dropzone d-flex flex-column align-items-center justify-content-center ${
                      isVideoDragActive ? "active" : ""
                    }`}
                  >
                    <div>
                      <img src="./assets/images/upload.png" alt="" />
                    </div>
                    <input {...getVideoInputProps()} name="video" 
                  tabIndex={17}/>
                    {isVideoDragActive ? (
                      <p className="upload-text mt-3">Drop the video here...</p>
                    ) : (
                      <p className="upload-text mt-3">
                        Drag 'n' drop a video here, or click to select a video
                        (Max size 10 MB)
                      </p>
                    )}
                  </div>

                  {/* Error message display for video */}

                  {/* Video Preview Area */}
                  {videofile && (
                    <div style={{ marginTop: "20px", position: "relative" }}>
                      <video
                        src={videofile.preview}
                        controls
                        style={{ width: "300px", borderRadius: "8px" }}
                      ></video>
                      <button
                        onClick={handleRemoveVideo}
                        style={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          backgroundColor: "#ff4d4f",
                          border: "none",
                          color: "#fff",
                          borderRadius: "50%",
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        &times;
                      </button>
                    </div>
                  )}
                </div>
                {videoErrorMessage && (
                  <p style={{ color: "red", marginTop: "10px" }}>
                    {videoErrorMessage}
                  </p>
                )}

              <div className='file-upload-wrapper p-4 position-relative form-group mb-2 mb-md-2'>
                  {/* Dropzone area */}
                  <div
                    {...getRootProps()}
                    className={`dropzone d-flex flex-column align-items-center justify-content-center ${
                      isDragActive ? "active" : ""
                    }`}
                  >
                    <div>
                      <img src="./assets/images/upload.png" alt="" />
                    </div>
                    <input {...getInputProps()} name="image"  tabIndex={18} />
                    {isDragActive ? (
                      <p className="upload-text mt-3">Drop the files here...</p>
                    ) : (
                      <p className="upload-text mt-3">
                        Drag 'n' drop product images here, or click to select
                        files (Max 5 Images)
                      </p>
                    )}
                  </div>

                  <br />
                  {/* Preview area */}
                  <div className="previews" style={{ marginTop: "20px" }}>
                    {imagefiles.map((file) => (
                      <div
                        key={file.id} // Use the unique identifier for the key
                        style={{
                          display: "inline-block",
                          marginRight: "10px",
                          position: "relative", // Position relative for the remove button
                        }}
                      >
                        <div>
                          <img
                            src={file.preview}
                            alt={file.name}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                        </div>
                        <div>
                          <button
                            onClick={() => handleRemoveImageFile(file.id,file.filename)} // Use the unique identifier to remove
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              backgroundColor: "#ff4d4f",
                              border: "none",
                              color: "#fff",
                              borderRadius: "50%",
                              width: "25px",
                              height: "25px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            &times;
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {imageErrorMessage && (
                  <p style={{ color: "red", marginTop: "10px" }}>
                    {imageErrorMessage}
                  </p>
                )}

              <Form.Group controlId="agree" className='form-group mb-2 mb-md-2'>
                <Form.Check
                  // className={`custom-checkbox mr-3 ${
                  //   errors.agree ? "checkbox-invalid" : ""
                  // }`}
                  type="checkbox"
                  name="agree"
                  label="I agree to the terms"
                  checked={formDetails.agree || false}
                  isInvalid={!!errors.agree}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      agree: e.target.checked,
                    })
                  }
                />
                 <Form.Control.Feedback type="invalid">{errors.agree}</Form.Control.Feedback>
              </Form.Group>



              
            </Col>
          </Row>
          <Button variant="primary" type="submit" className='form-group mb-2 mb-md-2' tabIndex={20}>Submit</Button>
        </Form>
      </Modal.Body>
    </Modal>
    </>
  );
};

export default EditProductModal;
