import React, { useState,useEffect } from "react";
import "./Dashboard.css";
// import "./sb-admin-2.css";
import DashboardView from "./DashboardView"
import Society from "./Society"
import AcceptedSociety from "./AcceptedSociety"
import RejectedSociety from "./RejectedSociety"
import Users from "./Users"
import Products from "./Products"
import RejectedProducts from "./RejectedProducts"
import SocietyReport from "./SocietyReport"
import ProductReport from "./ProductReport"
import UserReport from "./UserReport"
import Profile from "./Profile"
import { useNavigate } from 'react-router-dom'; 

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [usertype, setUsertype] = useState('user');
  const authToken = localStorage.getItem("admin-token");
  
  const [prodStatus, setproductStatus] = useState('');
  const [societyStatus, setsocietyStatus] = useState('');

  const redirect = useNavigate();

  const [page, setVewPage] = useState('');
  const [style, setStyle] = useState("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
  
  useEffect(() => {
    if (!authToken) {
      redirect("/admin/login");
      return;
    }else{
      let user = localStorage.getItem('adminDetails');
      user =JSON.parse(user)
      setUsername(user.username.toUpperCase())
      setUsertype(user.type)
    }
  }, [page]);

  useEffect(() => {
    
    import('./sb-admin-2.css').then(module => {
      setLoading(false);
      }).catch(err => {
        // Handle error
      });

  }, []);




  const changeStyle = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };

  const changeStyle1 = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };

  const handleLogout = () => {

    localStorage.removeItem('admin-token');
    localStorage.removeItem('adminDetails');
    redirect('/admin/login');

};

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
    
      <>
        <div id="wrapper">
          <ul className={style} id="accordionSidebar">
            <a
              className="sidebar-brand d-flex align-items-center justify-content-center"
              href="#!"
            >
              <div className="sidebar-brand-text mx-3" style={{color:'#363636'}}> 
                 {/* <img src="/assets/images/logo_main.svg" alt="Kerala Kithari" /> */}
                 Kerala Kaithari
                 </div>
              <div className="text-center d-none d-md-inline">
                <button
                  className="rounded-circle border-0"
                  id="sidebarToggle"
                  onClick={changeStyle}
                ></button>
              </div>
            </a>
            <hr className="sidebar-divider my-0" />

            <li className={`nav-item ${page=='' ? "active" : ""}`}>
              <a className="nav-link" href="#!" onClick={(e)=>setVewPage('')}>
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span>
              </a>
            </li>

            <li className={`nav-item ${page=='society'||page=='accepted_society'||page=='rejected_society' ? "active" : ""}`}>
              <a
                className="nav-link collapsed"
                href="!#"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <i className="fas fa-fw fa-cog"></i>
                <span>Society</span>
              </a>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <a className={`collapse-item ${page=='society' ? "active" : ""}`} href="#!" onClick={(e)=>{setsocietyStatus('');setVewPage('society')}}>
                    Registration
                  </a>
                  <a className={`collapse-item ${page=='accepted_society' ? "active" : ""}`} href="#!" onClick={(e)=>setVewPage('accepted_society')}>
                    Accepted Society
                  </a>
                  <a className={`collapse-item ${page=='rejected_society' ? "active" : ""}`} href="#!" onClick={(e)=>setVewPage('rejected_society')}>
                    Rejected Society
                  </a>
                </div>
              </div>
            </li>
            <li className={`nav-item ${page=='products'||page=='rejected_products' ? "active" : ""}`}>
              <a
                className="nav-link collapsed"
                href="!#"
                data-toggle="collapse"
                data-target="#collapseTwo1"
                aria-expanded="true"
                aria-controls="collapseTwo1"
              >
                <i className="fas fa-fw fa-table"></i>
                <span>Products</span>
              </a>
              <div
                id="collapseTwo1"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <a className={`collapse-item ${page=='products' ? "active" : ""}`} href="#!" onClick={(e)=>{setproductStatus('');setVewPage('products')}}>
                    All Products
                  </a>
                  <a className={`collapse-item ${page=='rejected_products' ? "active" : ""}`} href="#!" onClick={(e)=>setVewPage('rejected_products')}>
                    Rejected Products
                  </a>
                </div>
              </div>
            </li>

            {/* <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapseUtilities"
                aria-expanded="true"
                aria-controls="collapseUtilities"
              >
                <i className="fas fa-fw fa-wrench"></i>
                <span>Users</span>
              </a>
              <div
                id="collapseUtilities"
                className="collapse"
                aria-labelledby="headingUtilities"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Custom Utilities:</h6>
                  <a className="collapse-item" href="utilities-color.html">
                    Colors
                  </a>
                  <a className="collapse-item" href="utilities-border.html">
                    Borders
                  </a>
                  <a className="collapse-item" href="utilities-animation.html">
                    Animations
                  </a>
                  <a className="collapse-item" href="utilities-other.html">
                    Other
                  </a>
                </div>
              </div>
            </li> */}

            {(usertype != 'user')?(
              <li className={`nav-item ${page=='users' ? "active" : ""}`}>
                <a className="nav-link" href="#!" onClick={(e)=>setVewPage('users')}>
                  <i className="fas fa-fw fa-user"></i>
                  <span>Users</span>
                </a>
              </li>

            ):null}
           

            {/* <li className={`nav-item ${page=='products' ? "active" : ""}`}>
              <a className="nav-link" href="#!" onClick={(e)=>{setproductStatus('');setVewPage('products')}}>
                <i className="fas fa-fw fa-table"></i>
                <span>Products</span>
              </a>
            </li> */}


            <li className={`nav-item ${page=='society_report'||page=='product_report'||page=='user_report' ? "active" : ""}`}>
              <a
                className="nav-link collapsed"
                href="#!"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                <i className="fas fa-fw fa-list"></i>
                <span>Reports</span>
              </a>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <a className={`collapse-item ${page=='society_report' ? "active" : ""}`} href="#!" onClick={(e)=>setVewPage('society_report')}>
                    Society Report
                  </a>
                  <a className={`collapse-item ${page=='product_report' ? "active" : ""}`} href="#!" onClick={(e)=>setVewPage('product_report')}>
                    Product Report
                  </a>
                  <a className={`collapse-item ${page=='user_report' ? "active" : ""}`} href="#!" onClick={(e)=>setVewPage('user_report')}>
                    User Report
                  </a>
                </div>
              </div>
            </li>
          </ul>
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                <button
                  id="sidebarToggleTop"
                  className="btn btn-link d-md-none rounded-circle mr-3"
                  onClick={changeStyle1}
                >
                  <i className="fa fa-bars"></i>
                </button>

                <ul className="navbar-nav ml-auto">
                  <div className="topbar-divider d-none d-sm-block"></div>

                  <li className="nav-item dropdown no-arrow">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#!"
                      id="userDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                       <b>{username}</b>
                      </span>
                      <img
                        className="img-profile rounded-circle"
                        src="/assets/images/undraw_profile.svg"
                      />
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                      aria-labelledby="userDropdown"
                    >
                      <a className="dropdown-item" href="#" onClick={(e)=>setVewPage('profile')}>
                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                        Profile
                      </a>
                      {/* <a className="dropdown-item" href="#">
                        <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                        Settings
                      </a> */}

                      <div className="dropdown-divider"></div>
                      <a
                        className="dropdown-item"
                        href="#!"
                        data-toggle="modal"
                        data-target="#logoutModal"
                      >
                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                        Logout
                      </a>
                    </div>
                  </li>
                </ul>
              </nav>
             
              <div className="container-fluid">

              {(() => {
                  switch (page) {
                    case 'society':
                      return <Society  status={societyStatus}/>
                    case 'accepted_society':
                      return <AcceptedSociety/>
                    case 'rejected_society':
                      return <RejectedSociety/>
                    case 'products':
                      return <Products status={prodStatus}/>
                    case 'rejected_products':
                      return <RejectedProducts/>
                    case 'users':
                      return <Users/>
                    case 'society_report':
                      return <SocietyReport/>
                    case 'product_report':
                      return <ProductReport/>
                    case 'user_report':
                      return <UserReport/>
                    case 'profile':
                      return <Profile/>
                    default:
                      return <DashboardView redirectViewPage={setVewPage} productStatus={setproductStatus} societyStatus={setsocietyStatus} />
                  }
                })()}

                
              </div>
            </div>
            <footer className="sticky-footer bg-white">
              <div className="container my-auto">
                <div className="copyright text-center my-auto">
                  <span>Copyright &copy; Kerala Kaithari 2024</span>
                </div>
              </div>
            </footer>
          </div>
        </div>
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>

        <div
          className="modal fade"
          id="logoutModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Ready to Leave?
                </h5>
                <button
                  className="close"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body"><br/>
                Select "Logout" below if you are ready to end your current
                session.<br/><br/>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <a className="btn btn-primary" type="button" data-dismiss="modal" onClick={handleLogout} style={{color: 'white'}} > Logout</a>
              </div>
            </div>
          </div>
        </div>
     
    </> 
    )}
    </>
  );
}

export default Dashboard;
