import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Spinner, Row, Col } from 'react-bootstrap';
import  { toast } from 'react-hot-toast';

const SocietyModal = ({ show, handleClose, updateList }) => {
  const [districts, setDistricts] = useState([]);
  const [taluks, setTaluks] = useState([]);
  const [villages, setVillages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    website: '',
    register_number: '',
    district: '',
    taluk: '',
    village: '',
    contact_person: '',
    second_phone: '',
    address: '',
    password: '',
    reenter_password: '',
  });
  const [district, setSelectedDistricts] = useState("");
  const [taluk, setSelectedTaluks] = useState("");
  const [village, setSelectedVillages] = useState("");
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await fetch(`${apiUrl}district`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setDistricts(data.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchDistricts();
  }, []);

  useEffect(() => {
    const fetchTaluk = async () => {
      if (!district) return;
      try {
        const response = await fetch(`${apiUrl}taluk/${district}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setTaluks(data.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchTaluk();
  }, [district]);

  useEffect(() => {
    const fetchVillage = async () => {
      if (!taluk) return;
      try {
        const response = await fetch(`${apiUrl}village/${taluk}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setVillages(data.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchVillage();
  }, [taluk]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'district') {
      setSelectedDistricts(value);
      setSelectedVillages("");
    }
    if (name === 'village') {
      setSelectedVillages(value);
    }
    if (name === 'taluk') {
      setSelectedTaluks(value);
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    const phoneRegex = /^\d{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

    if (!formData.name.trim()) newErrors.name = "Organization/Society Name is required.";
    if (!formData.phone || !phoneRegex.test(formData.phone)) newErrors.phone = "Invalid phone number.";
    if (!formData.email || !emailRegex.test(formData.email)) newErrors.email = "Invalid email address.";
    if (!formData.register_number.trim()) newErrors.register_number = "Registration Number is required.";
    if (!formData.district) newErrors.district = "District is required.";
    if (!formData.taluk) newErrors.taluk = "Taluk is required.";
    if (!formData.village) newErrors.village = "Village is required.";
    if (!formData.contact_person.trim()) newErrors.contact_person = "Name of the Concerned Person is required.";
    if (!formData.address.trim()) newErrors.address = "Address is required.";
    if (!formData.password) newErrors.password = "Password is required.";
    if (!formData.reenter_password) newErrors.reenter_password = "Re-enter Password is required.";
    if (formData.password !== formData.reenter_password) newErrors.reenter_password = "Passwords do not match.";
    if (formData.second_phone && !phoneRegex.test(formData.second_phone)) newErrors.second_phone = "Alternative Phone Number is invalid.";
    if (formData.website && formData.website.trim() && !urlRegex.test(formData.website.trim())) {
      newErrors.website = 'Invalid website URL format';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await fetch(`${apiUrl}auth/society-signup`, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        toast.success('Society added successfully!');
        handleClose();
        setFormData({
          name: '',
          phone: '',
          email: '',
          website: '',
          register_number: '',
          district: '',
          taluk: '',
          village: '',
          contact_person: '',
          second_phone: '',
          address: '',
          password: '',
          reenter_password: '',
        });
        setErrors({});
        setError(null);
        updateList();
      } else {
        const errorResponse = await response.json();
        setError(errorResponse.message);
        setTimeout(() => setError(null), 5000);
      }
    } catch (error) {
      console.error('Error adding society:', error);
      setError('Failed to add society.');
      setTimeout(() => setError(null), 5000);
    }
  };

  return (
    <Form >
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>Add New Society</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        {loading ? (
          <Spinner animation="border" />
        ) : (
          <>            
          <h5 className="mb-3">Organization Details</h5>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formName">
                  <Form.Label>Society Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter society name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                    tabIndex={1}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formRegisterNumber" className="mt-3">
                  <Form.Label>Registration Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter registration number"
                    name="register_number"
                    value={formData.register_number}
                    onChange={handleChange}
                    isInvalid={!!errors.register_number}
                    tabIndex={3}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.register_number}
                  </Form.Control.Feedback>
                </Form.Group>
               
                <Form.Group controlId="formDistrict" className="mt-3">
                  <Form.Label>District</Form.Label>
                  <Form.Control
                    as="select"
                    name="district"
                    value={formData.district}
                    onChange={handleChange}
                    isInvalid={!!errors.district}
                    tabIndex={5}
                  >
                    <option value="">Select District</option>
                    {districts.map(district => (
                      <option key={district.id} value={district.id}>{district.district}</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.district}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="formPhone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter phone number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    isInvalid={!!errors.phone}
                    maxLength={10}
                    tabIndex={2}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formEmail" className="mt-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email address"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                    tabIndex={4}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formTaluk" className="mt-3">
                  <Form.Label>Taluk</Form.Label>
                  <Form.Control
                    as="select"
                    name="taluk"
                    value={formData.taluk}
                    onChange={handleChange}
                    isInvalid={!!errors.taluk}
                    disabled={!district}
                    tabIndex={6}
                  >
                    <option value="">Select Taluk</option>
                    {taluks.map(taluk => (
                      <option key={taluk.id} value={taluk.id}>{taluk.taluk}</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.taluk}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            {/* </Row>

            <Row className="mt-3"> */}
              <Col md={6}>
                <Form.Group controlId="formVillage">
                  <Form.Label>Village</Form.Label>
                  <Form.Control
                    as="select"
                    name="village"
                    value={formData.village}
                    onChange={handleChange}
                    isInvalid={!!errors.village}
                    disabled={!taluk}
                    tabIndex={7}
                  >
                    <option value="">Select Village</option>
                    {villages.map(village => (
                      <option key={village.id} value={village.id}>{village.village}</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.village}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              
              <Col md={6}>
                <Form.Group controlId="formContactPerson">
                  <Form.Label>Concerned Person</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter concerned person name"
                    name="contact_person"
                    value={formData.contact_person}
                    onChange={handleChange}
                    isInvalid={!!errors.contact_person}
                    tabIndex={8}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.contact_person}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
              <Form.Group controlId="formContactPerson">
                  <Form.Label>Website URL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Website URL"
                    name="website"
                    value={formData.website}
                    onChange={handleChange}
                    isInvalid={!!errors.website}
                    tabIndex={9}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.website}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formSecondPhone">
                  <Form.Label>Alternative Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter alternative phone number"
                    name="second_phone"
                    value={formData.second_phone}
                    onChange={handleChange}
                    maxLength={10}
                    isInvalid={!!errors.second_phone}
                    tabIndex={10}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.second_phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {/* <h5 className="mt-4">Contact Information</h5> */}
            <Row className="mt-3">
              

              <Col md={12}>
                <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    placeholder="Enter address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    isInvalid={!!errors.address}
                    tabIndex={11}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <h5 className="mt-4">Security</h5>
            <Row className="mt-3">
              <Col md={6}>
                <Form.Group controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                    tabIndex={12}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="formReenterPassword">
                  <Form.Label>Re-enter Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Re-enter password"
                    name="reenter_password"
                    value={formData.reenter_password}
                    onChange={handleChange}
                    isInvalid={!!errors.reenter_password}
                    tabIndex={13}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.reenter_password}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            </>

          
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" type="button" className="me-2" onClick={onSubmit}>
          Add Society
        </Button>
      </Modal.Footer>
    </Modal>
    </Form>
  );
};

export default SocietyModal;
