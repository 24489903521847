import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { toast } from "react-toastify";
import ExportButtons from "./ExportButtons";

const SocietyReport = () => {
  const authToken = localStorage.getItem("admin-token");
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [products, setProductsData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]); // Store filtered data
  const [districts, setDistricts] = useState([]); // Store unique districts
  const [taluks, setTaluks] = useState([]); // Store unique taluks
  const [villages, setVillages] = useState([]); // Store unique villages
  const [selectedDistrict, setSelectedDistrict] = useState(""); // Store selected district
  const [selectedTaluk, setSelectedTaluk] = useState(""); // Store selected taluk
  const [selectedVillage, setSelectedVillage] = useState(""); // Store selected village
  const [regNumber, setRegNumber] = useState(""); // Store the entered reg number
  const [fromDate, setFromDate] = useState(""); // Store the 'from' date
  const [toDate, setToDate] = useState(""); // Store the 'to' date

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}admin/get_by_status/1`, {
        method: "GET",
        headers: {
          Authorization: `jwt ${authToken}`,
          "Content-Type": "application/json",
        },
      });

      const jsonData = await response.json();

      if (jsonData.status) {
        const transformedData = jsonData.data.map((item, index) => ({
          sl: index + 1, // Start sequence from 1
          code: item.register_number,
          name: item.name,
          district_name: item.district_name,
          taluk_name: item.taluk_name,
          village_name: item.village_name,
          phone: item.phone,
          status: item.status,
          email: item.email,
          contact_person: item.contact_person,
          date: item.date?.split("T")[0], // Extract only the date part
          second_phone: item.second_phone,
          society_number: item.society_number,
          id: item.id,
        }));

        setProductsData(transformedData);

        // Extract unique values for district, taluk, and village
        setDistricts([...new Set(transformedData.map((item) => item.district_name))]);
        setTaluks([...new Set(transformedData.map((item) => item.taluk_name))]);
        setVillages([...new Set(transformedData.map((item) => item.village_name))]);
        setFilteredProducts(transformedData); // Initialize filteredProducts with all data
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Filter products based on selected filters
  useEffect(() => {
    let filteredData = products;

    if (selectedDistrict) {
      filteredData = filteredData.filter((product) => product.district_name === selectedDistrict);
    }

    if (selectedTaluk) {
      filteredData = filteredData.filter((product) => product.taluk_name === selectedTaluk);
    }

    if (selectedVillage) {
      filteredData = filteredData.filter((product) => product.village_name === selectedVillage);
    }

    if (regNumber) {
      filteredData = filteredData.filter((product) =>
        product.code.toLowerCase().includes(regNumber.toLowerCase())
      );
    }

    if (fromDate && toDate) {
      filteredData = filteredData.filter((product) => {
        const productDate = new Date(product.date);
        return productDate >= new Date(fromDate) && productDate <= new Date(toDate);
      });
    }

    setFilteredProducts(filteredData);
  }, [selectedDistrict, selectedTaluk, selectedVillage, regNumber, fromDate, toDate, products]);

  const handleDistrictChange = (e) => setSelectedDistrict(e.target.value);
  const handleTalukChange = (e) => setSelectedTaluk(e.target.value);
  const handleVillageChange = (e) => setSelectedVillage(e.target.value);
  const handleRegNumberChange = (e) => setRegNumber(e.target.value);
  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);

  const table_header = [
    {
      prop: "sl",
      title: "Sl No",
    },
    {
      prop: "name",
      title: "Society Name",
      isFilterable: true,
    },
    {
      prop: "society_number",
      title: "Society Number",
      isFilterable: true,
    },
    {
      prop: "code",
      title: "Reg No",
      isFilterable: true,
    },
    {
      prop: "district_name",
      title: "District",
      isFilterable: true,
    },
    {
      prop: "taluk_name",
      title: "Taluk",
      isFilterable: true,
    },
    {
      prop: "village_name",
      title: "Village",
      isFilterable: true,
    },
    {
      prop: "contact_person",
      title: "Person",
      isFilterable: true,
    },
    {
      prop: "phone",
      title: "Phone No",
      isFilterable: true,
    },
    {
      prop: "second_phone",
      title: "Second Phone No",
      isFilterable: true,
    },
    {
      prop: "email",
      title: "Email",
      isFilterable: true,
    },
    {
      prop: "date",
      title: "Date",
      isFilterable: true,
    },
  ];

  return (
    <>
      <h3>Society Report</h3>

      <Row className="mb-4 p-2">
        <Col xs={12} sm={6} lg={3}>
          <label>Filter by District:</label>
          <select className="form-control" value={selectedDistrict} onChange={handleDistrictChange}>
            <option value="">All Districts</option>
            {districts.map((district, index) => (
              <option key={index} value={district}>
                {district}
              </option>
            ))}
          </select>
        </Col>

        <Col xs={12} sm={6} lg={3}>
          <label>Filter by Taluk:</label>
          <select className="form-control" value={selectedTaluk} onChange={handleTalukChange}>
            <option value="">All Taluks</option>
            {taluks.map((taluk, index) => (
              <option key={index} value={taluk}>
                {taluk}
              </option>
            ))}
          </select>
        </Col>

        <Col xs={12} sm={6} lg={3}>
          <label>Filter by Village:</label>
          <select className="form-control" value={selectedVillage} onChange={handleVillageChange}>
            <option value="">All Villages</option>
            {villages.map((village, index) => (
              <option key={index} value={village}>
                {village}
              </option>
            ))}
          </select>
        </Col>

        <Col xs={12} sm={6} lg={3}>
          <label>Filter by Reg No:</label>
          <input
            type="text"
            className="form-control"
            value={regNumber}
            onChange={handleRegNumberChange}
            placeholder="Enter Reg No"
          />
        </Col>

        <Col xs={12} sm={6} lg={3} className="mt-3">
          <label>From Date:</label>
          <input type="date" className="form-control" value={fromDate} onChange={handleFromDateChange} />
        </Col>

        <Col xs={12} sm={6} lg={3} className="mt-3">
          <label>To Date:</label>
          <input type="date" className="form-control" value={toDate} onChange={handleToDateChange} />
        </Col>

        
      </Row>

      <DatatableWrapper
        body={filteredProducts}
        headers={table_header}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10,
            options: [5, 10, 15, 20],
          },
        }}
      >
        <Row className="mb-4 p-2">
          <Col
            xs={12}
            sm={12}
            lg={6}
            className="d-flex flex-col justify-content-lg-start align-items-start justify-content-sm-start mb-2 mb-sm-0"
          >
            <PaginationOptions />
          </Col>
          <Col xs={12} sm={12} lg={6} className="mt-3 float-right">
            <ExportButtons data={filteredProducts} headers={table_header} />
          </Col>
        </Row>
        <div style={{ overflowX: "auto", backgroundColor: "#f5fbf8", padding: "1rem", borderRadius: "0.25rem" }}>
          <Table style={{ backgroundColor: "#f5fbf8" }}>
            <TableHeader />
            <TableBody />
          </Table>
        </div>
        <Row className="mb-4 p-2">
          <Col
            xs={12}
            sm={12}
            lg={12}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Pagination />
          </Col>
        </Row>
      </DatatableWrapper>
    </>
  );
};

export default SocietyReport;
