import React from 'react'
const Footer =()=>{
    return (<>
    <footer className="pt-5 pt-md-5" style={{ background: `url('/assets/images/footer_bg.jpg')`}} >
<div className="container">
  <div className="row">
    <div className="col-lg-5 box-l">
      <h2>Contact us</h2>
      <div className="box box-1">
        <h3>DIRECTOR OF HANDLOOM  AND TEXTILES</h3>
        <address>
        Govt of Kerala<br/>
        Vikas Bhavan<br/>
        P.O Tiruvanantapuram<br/>
        Ph.No. 0471 2303427,2302891, 2302892<br/>
        Email :  <a href="">director.handloom@kerala.gov.in</a></address>
        
      </div>
      <div className="box box-2">
        <h4>Managed By,<i className="ml-3 iiht_thumb"><img src="/assets/images/iiht.png" alt="icon"/></i></h4>
        <h3>Indian Institute Of Handloom Technology</h3>
        <address>
        Govt of Kerala<br/>
        Kizhunna P.O, Thottada, Kannur, Kerala - 670007<br/>
        Email: <a href="">info@iihtkannur.ac.in</a><br/>
        Contact:  0497-2835390<br/>
        Web:  <a href="https://www.iihtkannur.ac.in" target="_blank">www.iihtkannur.ac.in</a>
        </address>
      </div>
    </div>
    <div className="col-lg-2 center-blk d-flex align-items-center justify-content-center">
      <div className="footer-logo">
        <img src="/assets/images/footer_logo.svg" alt="logo-footer"/>
      </div>
    </div>
    <div className="col-lg-5 box-r">
      <h2>Policies</h2>
      <div className="box box-1">
      <ul>
        <li><a href="#">Terms and Conditions</a></li>
        <li><a href="/privacy-policy">Privacy Policy</a></li>
      </ul>
      {/* <ul>
        <li><a href="#">Payment Policy</a></li>
        <li><a href="#">Refund Policy</a></li>
      </ul> */}
      {/* <ul className="social_ic">
        <li><a href="#"><img src="/assets/images/Facebook.png" alt="icon"/></a></li>
        <li><a href="#"><img src="/assets/images/Linkedin.png" alt="icon"/></a></li>
        <li><a href="#"><img src="/assets/images/Twitter.png" alt="icon"/></a></li>
        <li><a href="#"><img src="/assets/images/Instagram.png" alt="icon"/></a></li>
      </ul> */}
      </div>
    </div>
  </div>
  </div>

<div className="w-100 copy-row">
  <div className="container">
  <p className="text-center">Copyright © 2024. All rights reserved.</p>
  <p className="powered-by">Powered by <a href="https://www.alisonsgroup.com/" target="_blank">Alisons Infomatics Pvt. Ltd</a></p>
  </div>
</div>
</footer>
    
    </>);
}

export default Footer;