import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const RejectProductModal = ({ show, handleClose, onSubmit }) => {
  const [name1, setName1] = useState("");
  const [designation1, setDesignation1] = useState("");
  const [name2, setName2] = useState("");
  const [designation2, setDesignation2] = useState("");
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState({
    name1: "",
    designation1: "",
    name2: "",
    designation2: "",
    reason: "",
  });

  const handleFormSubmit = () => {
    const validationErrors = {};

    // Validate Name 1
    if (!name1.trim()) {
      validationErrors.name1 = "Name 1 is required";
    }

    // Validate Designation 1
    if (!designation1.trim()) {
      validationErrors.designation1 = "Designation 1 is required";
    }

    // Validate Name 2
    if (!name2.trim()) {
      validationErrors.name2 = "Name 2 is required";
    }

    // Validate Designation 2
    if (!designation2.trim()) {
      validationErrors.designation2 = "Designation 2 is required";
    }

    // Validate Reason
    if (!reason.trim()) {
      validationErrors.reason = "Reason for rejection is required";
    }

    setErrors(validationErrors);

    // If no validation errors, submit the form
    if (Object.keys(validationErrors).length === 0) {
      onSubmit({ name1, designation1, name2, designation2, reason }); // Pass the data to the parent component
      handleClose(); // Close the modal after submission
      setName1("");
      setDesignation1("");
      setName2("");
      setDesignation2("");
      setReason("");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Reject Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Name 1</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Name 1"
            value={name1}
            isInvalid={!!errors.name1}
            onChange={(e) => {
              setName1(e.target.value);
              setErrors((prevErrors) => ({ ...prevErrors, name1: "" }));
            }}
          />
          <Form.Control.Feedback type="invalid">{errors.name1}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Designation 1</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Designation 1"
            value={designation1}
            isInvalid={!!errors.designation1}
            onChange={(e) => {
              setDesignation1(e.target.value);
              setErrors((prevErrors) => ({ ...prevErrors, designation1: "" }));
            }}
          />
          <Form.Control.Feedback type="invalid">{errors.designation1}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Name 2</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Name 2"
            value={name2}
            isInvalid={!!errors.name2}
            onChange={(e) => {
              setName2(e.target.value);
              setErrors((prevErrors) => ({ ...prevErrors, name2: "" }));
            }}
          />
          <Form.Control.Feedback type="invalid">{errors.name2}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Designation 2</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Designation 2"
            value={designation2}
            isInvalid={!!errors.designation2}
            onChange={(e) => {
              setDesignation2(e.target.value);
              setErrors((prevErrors) => ({ ...prevErrors, designation2: "" }));
            }}
          />
          <Form.Control.Feedback type="invalid">{errors.designation2}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Reason for Rejection</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter the reason for rejection"
            value={reason}
            isInvalid={!!errors.reason}
            onChange={(e) => {
              setReason(e.target.value);
              setErrors((prevErrors) => ({ ...prevErrors, reason: "" }));
            }}
          />
          <Form.Control.Feedback type="invalid">{errors.reason}</Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="danger" onClick={handleFormSubmit}>
          Reject
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectProductModal;
